/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'bell': {
    width: 24,
    height: 28,
    viewBox: '0 0 24 28',
    data: '<path pid="0" d="M20.913 25.32a.307.307 0 0 1-.374.22L2.193 20.624a.307.307 0 0 1-.214-.377l.082-.304c.151-.566.4-1.094.74-1.57l2.011-2.821c.249-.348.43-.735.542-1.15l1.56-5.821c1.02-3.81 4.602-6.303 8.362-6.063.184.012.368.024.553.05.256.034.511.083.766.144.022.005.044.007.066.013l.142.036c.07.019.136.045.205.066.252.076.499.163.74.263.173.073.346.145.513.229 1.35.68 2.487 1.756 3.287 3.137a8.627 8.627 0 0 1 .865 6.514l-1.5 5.602c-.111.413-.147.84-.106 1.266l.331 3.449a4.854 4.854 0 0 1-.144 1.73l-.081.304zM17.2 1.306a.764.764 0 0 1 .558.667 8.646 8.646 0 0 0-.847-.255c-.178-.044-.356-.072-.534-.104a.764.764 0 0 1 .823-.308zm4.625 18.425l.331 3.448c.068.708.01 1.415-.175 2.103l-.081.304a1.339 1.339 0 0 1-1.63.958L1.923 21.627a1.338 1.338 0 0 1-.933-1.644l.082-.304c.184-.69.486-1.33.899-1.91l2.011-2.82c.176-.246.305-.519.383-.811l1.56-5.822c1.147-4.282 5.166-7.089 9.39-6.836C15.62.584 16.557.057 17.47.302c.915.245 1.462 1.173 1.274 2.102 1.514.77 2.791 1.974 3.688 3.522a9.68 9.68 0 0 1 .97 7.308L21.9 18.836a2.511 2.511 0 0 0-.074.894zm-11.577 7.477c-1.498-.402-2.384-1.967-1.977-3.488l.988.265c-.26.968.305 1.964 1.258 2.22.954.255 1.94-.325 2.2-1.294l.987.265c-.407 1.521-1.958 2.433-3.456 2.032z" _fill="#2C2F39" _stroke="#2C2F39" stroke-width=".4" fill-rule="evenodd"/>'
  }
})
