/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'check': {
    width: 16,
    height: 16,
    viewBox: '-469 273 21 16',
    data: '<path pid="0" d="M-462.3 285.7l-5-5.1-1.7 1.7 6.7 6.7 14.3-14.3-1.7-1.7z"/>'
  }
})
