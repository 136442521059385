import { mapGetters } from 'vuex';
import get from 'lodash/get';

import { formatWeight } from '@/utils/units';
import { batteriesMixin } from '@/mixins';
import RnButton from '../../button/button.vue';
import RnBatteryStatus from '../../battery-status/battery-status.vue';

export default {
	name: 'rn-battery',

	components: {
		RnButton,
		RnBatteryStatus,
	},

	mixins: [batteriesMixin],

	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},

	computed: {
		...mapGetters(['locations', 'batteryModels', 'businessRelationId']),

		id() {
			return get(this.data, 'id', null);
		},

		status() {
			return this.getBatteryStatusProps(this.data, this.businessRelationId, this.$store);
		},

		model() {
			const modelId = get(this.data, 'model', '');
			const model = this.batteryModels.find(m => m.batteryModelId === modelId);

			return model ? model.name : get(this.data, 'batteryDescription.batteryModel.name', '');
		},

		partNumber() {
			return get(this.data, 'battery.partNumber', '') || get(this.data, 'batteryDescription.partNumber', '');
		},

		year() {
			return get(this.data, 'constructionYear', '');
		},

		serialNumber() {
			return get(this.data, 'serialNumber', '') || get(this.data, 'batteryDescription.serialNumber', '');
		},

		size() {
			return `${get(this.data, 'width', get(this.data, 'batteryDescription.width', 0))} x ${(get(this.data, 'height'),
			get(this.data, 'batteryDescription.height', 0))} x ${get(this.data, 'depth', get(this.data, 'batteryDescription.depth', 0))} (${this.$t(
				'common.mm'
			)})`;
		},

		weight() {
			return formatWeight(get(this.data, 'nettoWeight', '-'));
		},
	},
};
