/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'logout': {
    width: 16,
    height: 16,
    viewBox: '0 0 21 12',
    data: '<path pid="0" d="M10.6 1.4L14.2 5H0v2h14.2l-3.6 3.6L12 12l6-6-6-6zM19 0v12h2V0z"/>'
  }
})
