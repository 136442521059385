import { mapGetters } from 'vuex';
import { ITEMS_PER_PAGE } from '@/services/constants';
import { RnNewTable, RnActionHeader, RnButton, RnField, RnSearch } from '@/components';
import RnPagination from '@/components/pagination/pagination.vue';
import { getInvoiceFile } from '@/services/invoices';
import { formatAsDate } from '@/utils/format-as-date';

export default {
	name: 'rn-pup-invoices-view',
	components: {
		RnNewTable,
		RnActionHeader,
		RnButton,
		RnPagination,
		RnField,
		RnSearch,
	},
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.default,
			currentTabIndex: 0,
			pickupPointsPage: 0,
			collectionPartnersPage: 0,
			brandsPage: 0,
		};
	},
	computed: {
		...mapGetters(['businessRelationId', 'pupInvoices', 'pupInvoicesCount']),

		invoicesHeaders: {
			get() {
				return [
					{
						text: this.$t('table.invoice.date'),
						value: 'date',
						display: formatAsDate,
					},
					{
						text: this.$t('table.invoice.name'),
						value: 'filename',
					},
					{
						text: this.$t('table.invoice.debtor'),
						value: 'debtor.name',
					},
					{
						text: '',
						value: 'download',
						component: RnButton,
						componentProps: ({ id: invoiceId }) => ({
							// Implement download call to invoiceproposals/{id}
							method: () => this.downloadFile(invoiceId),
							outline: true,
							disableWrap: true,
							text: this.$t('common.download'),
							size: 'small',
							color: 'blue',
							icon: {
								name: 'download',
								width: '14px',
								height: '14px',
								pos: 'left',
							},
						}),
					},
				];
			},
		},
	},
	created() {
		this.$store.dispatch('getPupInvoices', { businessRelationId: this.businessRelationId, page: 0, pageSize: this.ITEMS_PER_PAGE });
	},
	methods: {
		onSearchChange(value) {
			this.search = value;
			this.getInvoices();
		},
		onDateChange(date) {
			this.date = date;
			this.getInvoices();
		},
		downloadFile(invoiceId) {
			getInvoiceFile(this.businessRelationId, invoiceId);
		},
		getInvoices(page = 0) {
			this.pickupPointsPage = page;
			this.$store.dispatch('getPupInvoices', {
				businessRelationId: this.businessRelationId,
				page,
				pageSize: ITEMS_PER_PAGE.DEFAULT,
				search: this.search,
				date: this.date,
			});
		},
	},
};
