import RnIcon from '../icon/icon.vue';

export default {
	name: 'rn-tag',

	components: {
		RnIcon,
	},

	props: {
		icon: {
			type: String,
			default: '',
		},
		text: {
			type: String,
			default: '',
		},
		remark: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: '',
		},
	},
};
