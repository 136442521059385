/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'container-small': {
    width: 16,
    height: 16,
    viewBox: '0 0 171.71 50',
    data: '<defs/><g id="Layer_2" data-name="Layer 2"><g id="container-small"><g id="Layer_3" data-name="Layer 3"><path pid="0" class="cls-1" d="M121.09 15.92l-3.19 9.39h1.26l1-2.95h3.31l1 2.95h1.3l-3.21-9.39zm-.72 5.49l.91-2.73c.18-.57.33-1.14.46-1.7.14.54.28 1.1.49 1.72l.91 2.7zM130.13 15.85a17.13 17.13 0 0 0-2.58.19v9.24a18 18 0 0 0 2.2.11 5.68 5.68 0 0 0 4.16-1.39 5 5 0 0 0 1.36-3.65A4.32 4.32 0 0 0 134 17a5.49 5.49 0 0 0-3.87-1.15zm-.13 8.57a8.46 8.46 0 0 1-1.23-.07v-7.42a6.82 6.82 0 0 1 1.42-.13c2.57 0 3.82 1.41 3.81 3.64 0 2.56-1.42 3.98-4 3.98zM141.27 20.84v-.05A2.45 2.45 0 0 0 143 18.4a2.31 2.31 0 0 0-.73-1.79 3.86 3.86 0 0 0-2.73-.76 12.15 12.15 0 0 0-2.33.19v9.27h1.21v-4.07h1.15c1.1 0 1.6.53 1.88 1.82a12.5 12.5 0 0 0 .62 2.25h1.25a14.54 14.54 0 0 1-.72-2.58 2.29 2.29 0 0 0-1.33-1.89zm-1.56-.52h-1.26v-3.43a5.42 5.42 0 0 1 1.2-.11c1.29 0 2.16.54 2.16 1.76s-.81 1.78-2.1 1.78z"/><path pid="1" class="cls-1" d="M135.12 50v-7.19h-53.2V50H46.24v-7.19H26.58V50H0V0h171.71v50zM167 45.27v-2.46h-5.29v2.46zm-8.2 0v-2.46h-18.95v2.46zm-81.59 0v-2.46h-5.3v2.46zm-8.19 0v-2.46H51v2.46zm-47.15 0v-2.46H4.73v2.46zM167 38.08V4.73H71.91v33.35zm-98 0V4.73h-8.44v33.35zm-11.35 0V4.73h-8.44v33.35zm-11.35 0V4.73h-8.43v33.35zm-11.3 0V4.73h-8.48v33.35zm-11.35 0V4.73h-8.48v33.35zm-11.35 0V4.73H4.73v33.35z"/><path pid="2" class="cls-1" d="M88.36 21.41l11.35 11.38 11.38-11.38L99.71 10zm11.35 10.08L89.64 21.41l1.58-1.58v1.58h.91v-2.49l1.77-1.77v4.26h.91v-5.17l1.77-1.76v6.93h.91v-7.84l1.77-1.77v9.61h.91v-9.62l1.76 1.76v7.86h.91v-6.95l1.77 1.77v5.18h.91v-4.27l1.77 1.77v2.5h.91v-1.59l1.59 1.59z"/></g></g></g>'
  }
})
