import debounce from 'lodash/debounce';

import RnField from '../field/field.vue';
import { SearchClasses } from './search.enums';

export default {
	name: 'rn-search',
	components: {
		RnField,
	},
	props: {
		justify: {
			type: String,
			default: 'right',
		},
		size: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			searchInput: {
				inputProps: {
					id: 'search-field',
					type: 'text',
					placeholder: this.placeholder,
				},
				icon: {
					name: 'search',
					pos: 'left',
					width: '16px',
					height: '16px',
				},
			},
		};
	},
	computed: {
		buttonClasses() {
			return {
				'm-search-wrapper': true,
				// Justify
				[SearchClasses.JustifyLeft]: this.justify === 'left',
				[SearchClasses.JustifyRight]: this.justify === 'right',
				// Sizes
				[SearchClasses.Large]: this.size === 'large',
			};
		},
	},
	methods: {
		debounceSearch: debounce(function(value) {
			this.onValueChanged(value);
		}, 250),
		onValueChanged(value) {
			this.$emit('update:value', value);
		},
	},
};
