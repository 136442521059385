/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'plus': {
    width: 16,
    height: 16,
    viewBox: '-470 273 15 15',
    data: '<path pid="0" d="M-455 281.6h-6.4v6.4h-2.2v-6.4h-6.4v-2.2h6.4V273h2.2v6.4h6.4z"/>'
  }
})
