/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'check-r': {
    width: 16,
    height: 16,
    viewBox: '-466 268 26 26',
    data: '<path pid="0" d="M-453 268c-7.2 0-13 5.8-13 13s5.8 13 13 13 13-5.8 13-13-5.8-13-13-13zm-2.2 18.4l-4.2-4.1 1.3-1.3 2.9 2.8 7.2-7.1 1.3 1.3z"/>'
  }
})
