import { mapGetters } from 'vuex';

export default {
	name: 'rn-app-view',
	created() {
		this.$store.dispatch('getBusinessRoles');
		this.$store.dispatch('getLanguages');
		this.$store.dispatch('getCountries');
		this.$store.dispatch('getAllNotifications');
	},
	computed: {
		...mapGetters(['isLocked']),
	},
	watch: {
		isLocked() {
			if (this.isLocked) {
				document.body.classList.add('u-no-scroll');
			} else {
				document.body.classList.remove('u-no-scroll');
			}
		},
	},
};
