import { IconDirections } from '../icon/icon.enums';
import RnIcon from '../icon/icon.vue';
import RnButton from '../button/button.vue';
import RnConfirm from '../confirm/confirm.vue';
import RnSpinner from '../spinner/spinner.vue';

export default {
	name: 'rn-step-form',
	components: {
		RnIcon,
		RnButton,
		RnConfirm,
		RnSpinner,
	},
	props: {
		currentStep: {
			type: Number,
			default: 1,
		},
		steps: {
			type: Array,
			default: () => [],
		},
		nextDisabled: {
			type: Boolean,
			default: false,
		},
		cancelMethod: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			confirmOpen: false,
			buttonBackIcon: {
				name: 'arrow',
				direction: IconDirections.Left,
				pos: 'left',
				width: '16px',
				height: '16px',
			},
			buttonNextIcon: {
				name: 'arrow',
				direction: IconDirections.Right,
				pos: 'right',
				width: '16px',
				height: '16px',
			},
			showSpinner: false,
		};
	},
	computed: {
		currentStepInfo() {
			return this.steps[this.currentStep - 1];
		},
		activeConfirm() {
			return this.hasConfirmation ? { ...this.currentStepInfo.confirm, checked: false } : {};
		},
		hasConfirmation() {
			return this.currentStepInfo.confirm;
		},
		hasConfirmationDisabled() {
			return this.currentStepInfo.confirm.disabled || false;
		},
		nextButton() {
			let props = {
				icon: this.buttonNextIcon,
				text: this.$t('common.next'),
			};

			if (this.currentStep === this.steps.length) {
				props = {
					icon: this.finalAction.icon,
					text: this.finalAction.text,
				};
			}

			return {
				...props,
				method: this.nextStep,
				disabled: !this.currentStepInfo.valid,
			};
		},
		finalAction() {
			const finalStep = this.steps[this.steps.length - 1];

			return finalStep.action ? finalStep.action : null;
		},
	},
	methods: {
		toAddBatteryStep() {
			this.$emit('update:currentStep', 1);
		},

		previousStep() {
			if (this.currentStep > 1) {
				this.$emit('update:currentStep', this.currentStep - 1);
			}
		},

		nextStep() {
			if (typeof this.currentStepInfo.valid === 'function') {
				if (!this.currentStepInfo.valid()) {
					return;
				}
			}

			if (this.hasConfirmation && !this.activeConfirm.checked && !this.hasConfirmationDisabled) {
				if (!this.confirmOpen) {
					this.confirmOpen = true;
				}
			} else {
				this.confirmOpen = false;

				this.$refs['confirm-prompt'].checkedItems = [];

				if (this.currentStep < this.steps.length) {
					this.$emit('update:currentStep', this.currentStep + 1);
				} else if (this.currentStep === this.steps.length) {
					this.showSpinner = true;

					this.finalAction.method().catch(() => {
						this.showSpinner = false;
					});

					this.confirmOpen = false;
				}
			}
		},
	},
};
