export function formatTransportPackagings(packagings) {
	return packagings.reduce((acc, packaging) => {
		const index = acc.findIndex(item => item.id === packaging.id);

		if (index > -1) {
			acc[index].amount += 1;
		} else {
			acc.push({
				...packaging,
				amount: 1,
			});
		}

		return acc;
	}, []);
}
