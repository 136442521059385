import { RnIcon, RnButton } from '@/components';

export default {
	name: 'rn-menu',
	components: {
		RnIcon,
		RnButton,
	},
	props: {
		icon: {
			type: String,
			default: 'image',
		},
		position: {
			type: String,
			default: 'left',
		},
		options: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			resizeObserver: undefined,
		};
	},
	methods: {
		onClick(e) {
			e.stopPropagation();

			this.$refs.menu.togglePopover();

			this.calcPos();
		},
		calcPos() {
			// NextTick because getBoundingClientRect of menu is only possible after rerender
			this.$nextTick(() => {
				const body = document.body.getBoundingClientRect();
				const button = this.$refs.button.$el.getBoundingClientRect();
				const menu = this.$refs.menu.getBoundingClientRect();

				if (this.position === 'left') {
					return (this.$refs.menu.style.transform = `translate3d(${button.left}px, ${button.bottom - body.top}px, 0)`);
				}

				return (this.$refs.menu.style.transform = `translate3d(${button.left + button.width - menu.width}px, ${button.bottom - body.top}px, 0)`);
			});
		},
	},
	mounted() {
		this.resizeObserver = new ResizeObserver(this.calcPos);
		this.resizeObserver.observe(document.querySelector('#app'));
	},
	destroyed() {
		this.resizeObserver.disconnect();
	},
};
