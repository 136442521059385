import Vue from 'vue';
import { mapGetters } from 'vuex';

import store from '@/store';

export default Vue.extend({
	name: 'rn-language-wrapper',
	computed: {
		...mapGetters(['currentLanguage']),
	},
	watch: {
		'$route.params.language': function(val) {
			this.setLanguage(val);
			store.dispatch('setLanguage', val);
		},
	},
	created() {
		this.setLanguage(this.currentLanguage);
	},
	methods: {
		setLanguage(language: string) {
			(this as any).$locale = language;
		},
	},
});
