/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'attachment-r': {
    width: 16,
    height: 16,
    viewBox: '-466 268 26 26',
    data: '<path pid="0" d="M-453 268c-7.2 0-13 5.8-13 13s5.8 13 13 13 13-5.8 13-13-5.8-13-13-13zm3.3 16.5c0 1.9-1.4 3.5-3.3 3.5s-3.5-1.6-3.5-3.5v-8c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5v6.6c0 .9-.7 1.6-1.6 1.6s-1.6-.7-1.6-1.6v-6h1v6c0 .3.3.6.6.6.4 0 .6-.3.6-.6v-6.6c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6 1.6v8c0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5v-7.4h1z"/>'
  }
})
