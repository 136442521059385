import { moment, downloadAttachment } from '@/utils';

import RnIcon from '../icon/icon.vue';
import RnButton from '../button/button.vue';

export default {
	name: 'rn-order',
	components: {
		RnIcon,
		RnButton,
	},
	filters: {
		formatDate(date, format) {
			if (!date) {
				return '-';
			}

			return new moment(date).format(format);
		},
	},
	props: {
		order: {
			type: Object,
			default: null,
		},
	},
	methods: {
		shouldDisplayOrder(order) {
			// an order should be displayed if:
			// - it exists
			// - does not have the status "Cancelled"
			// - when it has the status "Executed" or "ExecutedWithRemarks", it should only display if it was
			// delivered less than 5 days ago
			const res =
				order &&
				order.status !== 'Cancelled' &&
				!(!!~['Executed', 'ExecutedWithRemarks'].indexOf(order.status) && new Date(order.deliveryDate) < new Date().setDate(new Date().getDate() - 5));

			return res;
		},
		downloadPackingSlip(packingSlipId) {
			downloadAttachment(packingSlipId, 'message.deliveryOrder.error', this.$store);
		},
	},
};
