/**
 * Centralized error object that contains recursive innerException information
 */
class RecursiveError extends Object {
	constructor(errorMessage, innerException = null, additionalInfo = null) {
		super();
		this.errorMessage = errorMessage;
		this.innerException = innerException;
		this.additionalInfo = additionalInfo;
		if (innerException && typeof innerException.stack === 'string') {
			this.stack = innerException.stack.split('\n');
		} else {
			this.stack = (new Error().stack || '').split('\n');
		}
	}

	toString() {
		return JSON.stringify(
			this,
			(key, value) => {
				if (key === 'request') {
					// Avoid huge request object in error json
					return '[request]';
				}

				return value;
			},
			2
		);
	}
}

export default RecursiveError;
