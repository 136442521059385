/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 16,
    height: 16,
    viewBox: '-468.5 271.5 19.1 19.1',
    data: '<path pid="0" d="M-450.6 290.6l-6.3-6.3c-1.3 1-2.8 1.6-4.5 1.6-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2c0 1.6-.6 3.2-1.6 4.5l6.3 6.3zm-10.7-17.4c-3 0-5.5 2.5-5.5 5.5 0 3.1 2.5 5.5 5.5 5.5 3.1 0 5.5-2.5 5.5-5.5 0-3.1-2.5-5.5-5.5-5.5z"/>'
  }
})
