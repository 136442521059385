import get from 'lodash/get';

import RnIcon from '@/components/icon/icon.vue';
import { fieldMixin } from '@/mixins';

export default {
	name: 'cs-field-battery-condition',
	components: { RnIcon },
	mixins: [fieldMixin],
	methods: {
		getCheckedValue(option) {
			const checked = get(option, 'checked', null);

			if (checked !== null) {
				return checked === option.value;
			}

			return option.value === this.value;
		},
	},
};
