/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'subtract': {
    width: 16,
    height: 16,
    viewBox: '0 0 15 15',
    data: '<path pid="0" class="cls-1" d="M0 6.4h15v2.2H0z"/>'
  }
})
