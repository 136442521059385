/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'download-r': {
    width: 16,
    height: 16,
    viewBox: '-466 268 26 26',
    data: '<path pid="0" d="M-453 268c-7.2 0-13 5.8-13 13s5.8 13 13 13 13-5.8 13-13-5.8-13-13-13zm4.6 20.5h-9.5v-1.8h9.5zm-4.8-3.2l-5-5.1 1.3-1.3 2.8 3v-9.1h1.8v9.1l2.8-3 1.3 1.3z"/>'
  }
})
