import get from 'lodash/get';

export const addFormFieldsToBattery = (batteryFormModel, batteryPackagings) => {
	const tmpBattery = { ...batteryFormModel };
	let batteryPackaging = null;

	if (batteryPackagings) {
		batteryPackaging = batteryPackagings.find(packaging => packaging.id === batteryFormModel.batteryPackagingId);
	}

	// Battery model data
	if (batteryFormModel.batteryDescriptionId) {
		// Existing battery
		tmpBattery.batteryDescriptionId = batteryFormModel.batteryDescriptionId;
	}
	{
		tmpBattery.batteryDescription = {
			brand: {
				id: batteryFormModel.brand,
			},
			model: batteryFormModel.model,
			constructionYear: batteryFormModel.constructionYear,
			nettoWeight: batteryFormModel.nettoWeight,
			width: batteryFormModel.width,
			height: batteryFormModel.height,
			depth: batteryFormModel.depth,
			chemicalFamilyTypeId: batteryFormModel.chemicalFamilyTypeId,
		};
	}

	// Battery packaging data
	if (batteryFormModel.batteryPackagingId) {
		// Existing battery
		tmpBattery.batteryPackagingId = batteryFormModel.batteryPackagingId;
		tmpBattery.noBatteryPackaging = false;
	} else {
		tmpBattery.batteryPackagingId = null;
		tmpBattery.noBatteryPackaging = true;
	}

	tmpBattery.brandId = batteryFormModel.brand;
	tmpBattery.chemicalFamilyTypeId = batteryFormModel.chemicalFamilyTypeId;
	tmpBattery.serialNumber = batteryFormModel.serialNumber;
	tmpBattery.internalReference = batteryFormModel.internalReference;

	// Battery conditions (step 2)
	tmpBattery.batteryCondition = batteryFormModel.batteryCondition || '';
	tmpBattery.damagedDescription = batteryFormModel.damagedDescription || '';
	tmpBattery.suitedForTransport = get(batteryPackaging, 'suitedForTransport', get(batteryFormModel, 'suitedForTransport', false));

	return {
		...batteryFormModel,
		...tmpBattery,
		batterySize: {
			width: batteryFormModel.width,
			height: batteryFormModel.height,
			depth: batteryFormModel.depth,
		},
	};
};
