import apiService from '@/services/api';
import { ITEMS_PER_PAGE } from '@/services/constants';
import { downloadAttachment, logAndToastError } from '@/utils';

export const getInvoices = (commit, businessRelationId, page, pageSize, date, search, role = null) => {
	let url = `/businessrelations/${businessRelationId}/invoiceproposals?page=${page || 0}&pageSize=${pageSize || ITEMS_PER_PAGE.DEFAULT}`;
	const body = {};

	if (search) {
		url = `${url}&searchString=${search}`;
	}

	if (date) {
		url = `${url}&date=${date}`;
	}

	if (role) {
		url = `${url}&debtorRoleId=${role.id}`;
	}

	return apiService
		.get(url, body)
		.then(response => response)
		.catch(err => {
			logAndToastError(commit, 'Failed to get invoices from the server', err, { url, body });
		});
};

export const getInvoiceFile = (businessRelationId, invoiceId) => {
	const url = `/businessrelations/${businessRelationId}/invoiceproposals/${invoiceId}`;
	const body = {};

	apiService
		.get(url, body)
		.then(response => {
			downloadAttachment(response.data);
		})
		.catch(err => {
			logAndToastError('getInvoiceFile', 'Failed to get invoice file from the server', err, { url, body });
		});
};
