/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'receptacle-barrel': {
    width: 16,
    height: 16,
    viewBox: '-442 211.5 74.9 139.4',
    data: '<path pid="0" d="M-434.9 231.4v-6.5s.5-.5.9-.6c0-.3.6-1.9 1.1-2.4 2.4-2.4 15-4 28.4-4 13.5 0 26 1.7 28.4 4 .5.5 1.1 2.1 1.1 2.4.4.1.9.6.9.6v6.5c.7.6.6 1.3 0 1.9-.2.2-.5.4-.9.6-3.6 2-13.9 3.7-29.6 3.7-15.6 0-25.5-1.8-29.6-3.5-.7-.3-1.2-.6-1.6-.8.2-.7.7-1.4.9-1.9zm60 103.1c.2 5.1-10.2 10.1-29.6 10.1-4.5 0-8.4-.3-11.9-.7-.1 0-.3 0-.4-.1-.3 0-.7-.1-1-.1-.1 0-.3 0-.4-.1-1-.2-2-.3-2.9-.5-.1 0-.2 0-.3-.1-.5-.1-.9-.2-1.4-.3s-1-.3-1.5-.4c-.2-.1-.5-.1-.7-.2s-.4-.1-.7-.2-.7-.2-1-.3c-.9-.4-1.8-.7-2.6-1.1-.2-.1-.3-.2-.5-.3s-.3-.2-.4-.3c-.6-.4-1.1-.7-1.6-1.1l-.1-.1c-1.6-1.3-2.3-2.7-2.3-4.1v-97.3c5.8 2.1 16.7 3.3 29.6 3.3 8.9 0 16.8-.5 22.6-1.6 3.2-.6 5.4-1.2 7-1.9zm7.3-104.7V225c0-1.7-.7-3.4-1.9-4.6-.6-1.2-1.2-2.2-2-3-1.6-1.6-4.5-3.4-13.7-4.7-5.5-.8-12.3-1.2-19.2-1.2s-13.7.4-19.2 1.2c-9.2 1.3-12.1 3.1-13.7 4.7-.8.8-1.4 1.7-2 3a6.42 6.42 0 0 0-1.9 4.6v5.2c-.3.7-.6 1.6-.7 2.6-.2 1.8.4 3.5 1.6 4.9v97.4c.1 2 .7 4.7 3.3 7.4 5.3 5.5 16.9 8.6 32.7 8.6 15.7 0 27.3-3.1 32.7-8.6 2.6-2.7 3.2-5.4 3.3-7.5v-98.2c.9-1.2 1.2-2.4 1.3-3.4 0-1.3-.2-2.5-.6-3.6z"/><path pid="1" d="M-381.3 291c0 1.2-1 2.4-2.2 2.7-14.8 2.6-29.4 2.5-44.2-.4v-16.8c0-1.2 1-2 2.2-1.8 14.8 2.6 29.4 2.5 44.2-.4zm0-19.6c-14.8 2.9-29.4 3.1-44.2.4-2.6-.5-4.9 1.2-4.9 3.9v16.8c0 1.5 1.2 3 2.7 3.3 14.8 2.9 29.4 3.1 44.2.4 2.8-.6 4.8-3.2 4.9-6v-16.8c0-1.4-1.2-2.3-2.7-2zM-428.8 224.7c4-1.5 13.9-2.4 24.4-2.4s20.4 1 24.4 2.4c1.7-.6 2.3-1.4 1.5-2.1-2.2-1.9-13.6-3.2-25.8-3.2s-23.6 1.3-25.8 3.2c-1 .7-.4 1.4 1.3 2.1"/>'
  }
})
