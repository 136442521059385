const passwordFormMixin = {
	data() {
		return {
			passwordFormModel: {
				oldPassword: null,
				newPassword: null,
				newPasswordRepeat: null,
			},
			passwordFormFieldErrors: {
				oldPassword: '',
				newPassword: '',
				newPasswordRepeat: '',
			},
		};
	},
	computed: {
		passwordFieldGroups() {
			return [
				{
					fields: [
						{
							cols: '6',
							push: '6',
							inputProps: {
								id: 'password-old-password',
								placeholder: this.$t('user.password.oldPassword.placeholder'),
								type: 'password',
							},
							label: this.$t('user.password.oldPassword'),
							modelKey: 'oldPassword',
							required: true,
							onValueChanged: () => this.validate('oldPassword'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'password-new-password',
								placeholder: this.$t('user.password.newPassword.placeholder'),
								type: 'password',
							},
							label: this.$t('user.password.newPassword'),
							modelKey: 'newPassword',
							required: true,
							onValueChanged: () => this.validate('newPassword'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'password-new-password-repeat',
								placeholder: this.$t('user.password.newPasswordRepeat.placeholder'),
								type: 'password',
							},
							label: this.$t('user.password.newPasswordRepeat'),
							modelKey: 'newPasswordRepeat',
							required: true,
							onValueChanged: () => this.validate('newPasswordRepeat'),
						},
					],
				},
			];
		},
		passwordForm() {
			return this.$store.state.forms.password;
		},
		changePasswordForm() {
			return {
				model: this.passwordFormModel,
				fieldGroups: this.passwordFieldGroups,
				modelUpdateHandler: this.updatePasswordFormModel,
				actionHandler: this.passwordFormAction,
				form: this.passwordForm,
				fieldErrors: this.passwordFormFieldErrors,
			};
		},
	},
	methods: {
		validate(prop) {
			// If the input field has an error, and the value changes, re-validate the form
			if (this.passwordFormFieldErrors[prop]) {
				// Validate the form after the values are set
				setTimeout(() => this.validatePasswordFormModel(this.passwordFormModel));
			}
		},
		updatePasswordFormModel(newModel) {
			this.passwordFormModel = newModel;
		},
		passwordFormAction() {
			if (this.validatePasswordFormModel(this.passwordFormModel)) {
				this.$store.dispatch('changePassword', this.passwordFormModel);
			}
		},
		clearPasswordFormFieldErrors() {
			this.passwordFormFieldErrors = {
				oldPassword: '',
				newPassword: '',
				newPasswordRepeat: '',
			};
		},
		clearPasswordFormError() {
			this.$store.dispatch('clearFormErrors', { form: 'password' });
		},
		validatePasswordFormModel(model) {
			this.clearPasswordFormError();
			this.clearPasswordFormFieldErrors();

			let valid = true;

			if (!model.oldPassword) {
				this.passwordFormFieldErrors.oldPassword = this.$t('error.required', [this.$t('user.password.oldPassword')]);
				valid = false;
			}

			if (!model.newPassword) {
				this.passwordFormFieldErrors.newPassword = this.$t('error.required', [this.$t('user.password.newPassword')]);
				valid = false;
			}

			if (!model.newPasswordRepeat) {
				this.passwordFormFieldErrors.newPasswordRepeat = this.$t('error.required', [this.$t('user.password.newPassword')]);
				valid = false;
			} else if (model.newPassword && model.newPassword !== model.newPasswordRepeat) {
				this.passwordFormFieldErrors.newPassword = this.$t('error.passwordMismatch');
				this.passwordFormFieldErrors.newPasswordRepeat = this.$t('error.passwordMismatch');
				valid = false;
			}

			return valid;
		},
	},
};

export default passwordFormMixin;
