import RnBattery from './battery/battery.vue';

export default {
	name: 'rn-battery-list',

	components: {
		RnBattery,
	},

	props: {
		data: {
			type: Array,
			default: () => [],
		},
	},
};
