import RnButton from '@/components/button/button.vue';

export default {
	name: 'rn-modal',
	components: {
		RnButton,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		message: {
			type: String,
			required: false,
		},
		onClose: {
			type: Function,
			required: true,
			default: () => {
				// do nothing
			},
		},
	},
};
