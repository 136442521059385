/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'warning': {
    width: 16,
    height: 16,
    viewBox: '0 0 22 19',
    data: '<path pid="0" d="M0 19h22L11 0zm12-3h-2v-2h2zm0-4h-2V8h2z"/>'
  }
})
