const clickOutside = {
	bind(el, binding) {
		el.clickOutsideEvent = function(event) {
			// Check if click was outside el and its children
			if (!(el === event.target || el.contains(event.target))) {
				// If outside, call method provided in attribute value
				binding.value();
			}
		};
		document.body.addEventListener('click', el.clickOutsideEvent);
	},
	unbind(el) {
		document.body.removeEventListener('click', el.clickOutsideEvent);
	},
};

export default clickOutside;
