import get from 'lodash/get';
import set from 'lodash/set';
import flatten from 'lodash/flatten';

import { getInvoices } from '@/services/invoices';
import { resetState } from '../../../utils/reset-state';

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	pupInvoices: [],
	pupInvoicesCount: 0,
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	pupInvoices: state => state.pupInvoices,
	pupInvoicesCount: state => state.pupInvoicesCount || 0,
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	getPupInvoices({ commit }, { businessRelationId, page, pageSize, date, search }) {
		getInvoices(commit, businessRelationId, page, pageSize, date, search).then(response => {
			commit('setPupInvoices', {
				items: flatten(get(response, 'data.items', [])),
				storePath: 'pupInvoices',
			});
			commit('setPupInvoicesCount', {
				items: get(response, 'data.totalItems', 0),
				storePath: 'pupInvoicesCount',
			});
		});
	},
	resetPupInvoices({ commit }) {
		commit('resetPupInvoices');
	},
};

const mutations = {
	setPupInvoices(state, { items, storePath }) {
		set(state, storePath, items);
	},
	setPupInvoicesCount(state, { count, storeCountPath }) {
		set(state, storeCountPath, count);
	},
	resetPupInvoices(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
