/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'add-round': {
    width: 16,
    height: 16,
    viewBox: '0 0 25 24',
    data: '<path pid="0" d="M12.5 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm5 11h-4v4h-2v-4h-4v-2h4V7h2v4h4v2z"/>'
  }
})
