/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'attachment-pdf': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 2H4c-1.1 0-1.99.9-1.99 2L2 20c0 1.1.89 2 1.99 2H16c1.1 0 2-.9 2-2v-1h-2v1H4V4h7v5h5v1h2V8l-6-6z" _fill="#6B6D6D"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M10.593 11.458A1.559 1.559 0 0 0 9.49 11H6v7h1.83v-1.937h1.662A1.558 1.558 0 0 0 11.05 14.5v-1.938c0-.414-.165-.81-.457-1.104zM7.559 14.5v-1.937H9.49V14.5H7.56z" _fill="#6B6D6D"/><path pid="2" d="M19.424 12.834v.749h1.932v1.834h-1.933V18h-1.829v-7H22v1.834h-2.576z" _fill="#6B6D6D"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M14.644 11c.584 0 1.144.233 1.557.648.413.414.645.975.646 1.56v2.583c0 .586-.233 1.148-.646 1.561a2.202 2.202 0 0 1-1.557.648h-2.847v-7h2.847zm-1.288 5.437h1.288a.644.644 0 0 0 .644-.646V13.21a.647.647 0 0 0-.644-.646h-1.288v3.874z" _fill="#6B6D6D"/>'
  }
})
