/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'cross-r': {
    width: 16,
    height: 16,
    viewBox: '-466 268 26 26',
    data: '<path pid="0" d="M-453 268c-7.2 0-13 5.8-13 13s5.8 13 13 13 13-5.8 13-13-5.8-13-13-13zm4.4 16l-1.4 1.4-3-3-3 3-1.4-1.4 3-3-3-3 1.4-1.4 3 3 3-3 1.4 1.4-3 3z"/>'
  }
})
