var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"a-battery-status"},[(_vm.batteryProps)?_c('rn-icon',{staticClass:"a-battery-status__icon",attrs:{"name":_vm.batteryProps.icon,"original":"","width":"30px","height":"30px","tooltip":{
			content: _vm.batteryProps.tooltip,
			placement: 'top-center',
			offset: '4px',
		}}}):_vm._e(),(_vm.packageProps)?_c('rn-icon',{staticClass:"a-battery-status__icon",attrs:{"name":_vm.packageProps.icon,"original":"","width":"30px","height":"30px","tooltip":{
			content: ((_vm.$t('common.batteryPackaging')) + "<br>" + (_vm.packageProps.tooltip)),
			placement: 'top-center',
			offset: '4px',
		}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }