import serverService from '@/services/server';
import { MessageTypes } from '@/enums/message-types';
import { logAndToastError } from '../../utils/errorHandler';
import config from '@/config';

const addResourceFormMixin = {
	data() {
		return {
			addResourceFormModel: {
				id: null,
				resourceId: null,
				title: null,
				category: null,
				description: null,
				language: 'en',
				file: null,
			},
			addResourceFormFieldErrors: {
				title: '',
				category: '',
				description: '',
				language: '',
				file: '',
			},
		};
	},
	computed: {
		addResourceFormErrors() {
			return this.$store.state.forms.addResource.errors;
		},
		addResourceFormFieldGroups() {
			return [
				{
					fields: [
						{
							inputProps: {
								id: 'add-resource-title',
								placeholder: this.$t('resources.title.placeholder'),
								type: 'text',
							},
							label: this.$t('resources.title.label'),
							modelKey: 'title',
							required: true,
							onValueChanged: () => this.validate('title'),
						},
						{
							inputProps: {
								id: 'add-resource-description',
								placeholder: this.$t('resources.description.placeholder'),
								type: 'textarea',
							},
							label: this.$t('resources.description.label'),
							modelKey: 'description',
							required: true,
							onValueChanged: () => this.validate('description'),
						},
						{
							inputProps: {
								id: 'resource-language',
								options: config.supportedLanguages,
								placeholder: this.$t('user.contactInfo.language.placeholder'),
								type: 'select',
							},
							label: this.$t('user.contactInfo.language'),
							modelKey: 'language',
							required: true,
							value: config.defaultLanguage,
							onValueChanged: () => this.validate('language'),
						},
						{
							icon: {
								name: 'file-add',
								width: '30px',
								height: '30px',
								original: true,
							},
							inputProps: {
								id: 'add-resource-file',
								placeholder: `${this.$t('resources.resource.placeholder')}`,
								type: 'file',
							},
							modelKey: 'file',
							required: true,
							onValueChanged: () => this.validate('file'),
						},
					],
				},
			];
		},
	},
	methods: {
		validate(prop) {
			// If the input field has an error, and the value changes, re-validate the form
			if (this.addResourceFormFieldErrors[prop]) {
				// Validate the form after the values are set
				setTimeout(() => this.validateAddResourceFormModel(this.addResourceFormModel));
			}
		},
		addResourceFormAction(cb, errCb) {
			if (this.validateAddResourceFormModel(this.addResourceFormModel)) {
				const formData = new FormData();
				if (this.addResourceFormModel.resourceId) {
					formData.append('id', this.addResourceFormModel.resourceId);
				}
				formData.append('title', this.addResourceFormModel.title);
				formData.append('category', this.currentCategory);
				formData.append('description', this.addResourceFormModel.description);
				formData.append('language', this.addResourceFormModel.language);
				formData.append(
					'resource',
					typeof this.addResourceFormModel.file === 'string' ? this.addResourceFormModel.file : this.addResourceFormModel.file[0]
				);

				const url = '/resources/upload';
				serverService
					.put(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
					.then(() => {
						this.$store.dispatch('setMessage', { text: 'message.resource.success', type: MessageTypes.SUCCESS });
						this.$store.dispatch('getAllResources');
						cb();
					})
					.catch(err => {
						logAndToastError(this.$store.dispatch, 'Failed to upload resource', err, {
							url,
							formData,
							addResourceFormModel: this.addResourceFormModel,
						});
						if (errCb) {
							errCb();
						}
					});
			} else if (errCb) {
				errCb();
			}
		},
		clearAddResourceFormFieldErrors() {
			this.addResourceFormFieldErrors = {
				title: '',
				description: '',
			};
		},
		clearAddResourceFormModel() {
			this.addResourceFormModel = {
				id: null,
				resourceId: null,
				title: null,
				category: null,
				description: null,
				language: null,
				file: null,
			};
		},
		clearAddResourceFormError() {
			this.$store.dispatch('clearFormErrors', { form: 'addResource' });
		},
		updateAddResourceFormModel(newModel) {
			this.addResourceFormModel = {
				...this.addResourceFormModel,
				...newModel,
			};
		},
		validateAddResourceFormModel(model) {
			this.clearAddResourceFormError();
			this.clearAddResourceFormFieldErrors();

			let valid = true;

			if (!model.title) {
				this.addResourceFormFieldErrors.title = this.$t('error.required', [this.$t('resources.title.label')]);
				valid = false;
			}

			if (!model.category) {
				this.addResourceFormFieldErrors.category = this.$t('error.required', [this.$t('resources.category.label')]);
				valid = false;
			}

			if (!model.description) {
				this.addResourceFormFieldErrors.description = this.$t('error.required', [this.$t('resources.description.label')]);
				valid = false;
			}

			if (!model.language) {
				this.addResourceFormFieldErrors.language = this.$t('error.required', [this.$t('user.contactInfo.language')]);
				valid = false;
			}

			if (!model.file || !model.file.length) {
				this.addResourceFormFieldErrors.file = this.$t('error.required', [this.$t('resources.resource.label')]);
				valid = false;
			}

			return valid;
		},
	},
};

export default addResourceFormMixin;
