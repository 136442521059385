import { mapGetters } from 'vuex';
import Vue from 'vue';

import RnFooter from './footer/footer.vue';
import RnNavigation from './navigation/navigation.vue';
import RnMessage from './message/message.vue';

export default Vue.extend({
	name: 'cs-app',
	components: {
		RnFooter,
		RnNavigation,
		RnMessage,
	},
	computed: {
		...mapGetters(['user']),

		isLoggedIn() {
			return !!this.user;
		},

		isFullscreen() {
			return this.$route.name === 'PUPNewRequest' || this.$route.name === 'MFNewPickupPoint' || this.$route.name === 'CPNewCollection';
		},
	},
});
