import { mapGetters } from 'vuex';
import { moment } from '@/utils';

export default {
	name: 'rn-calendar',
	props: {
		referenceDate: {
			type: Date,
			default: () => new Date(),
		},
		selectedDate: {
			type: Date,
			default: () => new Date(),
		},
		collectionOrders: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		...mapGetters(['businessRelationId']),
		getWeeksInMonth() {
			// calculate first week of month
			const firstOfMonth = new moment(this.referenceDate).startOf('month').toDate();
			const firstOfMonthDayOfWeek = (firstOfMonth.getDay() || 7) - 1;
			const firstWeek = [];

			// included days from prev month for this week
			for (let i = firstOfMonthDayOfWeek; i > 0; i--) {
				firstWeek.push({
					date: new moment(firstOfMonth).subtract(i, 'days').toDate(),
					isPrevMonth: true,
				});
			}

			// rest of this week
			for (let i = 0; i <= 6 - firstOfMonthDayOfWeek; i++) {
				firstWeek.push({
					date: new moment(firstOfMonth).add(i, 'days').toDate(),
				});
			}

			// calculate last week of month
			const lastOfMonth = new moment(this.referenceDate)
				.endOf('month')
				.startOf('day')
				.toDate();
			const lastOfMonthDayOfWeek = (lastOfMonth.getDay() || 7) - 1;
			const lastWeek = [];

			// days from this month for this week
			for (let i = lastOfMonthDayOfWeek; i >= 0; i--) {
				lastWeek.push({
					date: new moment(lastOfMonth).subtract(i, 'days').toDate(),
				});
			}

			// included days from next month for this week
			for (let i = 1; i <= 6 - lastOfMonthDayOfWeek; i++) {
				lastWeek.push({
					date: new moment(lastOfMonth).add(i, 'days').toDate(),
					isNextMonth: true,
				});
			}

			// calculate middle weeks of month
			const middleWeeks = [];
			const firstDayOfSecondWeek = new moment(firstWeek[firstWeek.length - 1].date).add(1, 'days').toDate();

			for (let i = 0; i <= 3; i++) {
				const week = [];

				for (let j = 0; j <= 6; j++) {
					const date = new moment(firstDayOfSecondWeek).add(j + i * 7, 'days').toDate();

					// deduplicate last week
					if (date < lastWeek[0].date) {
						week.push({ date });
					}
				}

				middleWeeks.push(week);
			}

			return [firstWeek, ...middleWeeks, lastWeek];
		},
	},
	updated() {
		if (this.selectedDate && this.collectionOrders) {
			this.setSelectedDate(this.selectedDate);
		}
	},
	methods: {
		setSelectedDate(date) {
			this.$emit('update:selectedDate', {
				date,
				collections: (this.collectionOrders || []).filter(cp => !!new moment(new Date(cp.plannedPickUpDate)).isSame(date, 'day')),
			});
		},
		hasPlannedCollectionOrders(day) {
			if (!this.collectionOrders || !this.collectionOrders.length) {
				return false;
			}

			return (this.collectionOrders || []).find(cp => new moment(new Date(cp.plannedPickUpDate)).isSame(day.date, 'day'));
		},
	},
};
