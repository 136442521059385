import '@icons';
import { IconDirections, IconClasses } from './icon.enums';

export default {
	name: 'rn-icon',
	props: {
		name: {
			type: String,
		},
		direction: {
			type: String,
			default: 'up',
			validator: value => {
				const iconDirections = [IconDirections.Up, IconDirections.Right, IconDirections.Down, IconDirections.Left];

				return !!~iconDirections.indexOf(value);
			},
		},
		original: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String,
		},
		height: {
			type: String,
		},
		backgroundSize: {
			type: String,
			default: 'cover',
		},
		label: {
			type: String,
			required: false,
			default: undefined,
		},
		tooltip: {
			type: Object,
			default: undefined,
		},
		color: {
			type: String,
			required: false,
			default: undefined,
		},
		zIndex: {
			type: Number,
			required: false,
			default: 0,
		},
		spin: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		iconClasses() {
			return {
				[IconClasses.Base]: true,
				[IconClasses.Original]: this.original,
				[IconClasses.HasLabel]: this.label,
				// color
				[IconClasses.Blue]: this.color === 'blue',
				[IconClasses.Green]: this.color === 'green',
				[IconClasses.Red]: this.color === 'red',
				[IconClasses.Grey]: this.color === 'grey',
				[IconClasses.White]: this.color === 'white',
			};
		},
		type() {
			if (this.name && this.name.indexOf('.') !== -1) {
				const extension = this.name.substring(this.name.lastIndexOf('.') + 1);
				if (extension === 'jpg' || extension === 'png' || extension === 'gif') {
					return 'image';
				}
			}
			if (this.name && this.name.substring(0, 'data:image/'.length) === 'data:image/') {
				return 'image';
			}

			return 'icon';
		},
	},
};
