/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'packaging': {
    width: 16,
    height: 16,
    viewBox: '0 0 182 188.8',
    data: '<g _fill="#191a1a"><path pid="0" d="M96.4 188.8l-.5-.3L0 136.8V37L91.9 0 182 33.2v102.5zM2 135.6l94.3 50.8 83.7-51.9v-100L92 2.1 2 38.3z"/><path pid="1" d="M96.6 79l-.4-.2L1 38.8c-.5-.2-.7-.8-.5-1.3s.8-.8 1.3-.5l94.7 39.9 84-43.8c.5-.3 1.1-.1 1.3.4.3.5.1 1.1-.4 1.3z"/><path pid="2" d="M96.5 188c-.6 0-1-.4-1-1V78.3c0-.6.4-1 1-1s1 .4 1 1V187c0 .6-.5 1-1 1z"/></g><path pid="3" d="M57.5 14.1l-.4-.1L40 20.8l92.1 38.6-.2 39.4 14.8-8.9.2-38.8z" _fill="#ec6244"/>'
  }
})
