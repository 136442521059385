import { mapGetters } from 'vuex';
import get from 'lodash/get';

import { Roles } from '@/enums/roles';
import apiService from '@/services/api';
import { RnButton, RnField, RnIcon, RnHeader, RnNewTable, RnCardList, RnSpinner } from '@/components';
import { batteriesMixin } from '@/mixins';
import { MessageTypes } from '@/enums/message-types';
import { logAndToastError } from '@/utils';
import { downloadAttachment } from '@/utils/download-attachment';

export default {
	name: 'rn-cp-update-collection-view',
	components: {
		RnButton,
		RnField,
		RnIcon,
		RnHeader,
		RnNewTable,
		RnCardList,
		RnSpinner,
	},
	mixins: [batteriesMixin],
	data() {
		return {
			state: {
				date: null,
				destination: null,
				saveInProgress: false,
				destinationError: null,
			},
		};
	},
	computed: {
		...mapGetters(['businessRoles', 'businessRelationId', 'locationsByRoles', 'collectionOrders', 'collectionOrderTransportPackagings']),
		collectionOrder() {
			return get(this.collectionOrders, '[0]', null);
		},
		destinationLocations() {
			return this.locationsByRoles.map(location => ({
				name: location.name,
				value: location.id,
			}));
		},
		location() {
			return this.$store.getters.locationsLocation(this.collectionOrder.sourceLocation.id);
		},
		transportPackagingCardList() {
			if (!this.collectionOrderTransportPackagings || !this.collectionOrderTransportPackagings.length) {
				return null;
			}

			return this.collectionOrderTransportPackagings.map(transportPackaging =>
				// Return the card info object
				({
					grid: { cols: 4 },
					icon: transportPackaging.icon,
					info: [
						{
							label: 'Packaging method',
							value: transportPackaging.name,
						},
						{
							label: 'Amount',
							value: transportPackaging.amount,
						},
					],
				})
			);
		},
		overviewCardList() {
			const cardGridOptions = { grid: { cols: 4 } };
			const list = [];

			if (this.collectionOrder && this.location) {
				const locationInfoCard = {
					info: [
						{
							label: this.$t('cp.newCollection.locationInformation'),
							value: `${this.location.name}<br>
							${this.location.addresses[0].street} ${this.location.addresses[0].number}<br>
							${this.location.addresses[0].zipCode} ${this.location.addresses[0].city}<br><br>
							${this.$t('user.contactInfo.email').toUpperCase()}: ${this.location.email || '-'}<br>
							${this.$t('user.contactInfo.phone').toUpperCase()}: ${this.location.phone || '-'}`,
						},
					],
					...cardGridOptions,
				};

				list.push(locationInfoCard);

				list.push({
					info: [
						{
							label: this.$t('common.deliveryNote'),
							component: {
								name: RnButton,
								props: {
									text: this.$t('common.downloadDeliveryNote'),
									color: 'blue',
									outline: true,
									disableWrap: true,
									icon: {
										name: 'download', // icon name
										pos: 'right', // left or right
										width: '20px',
										height: '20px',
										original: false,
										color: 'blue',
									},
									method: this.startDownloadDeliveryNote,
								},
							},
						},
					],
					...cardGridOptions,
				});
			}

			return list;
		},
		collectionOrdersState() {
			if (!this.collectionOrders) {
				return 'loading';
			}
			if (this.collectionOrders[0]) {
				return 'results';
			}
			return 'noResults';
		},
	},
	watch: {
		collectionOrders(newOrders) {
			const locationId = get(newOrders, '[0].sourceLocation.id');
			const collectionOrderId = get(newOrders, '[0].id');
			if (locationId) {
				this.$store.dispatch('getLocation', { locationId });
				if (collectionOrderId) {
					this.$store.dispatch('getCollectionOrderPackagings', { collectionOrderId });
				}
			}
		},
		businessRoles: {
			immediate: true,
			handler(value) {
				if (value.length) {
					const roles = [Roles.RC, Roles.SD];
					const roleIds = this.$store.getters.businessRoleIds(roles);

					this.$store.dispatch('getLocationsByRoles', { roleIds, businessRelationId: this.businessRelationId });
				}
			},
		},
		collectionOrder(value) {
			if (value) {
				this.setFormValue('destination', value.destinationLocation.id);

				if (value.plannedPickUpDate) {
					this.setFormValue('date', value.plannedPickUpDate);
				}
			}
		},
	},
	created() {
		this.$store.dispatch('getCollectionOrder', { businessRelationId: this.businessRelationId, collectionOrderId: this.$route.params.collectionOrderId });
		this.$store.dispatch('getCollectionOrderPackagings', { collectionOrderId: this.$route.params.collectionOrderId });
	},
	methods: {
		startDownloadDeliveryNote() {
			downloadAttachment(this.collectionOrder.packingSlipId, 'message.deliveryOrder.error', this.$store);
		},
		setFormValue(prop, value) {
			this.state[prop] = value;
			if (this.state.destinationError) {
				this.validateForm();
			}
		},
		validateForm() {
			this.state.destinationError = this.state.destination ? null : this.$t('error.required', [this.$t('common.destination')]);

			return this.state.destination;
		},
		scheduleCollection() {
			if (!this.validateForm()) {
				return;
			}

			this.state.saveInProgress = true;
			const url = `/businessrelations/${this.businessRelationId}/collectionorders/${this.collectionOrder.id}`;
			const body = {
				plannedPickupDate: this.state.date ? this.state.date : null,
				destinationLocationId: this.state.destination,
			};
			apiService
				.patch(url, body)
				.then(({ data: response }) => {
					if (response.success) {
						this.$store.dispatch('setMessage', { text: 'message.scheduleCollection.success', type: MessageTypes.SUCCESS });
						this.$router.push({ name: 'CPCollections' });
					}
					this.state.saveInProgress = false;
				})
				.catch(err => {
					logAndToastError(this.$store.dispatch, 'Failed to scheduleCollection', err, { url, body });
					this.state.saveInProgress = false;
				});
		},
	},
};
