import config from '@/config';
import store from '@/store';

import get from 'lodash/get';

export default function(to, from, next) {
	const language = get(to, 'params.language');

	const supportedLanguage = config.supportedLanguages.find(l => l.value === language);

	if (supportedLanguage) {
		store.dispatch('setLanguage', supportedLanguage.value);
		next();
	} else {
		next({ name: 'Error', params: { language: config.defaultLanguage } });
	}
}
