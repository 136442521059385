import get from 'lodash/get';

import RnField from '../components/field/field.vue';

function packagingsToCardInfos(selectedPackagings = [], allPackagings = [], props = {}, $t, removePackaging, updateAmount) {
	const packagingsCardList = [];

	// Sometimes we cannot use id in the selectedPackagings, so we use receptacleId instead
	const mappedReceptacles = selectedPackagings.map(selectedPackaging => {
		const packagingInfo = (allPackagings || []).find(receptacle => receptacle.id === (selectedPackaging.receptacleId || selectedPackaging.id));

		return {
			...(packagingInfo || selectedPackaging.transportPackaging),
			amount: selectedPackaging.amount,
		};
	});

	mappedReceptacles.forEach(packaging => {
		if (packaging) {
			const selectedPackaging = selectedPackagings.find(r => packaging.id === r.id);

			const tempId = selectedPackaging ? selectedPackaging.tempId || selectedPackaging.receptacleId : null;

			packagingsCardList.push({
				icon: { name: get(packaging, 'icon.name', 'receptacle-pallet-box') },
				tempId,
				info: [
					{
						label: $t('common.packagingMethod'),
						value: packaging.name || $t('addBattery.unpackaged'),
					},
					{
						label: $t('common.amount'),
						...(props.editable
							? {
									// Show number input when editable
									component: {
										name: RnField,
										props: {
											inputProps: {
												id: 'receptacle-amount',
												type: 'number',
												min: 1,
											},
											value: packaging.amount,
										},
										events: {
											'update:value': updatedAmount => updateAmount(updatedAmount, tempId),
										},
									},
									// or just show the amount
							  }
							: { value: packaging.amount || 1 }),
					},
				],
				...props,
				...(props.removeable && { removeHandler: removePackaging }),
			});
		}
	});

	return packagingsCardList;
}

export const packagingService = {
	packagingsToCardInfos,
};
