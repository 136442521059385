import { render, staticRenderFns } from "./textarea.vue?vue&type=template&id=4dd22cfe&scoped=true"
import script from "./textarea.js?vue&type=script&lang=js&external"
export * from "./textarea.js?vue&type=script&lang=js&external"
import style0 from "./textarea.scss?vue&type=style&index=0&id=4dd22cfe&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dd22cfe",
  null
  
)

export default component.exports