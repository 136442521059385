/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'cross': {
    width: 16,
    height: 16,
    viewBox: '-471 273 16 16',
    data: '<path pid="0" d="M-457 273l-6 6-6-6-2 2 6 6-6 6 2 2 6-6 6 6 2-2-6-6 6-6"/>'
  }
})
