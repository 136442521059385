import { RnMenu } from '@/components';

export default {
	name: 'rn-menus',
	components: {
		RnMenu,
	},
	props: {
		menus: {
			type: Array,
			default: () => [],
		},
	},
};
