import RnIcon from '../icon/icon.vue';

export default {
	name: 'rn-attachment-preview',
	components: {
		RnIcon,
	},
	props: {
		type: {
			type: String,
			default: 'image',
		},
		title: {
			type: String,
			default: '',
		},
		callback: {
			type: Function,
			default: () => null,
		},
	},
	computed: {
		getTypeIcon() {
			return this.type === 'document' ? 'attachment-pdf' : 'attachment-image';
		},
	},
};
