
import {RnIcon, RnButton , RnTag} from "@/components";
import config from '@/config';

export default {
	name: 'rn-resource-card',
	components: {
		RnIcon,
		RnTag,
		RnButton,
	},
	props: {
		showDelete: {
			type: Boolean,
			default: false,
		},
		resource: {
			type: Object,
			required: true,
		},
	},
	methods: {
		getLanguageName(key) {
			return config.supportedLanguages.find(({value}) => value === key)?.name;
		},
		downloadFile(fileName) {
			window.open(`/server/resources/file/${fileName}`, '_blank');
		},
	},
};
