import * as validators from '@/utils/validators';
import { mapGetters } from 'vuex';

const businessRelationModel = {
	name: '',
	email: '',
	phone: '',
	language: '',
};

const businessRelationFormMixin = {
	data() {
		return {
			businessRelationFormModel: {
				...businessRelationModel,
			},
			businessRelationFormFieldErrors: {
				...businessRelationModel,
			},
		};
	},
	computed: {
		...mapGetters(['isBusinessRelationChangesPending', 'languages']),
		businessRelationFieldGroups() {
			return [
				{
					fields: [
						{
							cols: '6',
							inputProps: {
								id: 'business-relation-name',
								placeholder: this.$t('helpdesk.contactForm.name.placeholder'),
								type: 'text',
							},
							label: this.$t('helpdesk.contactForm.name'),
							modelKey: 'name',
							required: true,
							onValueChanged: () => this.validate('name'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'business-relation-email',
								placeholder: this.$t('user.contactInfo.email.placeholder'),
								type: 'email',
							},
							label: this.$t('user.contactInfo.email'),
							modelKey: 'email',
							required: true,
							onValueChanged: () => this.validate('email'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'business-relation-phone',
								placeholder: this.$t('user.contactInfo.phone.placeholder'),
								type: 'text',
							},
							label: this.$t('user.contactInfo.phone'),
							modelKey: 'phone',
							required: true,
							onValueChanged: () => this.validate('phone'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'business-relation-language',
								options: this.languageOptions,
								placeholder: this.$t('user.contactInfo.language.placeholder'),
								type: 'select',
							},
							label: this.$t('user.contactInfo.language'),
							modelKey: 'language',
							required: true,
							onValueChanged: () => this.validate('language'),
						},
					],
				},
			];
		},
		languageOptions() {
			return this.languages.map(lang => ({
				value: lang.id,
				name: lang.name,
			}));
		},
		brForm() {
			return this.$store.state.forms.businessRelation;
		},
		businessRelationForm() {
			return {
				model: this.businessRelationFormModel,
				fieldGroups: this.businessRelationFieldGroups,
				modelUpdateHandler: this.updateBusinessRelationFormModel,
				actionHandler: this.businessRelationFormAction,
				form: this.brForm,
				fieldErrors: this.businessRelationFormFieldErrors,
			};
		},
	},
	methods: {
		validate(prop) {
			// If the input field has an error, and the value changes, re-validate the form
			if (this.businessRelationFormFieldErrors[prop]) {
				// Validate the form after the values are set
				setTimeout(() => this.validateBusinessRelationFormModel(this.businessRelationFormModel));
			}
		},
		updateBusinessRelationFormModel(newModel) {
			this.businessRelationFormModel = newModel;
		},
		businessRelationFormAction() {
			if (this.validateBusinessRelationFormModel(this.businessRelationFormModel)) {
				this.$store.dispatch('updateBusinessRelation', this.businessRelationFormModel);
			}
		},
		clearBusinessRelationFormFieldErrors() {
			this.businessRelationFormFieldErrors = {
				...businessRelationModel,
			};
		},
		clearBusinessRelationFormError() {
			this.$store.dispatch('clearFormErrors', { form: 'businessRelation' });
		},
		validateBusinessRelationFormModel(model) {
			this.clearBusinessRelationFormError();
			this.clearBusinessRelationFormFieldErrors();

			let valid = true;

			if (!model.name) {
				this.businessRelationFormFieldErrors.name = this.$t('error.required', [this.$t('helpdesk.contactForm.name')]);
				valid = false;
			}

			if (!model.email) {
				this.businessRelationFormFieldErrors.email = this.$t('error.required', [this.$t('user.contactInfo.email')]);
				valid = false;
			} else if (!validators.email(model.email)) {
				this.businessRelationFormFieldErrors.email = this.$t('error.invalid', [this.$t('user.contactInfo.email')]);
				valid = false;
			}

			if (!model.phone) {
				this.businessRelationFormFieldErrors.phone = this.$t('error.required', [this.$t('user.contactInfo.phone')]);
				valid = false;
			}

			if (!model.language) {
				this.businessRelationFormFieldErrors.language = this.$t('error.required', [this.$t('user.contactInfo.language')]);
				valid = false;
			}

			return valid;
		},
	},
	// watch: {
	// 	businessRelation: {
	// 		immediate: true,
	// 		handler() {
	// 			this.businessRelationFormModel = {
	// 				name: this.businessRelation.name,
	// 				email: this.businessRelation.email,
	// 				phone: this.businessRelation.phone,
	// 				language: this.businessRelation.language.substring(0, 2),
	// 			};
	// 		},
	// 	},
	// },
};

export default businessRelationFormMixin;
