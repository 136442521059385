/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  're-use': {
    width: 16,
    height: 16,
    viewBox: '0 0 137.655 125',
    data: '<defs><path pid="0" id="svgicon-re-use-a" d="M100.363 87.154c-2.634-4.675-7.521-7.54-12.976-7.54-4.379 0-8.548 1.958-11.354 5.229a12.954 12.954 0 0 0-5.294-1.722V72.903c9.642-.754 17.258-8.769 17.258-18.532 0-11.131-8.84-24.521-17.386-31.037l-1.39-1.06-1.363 1.094c-8.408 6.738-17.343 19.895-17.343 31.003 0 9.763 7.616 17.777 17.258 18.532v10.256c-.689.087-1.369.224-2.031.42a13.056 13.056 0 0 0-9.352-3.964c-4.92 0-9.317 2.728-11.507 6.963a13.108 13.108 0 0 0-2.272-.196c-7.137 0-12.944 5.768-12.944 12.859v2.209h79.175v-2.21c.001-5.408-3.44-10.243-8.479-12.086zm-31.762-38.23v12.76L54.555 48.749c.806-3.047 2.179-6.177 3.893-9.178l10.153 9.353zM59.32 38.107c2.642-4.27 5.943-8.202 9.28-11.148v19.7l-9.28-8.552zm9.281 25.841v6.065c-8.261-.432-14.853-7.245-14.853-15.557 0-1.243.13-2.531.38-3.839l14.473 13.331zm11.916-24.46c1.761 3.102 3.143 6.361 3.904 9.555L70.27 62.07V48.914l10.247-9.426zM70.27 46.651V26.914c3.376 2.863 6.722 6.784 9.375 11.103l-9.375 8.634zm14.539 4.29c.204 1.196.306 2.365.306 3.516 0 8.312-6.592 15.125-14.846 15.557v-5.68l14.54-13.393zM32.765 98.412c.427-5.026 4.673-8.994 9.846-8.994 1.141 0 2.245.193 3.302.562l.807.285.269-.809c1.317-4.069 5.1-6.804 9.401-6.804a9.95 9.95 0 0 1 7.984 4.032l.399.544.62-.286a9.93 9.93 0 0 1 4.044-.847c2.41 0 4.737.866 6.537 2.458l.75.663.52-.856c2.17-3.572 5.953-5.707 10.143-5.707 4.469 0 8.605 2.559 10.599 6.527v.451l.612.167c3.959 1.114 6.806 4.583 7.149 8.615H32.765z"/></defs><use xlink:href="#svgicon-re-use-a" overflow="visible" fill-rule="evenodd" clip-rule="evenodd" _fill="#f86439"/>'
  }
})
