import isEqual from 'lodash/isEqual';

import RnField from '../field/field.vue';

export default {
	name: 'rn-confirm',
	components: { RnField },
	props: {
		prompt: {
			type: Object,
			default: () => ({
				title: '',
				message: '',
				action: '',
			}),
		},
		open: {
			type: Boolean,
			default: false,
		},
		checked: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			checkedItems: [],
		};
	},
	computed: {
		confirmFieldProps() {
			if (this.prompt.action.constructor === Array) {
				return this.prompt.action.map((value, i) => ({
					id: `confirm-action_${i}`,
					options: [
						{
							checked: this.checked,
							name: value,
						},
					],
					type: 'checkbox',
				}));
			}
			return [
				{
					id: 'confirm-action',
					options: [
						{
							checked: this.checked,
							name: this.prompt.action,
						},
					],
					type: 'checkbox',
				},
			];
		},
	},
	methods: {
		checkConfirm(item, checked) {
			const itemIndex = this.checkedItems.findIndex(arrayItem => isEqual(item, arrayItem));

			if (checked) {
				this.checkedItems.push(item);
			} else if (itemIndex > -1) {
				this.checkedItems.splice(itemIndex, 1);
			}

			const allChecked = this.checkedItems.length === this.confirmFieldProps.length;
			this.$emit('update:checked', allChecked); // Emit the overall checked state
			this.$emit('update:checked', checked); // Emit the individual checked state for the item
		},
	},
};
