import get from 'lodash/get';
import { saveAs } from 'file-saver';

import apiService from '@/services/api';
import { logAndToastError } from './errorHandler';

/**
 * Example fileInfo:
 * {
 * 	 "fileNameWithExtension": "electric-car-battery.jpg",
 * 	 "content": "/9j/4AAQSkZJRgABAQEAYABgAAD/4QAuRXhpZgAATU0AKgAA...",
 * 	 "contentType": "image/jpeg"
 * }
 * @param fileInfo
 */
export function downloadAttachmentByFileInfo(fileInfo) {
	saveAs(`data:${fileInfo.contentType};base64,${fileInfo.content}`, fileInfo.fileNameWithExtension);
}

/**
 * Gets the fileInfo for the attachment from the api, then downloads the file to the client browser
 * @param attachmentId
 * @param errorMessage
 * @param store
 */
function downloadAttachmentById(attachmentId, errorMessage, store) {
	const url = `/attachments/${attachmentId}`;
	apiService
		.get(url)
		.then(response => {
			const data = get(response, 'data');
			if (!data || !data.fileNameWithExtension || !data.contentType || !data.content) {
				logAndToastError(store.dispatch, `${errorMessage}: Failed to get attachment info (empty response)`, null, { url, response });

				return;
			}
			downloadAttachmentByFileInfo(data);
		})
		.catch(err => {
			logAndToastError(store.dispatch, 'Failed to get attachment info', err, { url });
		});
}

/**
 * Download an attachment by passing the id of the attachment or the fileInfo of the attachment
 * @param attachmentIdOrFileInfo
 * @param errorMessage Message to be shown in a toast message when an error occurs
 * @param store The store object to be able to dispatch a setMessage to show the error message
 */
export function downloadAttachment(attachmentIdOrFileInfo, errorMessage, store) {
	if (typeof attachmentIdOrFileInfo === 'string') {
		// attachment id was passed
		downloadAttachmentById(attachmentIdOrFileInfo, errorMessage, store);
	} else {
		// fileInfo was passed
		downloadAttachmentByFileInfo(attachmentIdOrFileInfo);
	}
}

function base64ToArrayBuffer(base64) {
	const binaryString = window.atob(base64);
	const binaryLen = binaryString.length;
	const bytes = new Uint8Array(binaryLen);
	for (let i = 0; i < binaryLen; i++) {
		const ascii = binaryString.charCodeAt(i);
		bytes[i] = ascii;
	}
	return bytes;
}

export function downloadExcelFile(response) {
	const data = response.data;
	const byteArray = base64ToArrayBuffer(data.fileAsByteArray);
	const a = window.document.createElement('a');

	a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
	a.download = data.fileNameWithExtension;

	// Append anchor to body.
	document.body.appendChild(a);
	a.click();

	// Remove anchor from body
	document.body.removeChild(a);
}
