/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'receptacle-pallet-box': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M3.712 18.987l1.481 1.023v.69l-1.897-1.31V9.85l.416 2.463v6.674zM7.05 21.287l2.01 1.387v.695l-2.457-1.696V11.655l.448 2.656v6.976z" _fill="#191A1A"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M13.108 24.985a.314.314 0 0 1 .1-.003L18 24.131c.008.514.065 1.017.166 1.504l-4.685.833v.783a.32.32 0 0 1-.26.316l-2.322.428h-.008l-.006.002h-.006l-.004.001a.31.31 0 0 1-.095-.005l-.007-.002a.322.322 0 0 1-.033-.009l-.013-.005a.302.302 0 0 1-.054-.028l-.017-.01-1.596-1.169a.322.322 0 0 1-.13-.26v-.681l-1.153-.814-.77.133h-.003-.002a.31.31 0 0 1-.1 0l-.01-.003-.006-.001a.31.31 0 0 1-.082-.031l-.02-.012-.006-.004-.01-.006-1.49-1.093a.322.322 0 0 1-.13-.26v-.579l-.855-.603-.677.11h-.003-.003a.316.316 0 0 1-.107-.003l-.006-.002a.288.288 0 0 1-.034-.01c-.005 0-.01-.003-.014-.005l-.005-.002a.281.281 0 0 1-.046-.024l-.002-.002-.007-.003-.009-.006-1.391-1.02a.322.322 0 0 1-.131-.26v-.492l-.356-.251A1.195 1.195 0 0 1 1 19.609V7.121c0-.607.451-1.117 1.047-1.183l17.364-1.93a1.161 1.161 0 0 1 .542.068l10.285 3.917c.459.175.762.619.762 1.114v8.648a8.008 8.008 0 0 0-1.492-.947V9.472L11.16 11.849v13.495l1.95-.36zm-2.582.129v-13.35l-8.32-4.267V19.29a.314.314 0 0 1 .15.055l6.884 4.86c.033.023.06.052.081.085.038.009.074.024.107.048l1.098.775zm.373-13.878l8.42-1.09V5.537L3.268 7.322l7.63 3.914z" _fill="#191A1A"/><path pid="2" d="M27.534 16.147v-4.622a.032.032 0 0 0-.028-.032.032.032 0 0 0-.034.024l-.545 2.295a.03.03 0 0 0-.001.007v2.234c.205.024.408.055.608.094zM19.661 19.119v-6.51a.032.032 0 1 0-.062-.01l-.544 1.722a.03.03 0 0 0-.002.01v5.699a8.01 8.01 0 0 1 .608-.911zM18.113 22.65l-4.356.737a.032.032 0 0 0-.017.009l-.597.598a.033.033 0 0 0-.006.038.032.032 0 0 0 .034.017l4.866-.823c.019-.194.044-.386.076-.576z" _fill="#191A1A"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M32 24a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm-9.585 0l2.085 2.085 5.295-5.29.705.705-6 6-2.795-2.795.71-.705z" _fill="#10BC60"/>'
  }
})
