/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'box-grey': {
    width: 16,
    height: 16,
    viewBox: '0 0 80 63',
    data: '<defs/><g id="Layer_2" data-name="Layer 2"><g id="tracking"><g id="_-Symbols" data-name="⚙-Symbols"><g id="atom-_-illustration-_-box" data-name="atom-/-illustration-/-box"><g id="box"><path pid="0" id="path-1" class="cls-1" d="M25.4 20.38v35l-2.93-2a.9.9 0 0 0-.28-.13A.92.92 0 0 0 22 53l-5.61-3.9-4-2.76-5.06-3.48-3.71-2.58a.84.84 0 0 0-.4-.14v-31zm23.45-4.25L26.4 19 6.05 8.72 48.85 4zM55.73 51h-.23l-23 4h-.26l-5.2 1V20.6L76 14.36v33.12l-5.49 1-4.94.86zM78 10.48L50.54.2a3.19 3.19 0 0 0-1.1-.2h-.34L2.79 5.09A3.12 3.12 0 0 0 0 8.19V41a3.13 3.13 0 0 0 1.34 2.57l1 .66v1.29a.84.84 0 0 0 .35.68l3.71 2.68H7l1.8-.29 2.28 1.58v1.52a.83.83 0 0 0 .35.68l4 2.87H16l2.05-.34 3.08 2.13v1.79a.84.84 0 0 0 .35.68l4.25 3.07H26.33l6.19-1.13a.84.84 0 0 0 .69-.83V59l12.91-2.26 3 1.9a.2.2 0 0 0 .08 0H49.62L56 57.61a.85.85 0 0 0 .7-.83v-1.9l10.35-1.81 3.19 1.77h.54L77 53.81a.85.85 0 0 0 .69-.83v-1.79a3.12 3.12 0 0 0 2.29-3V13.41A3.14 3.14 0 0 0 78 10.48zM11.18 42l-3.95-2.66V21.82l-1.11-6.46v25l5.06 3.44zm10.31 7l-5.35-3.64V27.07l-1.2-7v26.3l6.55 4.46zm28.2-26.5a.07.07 0 0 0-.09.05l-1.45 4.52v21.47L34 50.89h-.05l-1.59 1.58a.06.06 0 0 0 0 .09.08.08 0 0 0 .07.05l17.24-2.87a.08.08 0 0 0 .07-.08V22.6a.08.08 0 0 0-.07-.08m21 23.65a.1.1 0 0 0 .07-.09V19.75a.08.08 0 0 0-.08-.08.08.08 0 0 0-.09.06l-1.45 6V45l-11.67 2-1.61 1.59a.09.09 0 0 0 0 .1.08.08 0 0 0 .07 0z"/></g></g></g></g></g>'
  }
})
