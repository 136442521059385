import { RnActionHeader, RnForm, RnButton, RnIcon } from '@/components';
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import * as validators from '../../utils/validators';
import serverService from '../../services/server';
import { MessageTypes } from '../../enums/message-types';
import { logAndToastError } from '../../utils/errorHandler';

const initialHelpdeskFormState = {
	name: '',
	email: '',
	phone: '',
	regarding: '',
	message: '',
};

export default {
	name: 'rn-helpdesk-view',
	components: {
		RnActionHeader,
		RnForm,
		RnButton,
		RnIcon,
	},
	data() {
		return {
			helpdeskInfoFormModel: {
				...initialHelpdeskFormState,
			},
			helpdeskInfoFormFieldErrors: {
				...initialHelpdeskFormState,
			},
			regardingOptions: [],
			isSendingMail: false,
			honeypotValue: '',
		};
	},
	computed: {
		...mapGetters(['locationId', 'user']),
		helpdeskInfoFieldGroups() {
			return [
				{
					fields: [
						{
							cols: '4',
							push: '8',
							inputProps: {
								id: 'helpdesk-info-name',
								placeholder: this.$t('helpdesk.contactForm.name.placeholder'),
								type: 'text',
							},
							label: this.$t('helpdesk.contactForm.name'),
							modelKey: 'name',
							required: true,
							onValueChanged: () => this.validate('name'),
						},
						{
							cols: '4',
							inputProps: {
								id: 'helpdesk-info-email',
								placeholder: this.$t('helpdesk.contactForm.email.placeholder'),
								type: 'email',
							},
							label: this.$t('helpdesk.contactForm.email'),
							modelKey: 'email',
							required: true,
							onValueChanged: () => this.validate('email'),
						},
						{
							cols: '4',
							inputProps: {
								id: 'helpdesk-info-phone',
								placeholder: this.$t('helpdesk.contactForm.phoneNumber.placeholder'),
								type: 'text',
							},
							label: this.$t('helpdesk.contactForm.phoneNumber'),
							modelKey: 'phone',
							required: false,
						},
						{
							cols: '4',
							push: '8',
							inputProps: {
								id: 'helpdesk-info-regarding',
								placeholder: this.$t('helpdesk.contactForm.regarding.placeholder'),
								options: this.regardingOptions,
								type: 'select',
							},
							label: this.$t('helpdesk.contactForm.regarding'),
							modelKey: 'regarding',
							required: true,
							onValueChanged: () => this.validate('regarding'),
						},
						{
							cols: '4',
							inputProps: {
								id: 'helpdesk-info-message',
								placeholder: this.$t('helpdesk.contactForm.message.placeholder'),
								type: 'textarea',
							},
							label: this.$t('helpdesk.contactForm.message'),
							modelKey: 'message',
							required: true,
							onValueChanged: () => this.validate('message'),
						},
					],
				},
			];
		},
		submitButtonLabel() {
			return this.isSendingMail ? this.$t('helpdesk.contactForm.sendingMessage') : this.$t('helpdesk.contactForm.sendMessage');
		},
	},
	watch: {
		user: {
			immediate: true,
			handler() {
				this.helpdeskInfoFormModel.name = this.helpdeskInfoFormModel.name || this.user ? `${this.user.firstName} ${this.user.lastName}` : '';
				this.helpdeskInfoFormModel.email = this.helpdeskInfoFormModel.email || get(this.user, 'email', '');
				this.helpdeskInfoFormModel.phone = this.helpdeskInfoFormModel.phone || get(this.user, 'phone', '');
			},
		},
	},
	created() {
		this.initRegardingOptions();
	},
	methods: {
		modelUpdateHandler(newModel) {
			this.helpdeskInfoFormModel = {
				...this.helpdeskInfoFormModel,
				...newModel,
			};
		},
		validate(prop) {
			// If the input field has an error, and the value changes, re-validate the form
			if (this.helpdeskInfoFormFieldErrors[prop]) {
				// Validate the form after the values are set
				setTimeout(() => this.validateHelpdeskInfoFormModel(this.helpdeskInfoFormModel));
			}
		},
		helpdeskInfoFormAction() {
			if (this.validateHelpdeskInfoFormModel(this.helpdeskInfoFormModel)) {
				this.isSendingMail = true;
				const url = '/support/create';
				const body = {
					...this.helpdeskInfoFormModel,
					honeypot: this.honeypotValue,
					locationId: this.locationId, // Can be undefined if user is not logged in
				};
				serverService
					.post(url, body)
					.then(() => {
						this.clearHelpdeskForm();
						this.$store.dispatch('setMessage', { text: 'helpdesk.contactForm.successMessage', type: MessageTypes.SUCCESS });
					})
					.catch(err => {
						logAndToastError(this.$store.dispatch, this.$t('helpdesk.contactForm.errorMessage'), err, { url, body });
					})
					.finally(() => {
						this.isSendingMail = false;
					});
			}
		},
		clearHelpdeskInfoFormFieldErrors() {
			this.helpdeskInfoFormFieldErrors = {
				...initialHelpdeskFormState,
			};
		},
		clearHelpdeskInfoFormError() {
			this.$store.dispatch('clearFormErrors', { form: 'helpdeskInfo' });
		},
		clearHelpdeskForm() {
			this.helpdeskInfoFormModel = {
				...initialHelpdeskFormState,
			};
		},
		validateHelpdeskInfoFormModel(model) {
			this.clearHelpdeskInfoFormError();
			this.clearHelpdeskInfoFormFieldErrors();

			let valid = true;

			if (!model.name) {
				this.helpdeskInfoFormFieldErrors.name = this.$t('error.required', [this.$t('helpdesk.contactForm.name')]);
				valid = false;
			}

			if (!model.email) {
				this.helpdeskInfoFormFieldErrors.email = this.$t('error.required', [this.$t('helpdesk.contactForm.email')]);
				valid = false;
			} else if (!validators.email(model.email)) {
				this.helpdeskInfoFormFieldErrors.email = this.$t('error.invalid', [this.$t('helpdesk.contactForm.email')]);
				valid = false;
			}

			if (!model.regarding) {
				this.helpdeskInfoFormFieldErrors.regarding = this.$t('error.required', [this.$t('helpdesk.contactForm.regarding')]);
				valid = false;
			}

			if (!model.message) {
				this.helpdeskInfoFormFieldErrors.message = this.$t('error.required', [this.$t('helpdesk.contactForm.message')]);
				valid = false;
			}

			return valid;
		},
		initRegardingOptions() {
			const prefix = 'helpdesk.contactForm.regarding.reasons.';
			const numberOfTopics = 4;
			this.regardingOptions = [];

			for (let i = 0; i < numberOfTopics; i++) {
				const translationString = this.$t(prefix + i);
				this.regardingOptions.push({
					name: translationString,
					value: translationString,
				});
			}
		},
	},
};
