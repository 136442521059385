import RnButton from '../button/button.vue';
import { ITEMS_PER_PAGE } from '../../services/constants';

export default {
	name: 'rn-pagination',
	components: {
		RnButton,
	},
	props: {
		currentPage: {
			type: Number,
			default: 0,
		},
		itemsPerPage: {
			type: Number,
			default: ITEMS_PER_PAGE.DEFAULT,
		},
		totalItems: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		totalPages() {
			return Math.ceil(this.totalItems / this.itemsPerPage);
		},
	},
	methods: {
		previous() {
			if (this.currentPage > 0) {
				this.$emit('update:page', this.currentPage - 1);
			}
		},
		next() {
			if (this.currentPage < this.totalPages - 1) {
				this.$emit('update:page', this.currentPage + 1);
			}
		},
		setCurrentPage(page) {
			this.$emit('update:page', page - 1);
		},
	},
};
