import config from '@/config';
import RnBrand from '../brand/brand.vue';
import RnField from '../field/field.vue';

export default {
	name: 'rn-footer',
	components: {
		RnBrand,
		RnField,
	},
	props: {
		isLoggedIn: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		currentLanguage: {
			get() {
				return this.$store.getters.currentLanguage;
			},
			set(newLanguage) {
				const { name, params } = this.$router.history.current;
				if (params.language !== newLanguage) {
					this.$router.push({ name, params: { ...params, language: newLanguage } });
				}
			},
		},
		isOnHelpdeskPage() {
			return this.$route.name === 'Helpdesk';
		},
		selectFieldProps() {
			return {
				inputProps: {
					id: 'footer-language-select',
					options: config.supportedLanguages,
					type: 'select',
				},
				inverted: true,
			};
		},
	},
};
