import { render, staticRenderFns } from "./order-checking.vue?vue&type=template&id=4c19be12"
import script from "./order-checking.js?vue&type=script&lang=js&external"
export * from "./order-checking.js?vue&type=script&lang=js&external"
import style0 from "./order-checking.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports