// Forms store module
// ---
//
// ------------------------------------------------------------------------- /

// Initial state
// ------------------------------------------------------------------------- /
import { resetState } from '../../utils';

const initialFormState = {
	errors: [],
};

const initialState = {
	login: { ...initialFormState },
	passwordReset: { ...initialFormState },
	passwordResetRequest: { ...initialFormState },
	contactInformation: { ...initialFormState },
	addressInformation: { ...initialFormState },
	password: { ...initialFormState },
	addBattery: { ...initialFormState },
	addPackaging: { ...initialFormState },
	addResource: { ...initialFormState },
	businessRelation: { ...initialFormState },
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	setFormErrors({ commit }, { form, errors }) {
		commit('setFormErrors', {
			form,
			errors,
		});
	},
	setInfoFormErrors({ commit }, { form, errors }) {
		commit('setInfoFormErrors', {
			form,
			errors,
		});
	},
	clearFormErrors({ commit }, { form }) {
		commit('clearFormErrors', { form });
	},
	clearInfoFormErrors({ commit }, { form }) {
		commit('clearInfoFormErrors', { form });
	},
	resetForms({ commit }) {
		commit('resetForms');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setFormErrors(state, { form, errors }) {
		if (state[form]) {
			state[form].errors = errors.map(err => err.code || err);
		}
	},
	setInfoFormErrors(state, { form, errors }) {
		if (state[form]) {
			state[form].errors = [errors.status];
		}
	},
	clearFormErrors(state, { form }) {
		if (state[form]) {
			state[form].errors = [];
		}
	},
	resetForms(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
