import get from 'lodash/get';

import store from '@/store';

export default function(to, from, next) {
	const token = get(to, 'query.token');
	const language = get(to, 'params.language');

	if (token) {
		store.dispatch('setResetPasswordToken', token);
		next();
	} else {
		next({ name: 'Login', params: { language } });
	}
}
