import RnIcon from '../icon/icon.vue';

export default {
	name: 'rn-dock',
	components: {
		RnIcon,
	},
	props: {
		toggleText: {
			type: String,
			default: '',
		},
		open: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			expanded: false,
		};
	},
	methods: {
		toggleExpand() {
			this.expanded = !this.expanded;
			this.$emit('update:expanded', this.expanded);
		},
	},
};
