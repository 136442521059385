/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'choose': {
    width: 16,
    height: 16,
    viewBox: '0 0 137.655 125',
    data: '<g _fill="#f86439"><path pid="0" d="M94.435 23.263h-48.7a5.725 5.725 0 0 0-5.719 5.719v65.105a5.725 5.725 0 0 0 5.719 5.719h48.7a5.725 5.725 0 0 0 5.719-5.719V28.981a5.725 5.725 0 0 0-5.719-5.718zm2.896 70.824a2.9 2.9 0 0 1-2.896 2.896h-48.7a2.9 2.9 0 0 1-2.897-2.896V28.981a2.9 2.9 0 0 1 2.897-2.896h48.7a2.9 2.9 0 0 1 2.896 2.896v65.106z"/><path pid="1" d="M56.113 30.731a7.803 7.803 0 1 0 0 15.606 7.803 7.803 0 0 0 0-15.606zm0 14.106c-3.475 0-6.303-2.827-6.303-6.303s2.827-6.303 6.303-6.303 6.303 2.827 6.303 6.303-2.828 6.303-6.303 6.303zM91.11 35.355l-21.62.022a.75.75 0 0 0 .001 1.5h.001l21.619-.022a.75.75 0 0 0-.001-1.5zM91.11 40.19l-21.62.022a.75.75 0 0 0 .001 1.5h.001l21.619-.022a.75.75 0 0 0-.001-1.5zM56.113 78.731a7.803 7.803 0 1 0 0 15.606 7.803 7.803 0 0 0 0-15.606zm0 14.106c-3.475 0-6.303-2.827-6.303-6.303s2.827-6.303 6.303-6.303 6.303 2.827 6.303 6.303-2.828 6.303-6.303 6.303zM91.11 83.355l-21.62.022a.75.75 0 0 0 .001 1.5h.001l21.619-.022a.75.75 0 0 0-.001-1.5zM91.11 88.19l-21.62.022a.75.75 0 0 0 .001 1.5h.001l21.619-.022a.75.75 0 0 0-.001-1.5zM56.113 53.731a7.803 7.803 0 1 0 0 15.606 7.803 7.803 0 0 0 0-15.606zm0 14.106c-3.475 0-6.303-2.827-6.303-6.303s2.827-6.303 6.303-6.303 6.303 2.827 6.303 6.303-2.828 6.303-6.303 6.303zM91.11 58.355l-21.62.022a.75.75 0 0 0 .001 1.5h.001l21.619-.022a.75.75 0 0 0-.001-1.5zM91.11 63.19l-21.62.022a.75.75 0 0 0 .001 1.5h.001l21.619-.022a.75.75 0 0 0-.001-1.5z"/><circle pid="2" cx="56.113" cy="61.534" r="4.704"/></g>'
  }
})
