const IconDirections = Object.freeze({
	Up: 'up',
	Right: 'right',
	Down: 'down',
	Left: 'left',
});

const IconClasses = Object.freeze({
	Base: 'a-icon',
	Original: 'a-icon--original',
	HasLabel: 'a-icon--has-label',
	// Color
	Blue: 'a-icon--blue',
	Green: 'a-icon--green',
	Red: 'a-icon--red',
	Grey: 'a-icon--grey',
	White: 'a-icon--white',
});

export { IconDirections, IconClasses };
