var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-cp-new-transport"},[_c('rn-header',{attrs:{"title":_vm.$t('cp.createNewTransport'),"size":"small"}},[_c('router-link',{staticClass:"a-link a-link--back",attrs:{"slot":"action","to":{ name: 'Dashboard' }},slot:"action"},[_c('rn-icon',{attrs:{"name":"chevron","direction":"left","width":"16px","height":"16px"}}),_c('span',[_vm._v(_vm._s(_vm.$t('common.cancelAndReturn')))])],1)],1),_c('div',{staticClass:"o-section o-section--grey o-section--step-form"},[_c('div',{staticClass:"o-section__content"},[_c('rn-step-form',{ref:"stepForm",attrs:{"steps":_vm.steps,"current-step":_vm.state.currentStep,"cancelMethod":_vm.goBack},on:{"update:currentStep":function($event){return _vm.$set(_vm.state, "currentStep", $event)},"update:current-step":function($event){return _vm.$set(_vm.state, "currentStep", $event)}}},[_c('template',{slot:"step-1"},[_c('div',{staticClass:"m-button-group m-button-group--left"},[_c('rn-field',{attrs:{"disabled":_vm.state.selectedBatteries.length > 0,"input-props":{
								id: 'new-transport-loc',
								options: _vm.selectableLocations,
								type: 'select',
								placeholder: _vm.$t('cp.newTransport.allLocations'),
							},"value":_vm.state.location},on:{"update:value":function($event){return _vm.$set(_vm.state, "location", $event)}}}),_c('rn-field',{attrs:{"disabled":_vm.state.selectedBatteries.length > 0,"input-props":{
								id: 'new-transport-brands',
								options: _vm.selectableBrands,
								type: 'select',
								placeholder: _vm.$t('cp.newTransport.allBrands'),
							},"value":_vm.state.brand},on:{"update:value":function($event){return _vm.$set(_vm.state, "brand", $event)}}})],1),(_vm.batteriesLoadingState === 'loading')?_c('rn-spinner'):_vm._e(),(_vm.batteriesLoadingState === 'noResults')?_c('div',{staticClass:"a-no-results"},[_vm._v(" "+_vm._s(_vm.$t('common.noResults'))+" ")]):_vm._e(),(_vm.batteriesLoadingState === 'results')?_c('rn-new-table',{attrs:{"headers":_vm.selectBatteryTableHeaders,"data":_vm.batteriesToSelect,"clickable":""},on:{"row:click":function($event){return _vm.handleRowPress($event)}}}):_vm._e(),_c('rn-pagination',{staticClass:"o-table-wrapper__pagination",attrs:{"current-page":_vm.page,"items-per-page":_vm.ITEMS_PER_PAGE,"total-items":_vm.checkedBatteriesCount},on:{"update:page":_vm.updateBatteries}})],1),_c('rn-card-list',{attrs:{"slot":"step-2","card-data":_vm.state.editableSelectedTransportPackagingCardList},slot:"step-2"},[_c('rn-button',{attrs:{"slot":"card-list-action","icon":{ name: 'packaging', width: '65px', height: '65px', original: true },"method":function () { return _vm.toggleSidebar(true); },"shrink":!!_vm.state.selectedPackaging.length,"text":_vm.$t('stepForm.addPackaging'),"size":"x-large"},slot:"card-list-action"})],1),_c('div',{staticClass:"o-overview u-grid",attrs:{"slot":"step-3"},slot:"step-3"},[_c('div',{staticClass:"o-overview__section u-grid__12-12"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('common.generalInformation')))]),_c('div',{staticClass:"u-grid"},[_c('div',{staticClass:"u-grid__4-12"},[_c('rn-field',{attrs:{"input-props":{
										id: 'new-transport-from-location',
										type: 'text',
									},"value":_vm.fromLocation,"label":_vm.$t('common.location'),"readonly":""}})],1),_c('div',{staticClass:"u-grid__4-12"},[_c('rn-field',{attrs:{"input-props":{
										id: 'new-transport-dest-loc-id',
										options: _vm.destinationLocations,
										placeholder: _vm.$t('cp.newCollection.destination.placeholder'),
										type: 'select',
									},"required":true,"label":_vm.$t('cp.newCollection.destination.placeholder'),"value":_vm.state.destinationLocationId},on:{"update:value":function($event){return _vm.$set(_vm.state, "destinationLocationId", $event)}}})],1),_c('div',{staticClass:"u-grid__4-12"},[_c('rn-field',{attrs:{"input-props":{
										id: 'new-transport-date',
										type: 'datetime-local',
										format: 'date',
										setInitialValue: false,
									},"label":_vm.$t('cp.newCollection.date')},on:{"update:value":function (value) { return _vm.setFormValue('date', value); }}})],1)])]),_c('div',{staticClass:"o-overview__section u-grid__12-12"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('common.packagingMethod')))]),_c('rn-card-list',{attrs:{"card-data":_vm.state.selectedTransportPackagingCardList}})],1),_c('div',{staticClass:"o-overview__section u-grid__12-12"},[_c('h4',{staticClass:"a-title"},[_vm._v(" "+_vm._s(((_vm.$t('stepForm.overview.batteries')) + " (" + (_vm.state.selectedBatteries.length) + " " + (_vm.state.selectedBatteries.length === 1 ? _vm.$t('common.battery') : _vm.$t('common.batteries')) + ")"))+" ")]),(_vm.state.selectedBatteries.length)?_c('rn-new-table',{attrs:{"headers":_vm.overviewBatteryTableHeaders,"data":_vm.state.selectedBatteries}}):_vm._e(),(_vm.state.selectedBatteries.length)?_c('rn-new-table',{attrs:{"headers":_vm.summaryTableHeaders,"data":_vm.summary}}):_vm._e()],1),_c('div',{staticClass:"o-overview__section u-grid__12-12"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('stepForm.overview.comments')))]),_c('rn-field',{attrs:{"input-props":{
								id: 'new-transport-remarks',
								placeholder: _vm.$t('stepForm.overview.comments.placeholder'),
								type: 'textarea',
							},"value":_vm.state.comments},on:{"update:value":function($event){return _vm.$set(_vm.state, "comments", $event)}}})],1)])],2),(_vm.dockOpen)?_c('rn-dock',{attrs:{"open":_vm.dock.open,"toggle-text":_vm.toggleDockLabel},on:{"update:expanded":_vm.updateDock}},[_c('h3',{staticClass:"a-title u-text-blue",attrs:{"slot":"dock-header"},slot:"dock-header"},[_vm._v(_vm._s(_vm.$t('cp.newTransport.overviewBatteries')))]),_c('rn-new-table',{attrs:{"slot":"dock-content","headers":_vm.removeBatteryTableHeaders,"data":_vm.dock.tableData},slot:"dock-content"}),_c('div',{staticClass:"m-button-group",attrs:{"slot":"dock-action"},slot:"dock-action"},[_c('rn-button',_vm._b({},'rn-button',_vm.dock.button,false))],1)],1):_vm._e(),(_vm.state.isEditing)?_c('rn-edit-battery-sidebar',{attrs:{"slot":"content","is-editing":_vm.state.isEditing,"battery":_vm.state.toEditBattery,"save-battery":_vm.saveBattery,"open":_vm.editSidebarOpen},on:{"update:open":[function($event){_vm.editSidebarOpen=$event},_vm.onSidebarOpenOrClosed]},slot:"content"}):_vm._e(),_c('rn-sidebar',{attrs:{"open":_vm.sidebarOpen},on:{"update:open":[function($event){_vm.sidebarOpen=$event},_vm.onSidebarOpenOrClosed]}},[(!_vm.state.isEditing)?_c('h2',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('addPackaging.title'))+" ")]):_vm._e(),(!_vm.state.isEditing && _vm.selectedBrandId)?_c('rn-form',{staticClass:"o-sidebar__form",attrs:{"slot":"content","model":_vm.transportPackagingFormModel,"field-groups":_vm.transportPackagingFieldGroups,"field-errors":_vm.transportPackagingFormFieldErrors},on:{"update:model":_vm.updateTransportPackagingFormModel},slot:"content"},[_c('rn-button',{attrs:{"slot":"main","icon":{ name: 'plus', pos: 'right', direction: 'right', width: '16px', height: '16px' },"method":_vm.addTransportPackaging,"text":_vm.$t('common.add')},slot:"main"})],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }