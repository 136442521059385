/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'barrel-grey': {
    width: 16,
    height: 16,
    viewBox: '0 0 42.93 79.93',
    data: '<path pid="0" _fill="#cacccc" d="M4 11.44V7.7a1.88 1.88 0 0 1 .52-.34A3.5 3.5 0 0 1 5.22 6c1.37-1.37 8.58-2.32 16.3-2.32S36.46 4.59 37.83 6a3.5 3.5 0 0 1 .66 1.4 1.77 1.77 0 0 1 .51.3v3.74a.68.68 0 0 1 0 1.11 2.22 2.22 0 0 1-.5.36c-2.05 1.17-8 2.14-17 2.14s-14.59-1-17-2a4.45 4.45 0 0 1-.92-.49A4.9 4.9 0 0 1 4 11.44zm34.48 59.08c.13 2.91-5.84 5.77-17 5.77a51.51 51.51 0 0 1-6.84-.42h-.22l-.56-.09h-.25c-.58-.09-1.13-.19-1.65-.31h-.17l-.81-.2-.83-.23-.42-.12-.38-.13-.56-.2a12.52 12.52 0 0 1-1.46-.64l-.29-.15-.26-.15a6.46 6.46 0 0 1-.9-.63 3 3 0 0 1-1.3-2.36V14.71c3.33 1.18 9.57 1.9 17 1.9a78.37 78.37 0 0 0 13-.9 19.31 19.31 0 0 0 4-1.06v55.87zm4.17-60V7.7a3.63 3.63 0 0 0-1.11-2.62 6 6 0 0 0-1.13-1.7c-.93-.93-2.58-2-7.84-2.7a83.37 83.37 0 0 0-11-.68 83.32 83.32 0 0 0-11 .68c-5.26.73-6.91 1.77-7.84 2.7a6 6 0 0 0-1.13 1.7A3.63 3.63 0 0 0 .4 7.7v3a5.36 5.36 0 0 0-.4 1.48A3.64 3.64 0 0 0 .92 15v55.52a2.17 2.17 0 0 0 0 .26A6.27 6.27 0 0 0 2.8 75c3.05 3.18 9.7 4.93 18.72 4.93S37.2 78.18 40.25 75a6.27 6.27 0 0 0 1.87-4.28 1.27 1.27 0 0 0 0-.2v-56a4.25 4.25 0 0 0 .77-2 4.33 4.33 0 0 0-.24-2.04zm-7.83 35.1a1.61 1.61 0 0 1-1.26 1.52 67.65 67.65 0 0 1-25.33-.25V37.2a1 1 0 0 1 1.25-1 67.7 67.7 0 0 0 25.34-.26zm0-11.22a67.49 67.49 0 0 1-25.34.26 2.26 2.26 0 0 0-2.8 2.23v9.61a2 2 0 0 0 1.55 1.91 67.44 67.44 0 0 0 25.33.26 3.67 3.67 0 0 0 2.81-3.44v-9.65a1.21 1.21 0 0 0-1.55-1.22zM7.56 7.56c2.27-.84 7.94-1.4 14-1.4s11.7.56 14 1.4c1-.37 1.31-.77.84-1.19-1.25-1.08-7.8-1.84-14.81-1.84S8 5.29 6.72 6.37c-.47.42-.14.82.84 1.19"/>'
  }
})
