import get from 'lodash/get';

import store from '@/store';

export default async (to, _, next) => {
	const user = store.getters.user;
	const language = get(to, 'params.language');

	if (user) {
		// User is authenticated, we can continue to the requested route;
		return next();
	}

	/**
	 * If there is no user found, we try authenticating with checkLogin. If a user
	 * is returned we can safely proceed, otherwise the session has expired and we
	 * redirect to home.
	 */
	await Promise.all([store.dispatch('checkLogin'), store.dispatch('getEnvironmentVariables')]);

	if (store.getters.user) {
		return next(to);
	}

	// User needs to login first
	next({
		name: 'Login',
		params: { language },
	});
};
