import { RnActionHeader, RnForm, RnButton, RnIcon } from '@/components';

export default {
	name: 'rn-helpdesk-view',
	components: {
		RnActionHeader,
		RnForm,
		RnButton,
		RnIcon,
	},

	mounted() {
		window.scrollTo(0, 0);
	},
};
