/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'arrow': {
    width: 16,
    height: 16,
    viewBox: '-469.1 273 16 16',
    data: '<path pid="0" d="M-469.1 281l1.5 1.4 5.5-5.6V289h2v-12.2l5.6 5.6 1.4-1.4-8-8z"/>'
  }
})
