import get from 'lodash/get';

import apiService from '@/services/api';
import * as validators from '@/utils/validators';
import { MessageTypes } from '@/enums/message-types';

const passwordResetMixin = {
	data() {
		return {
			passwordResetFormModel: {
				email: null,
			},
			passwordResetFormFieldErrors: {
				email: '',
			},
		};
	},
	computed: {
		passwordResetFieldGroups() {
			return [
				{
					fields: [
						{
							inputProps: {
								id: 'login-email',
								placeholder: this.$t('login.email.placeholder'),
								type: 'email',
							},
							label: this.$t('login.email'),
							modelKey: 'email',
							required: true,
							onValueChanged: () => this.validate('email'),
						},
					],
				},
			];
		},
		passwordResetFormErrors() {
			return this.$store.state.forms.passwordResetRequest.errors;
		},
	},
	methods: {
		validate(prop) {
			// If the input field has an error, and the value changes, re-validate the form
			if (this.passwordResetFormFieldErrors[prop]) {
				// Validate the form after the values are set
				setTimeout(() => this.validatePasswordResetFormModel(this.passwordResetFormModel));
			}
		},
		passwordResetFormAction() {
			if (this.validatePasswordResetFormModel(this.passwordResetFormModel)) {
				apiService
					.post('/users/reset-password', { email: this.passwordResetFormModel.email })
					.then(({ data: response }) => {
						if (response.success) {
							this.$store.dispatch('setMessage', { text: 'message.passwordResetRequest.success', type: MessageTypes.SUCCESS });
						} else if (response.errors) {
							this.$store.dispatch('setFormErrors', { form: 'passwordReset', errors: get(response, 'errors', ['default']) });
						}
					})
					.catch(error => {
						this.$store.dispatch('setFormErrors', { form: 'passwordReset', errors: get(error, 'response.data.errors', ['default']) });
					});
			}
		},
		clearPasswordResetFormFieldErrors() {
			this.passwordResetFormFieldErrors = {
				email: '',
			};
		},
		clearPasswordResetFormError() {
			this.$store.dispatch('clearFormErrors', { form: 'passwordReset' });
		},
		validatePasswordResetFormModel(model) {
			this.clearPasswordResetFormError();
			this.clearPasswordResetFormFieldErrors();

			let valid = true;

			if (!model.email) {
				this.passwordResetFormFieldErrors.email = this.$t('error.required', [this.$t('login.email')]);
				valid = false;
			} else if (!validators.email(model.email)) {
				this.passwordResetFormFieldErrors.email = this.$t('error.invalid', [this.$t('login.email')]);
				valid = false;
			}

			return valid;
		},
	},
};

export default passwordResetMixin;
