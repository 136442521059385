import get from 'lodash/get';
import set from 'lodash/set';
import flatten from 'lodash/flatten';
import { getInvoices } from '@/services/invoices';

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	opsInvoices: [],
	opsInvoicesCount: 0,
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	opsInvoices: state => state.opsInvoices,
	opsInvoicesCount: state => state.opsInvoicesCount || 0,
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	getOpsInvoices({ commit }, { businessRelationId, page, pageSize, date, search }) {
		getInvoices(commit, businessRelationId, page, pageSize, date, search).then(response => {
			commit('setOpsInvoices', {
				items: flatten(get(response, 'data.items', [])),
				storePath: 'opsInvoices',
			});
			commit('setOpsInvoicesCount', {
				count: get(response, 'data.totalItems', 0),
				storePath: 'opsInvoicesCount',
			});
		});
	},
	resetOpsInvoices({ commit }) {
		commit('resetOpsInvoices');
	},
};

const mutations = {
	setOpsInvoices(state, { items, storePath }) {
		set(state, storePath, items);
	},
	setOpsInvoicesCount(state, { count, storePath }) {
		set(state, storePath, count);
	},
	resetOpsInvoices(state) {
		state.opsInvoices = initialState.opsInvoices;
		state.opsInvoicesCount = initialState.opsInvoicesCount;
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
