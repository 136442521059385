const FieldTypes = Object.freeze({
	// <input>
	Text: 'text',
	Email: 'email',
	Password: 'password',
	Number: 'number',
	Checkbox: 'checkbox',
	Radio: 'radio',
	Date: 'date',
	DateTime: 'datetime-local',
	File: 'file',
	Amount: 'amount',
	// <textarea>
	TextArea: 'textarea',
	// <select>
	Select: 'select',
	MultiSelect: 'multi-select',
	BatteryCondition: 'battery-condition',
});

const FieldClasses = Object.freeze({
	Base: 'a-field',
	// Icons
	IconLeft: 'a-field--icon-left',
	IconRight: 'a-field--icon-right',
	// Color schemes
	Inverted: 'a-field--inverted',
	// States
	Disabled: 'a-field--disabled',
	HasError: 'a-field--has-error',
	// Exceptions
	ColumnLayout: 'a-field--column',
});

const ToggleTypes = Object.freeze({
	Checkbox: 'checkbox',
	Radio: 'radio',
	RadioIcon: 'radio-icon',
});

const ToggleClasses = Object.freeze({
	Base: 'a-field__toggle',
	Checkbox: 'a-field__toggle--checkbox',
	Radio: 'a-field__toggle--radio',
	RadioIcon: 'a-field__toggle--radio-icon',
});

const ToggleTypeClasses = Object.freeze({
	Checkbox: 'a-field__toggle__checkbox',
	Radio: 'a-field__toggle__radio',
	RadioIcon: 'a-field__toggle__radio-icon',
});

export { FieldTypes, FieldClasses, ToggleTypes, ToggleClasses, ToggleTypeClasses };
