import { mapGetters } from 'vuex';
import get from 'lodash/get';

import RnField from '../field/field.vue';
import RnIcon from '../icon/icon.vue';
import { mapNamesToLabels, mapOptionNamesToLabels } from './filters.utils';
import collectionOrdersMetadata from '@/assets/api/collection-orders-filters-metadata.json';
import pupsOrdersMetadata from '@/assets/api/pups-filters-metadata.json';

export default {
	name: 'rn-filters',
	components: {
		RnField,
		RnIcon,
	},
	props: {
		resource: {
			type: String,
			default: '',
			required: false,
		},
	},
	computed: {
		...mapGetters(['defaultRole']),
		metadata() {
			switch (this.resource) {
				case 'pickupPoints':
					return pupsOrdersMetadata;

				default:
					return collectionOrdersMetadata;
			}
		},
		tableHeaders() {
			return get(this.metadata, 'tableHeaders', []);
		},
	},
	methods: {
		updateFilter(name, values) {
			const searchParams = new URLSearchParams(window.location.search);

			searchParams.set(name, values.join(','));
			const newUrl = window.location.origin + window.location.pathname + '?' + searchParams.toString();
			window.history.pushState({ path: newUrl }, '', newUrl);

			this.$emit('update:filter');
		},
		getTranslation(name) {
			return mapNamesToLabels(name, this.$t);
		},
		getHeaderOptions(options, filterName) {
			return options.map(option => ({
				value: option,
				name: mapOptionNamesToLabels(option, this.$t, filterName),
			}));
		},
		getFieldType(header) {
			if (header.options && !!header.options.length) {
				return 'multi-select';
			}

			if (header.properties[0].systemType === 'DateTime') {
				return 'datetime';
			}

			return 'combobox';
		},
	},
};
