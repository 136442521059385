import get from 'lodash/get';

import RnIcon from '@/components/icon/icon.vue';
import { fieldMixin } from '@/mixins';

export default {
	name: 'cs-field-select',
	components: { RnIcon },
	mixins: [fieldMixin],
	methods: {
		// helper to determine if the passed value is one
		// of the possible options in a select field
		getSelectValue(options, targetValue) {
			if (options && options.length) {
				return get(
					options.find(option => option.value === targetValue),
					'value'
				);
			}
		},
	},
};
