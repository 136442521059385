// Navigation store module
// ---
//
// ------------------------------------------------------------------------- /

// Initial state
// ------------------------------------------------------------------------- /
import { resetState } from '../../utils';

const initialState = {
	modeCpOperationsOrSpoc: 'operations',
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	modeCpOperationsOrSpoc: state => state.modeCpOperationsOrSpoc || 'operations',
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	setModeCpOperationsOrSpoc({ commit }, modeCpOperationsOrSpoc) {
		commit('setModeCpOperationsOrSpoc', modeCpOperationsOrSpoc);
	},
	resetNavigation({ commit }) {
		commit('resetNavigation');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setModeCpOperationsOrSpoc(state, modeCpOperationsOrSpoc) {
		state.modeCpOperationsOrSpoc = modeCpOperationsOrSpoc;
	},
	resetNavigation(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
