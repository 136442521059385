import datetime from 'vuejs-datetimepicker';
import { fieldMixin } from '@/mixins';
import moment from 'moment';

export default {
	name: 'cs-field-datetime',
	components: { datetime },
	mixins: [fieldMixin],
	props: {
		icon: {
			type: Object,
			default: () => ({
				name: '', // icon name
				direction: '', // icon direction
				pos: '', // left or right
				original: false,
				width: '16px',
				height: '16px',
			}),
		},
		format: {
			type: String,
			default: 'dateTime',
		},
		value: {
			type: [String, Array],
			default: undefined,
		},
	},
	data() {
		return {
			range: {
				from: '',
				to: '',
			},
		};
	},
	computed: {
		val: {
			get() {
				if (this.value) {
					const date = moment.utc(this.value);
					if (this.format === 'dateTime') {
						return date.format('DD-MM-YYYY HH:mm');
					}
					return date.format('DD-MM-YYYY');
				}

				return '';
			},
			set(val) {
				let newDate;
				if (!val) {
					newDate = undefined;
				} else {
					const date = moment.utc(val, this.format === 'dateTime' ? 'DD-MM-YYYY HH:mm:ss' : 'DD-MM-YYYY');
					if (this.format === 'dateTime') {
						newDate = date.toISOString();
					} else {
						// By setting 8 o clock we avoid issues with gmt + 1 => day - 1 23:00:00
						newDate = `${date.year()}-${date.month() + 1}-${date.date()}T08:00:00.000Z`;
					}
				}
				this.updateValue({ target: { value: newDate } });
			},
		},
		from: {
			get() {
				const [from, _to] = this.value.split('&');
				if (from) {
					const date = moment.utc(from);

					return date.format('DD-MM-YYYY');
				}

				return '';
			},
			set(val) {
				let newDate;
				if (!val) {
					newDate = '';
				} else {
					const date = moment.utc(val, 'DD-MM-YYYY');
					// By setting 8 o clock we avoid issues with gmt + 1 => day - 1 23:00:00
					newDate = `${date.year()}-${date.month() + 1}-${date.date()}`;
				}

				this.range = {
					...this.range,
					from: newDate,
				};
				this.updateValue({ target: { value: [`${newDate}&${this.range.to}`] } });
			},
		},
		to: {
			get() {
				const [_from, to] = this.value.split('&');
				if (to) {
					const date = moment.utc(to);

					return date.format('DD-MM-YYYY');
				}

				return '';
			},
			set(val) {
				let newDate;
				if (!val) {
					newDate = '';
				} else {
					const date = moment.utc(val, 'DD-MM-YYYY');
					// By setting 8 o clock we avoid issues with gmt + 1 => day - 1 23:00:00
					newDate = `${date.year()}-${date.month() + 1}-${date.date()}`;
				}

				this.range = {
					...this.range,
					to: newDate,
				};
				this.updateValue({ target: { value: [`${this.range.from}&${newDate}`] } });
			},
		},
	},
	methods: {
		getFormat() {
			if (this.format === 'dateTime') {
				return 'DD-MM-YYYY H:i:s';
			}

			return 'DD-MM-YYYY';
		},
	},
};
