export default {
	supportedLanguages: [
		{ value: 'en', name: 'English' },
		{ value: 'nl', name: 'Nederlands' },
		{ value: 'fr', name: 'Français' },
		{ value: 'de', name: 'Deutsch' },
		{ value: 'it', name: 'Italiano' },
		{ value: 'es', name: 'Español' },
		{ value: 'cs', name: 'Česky' },
		{ value: 'el', name: 'Ελληνική' },
		{ value: 'pl', name: 'Polska' },
		{ value: 'pt', name: 'Português' },
		{ value: 'ro', name: 'Românesc' },
	],
	defaultLanguage: 'en',
};
