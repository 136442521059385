// contracts store module
// ---
//
// ------------------------------------------------------------------------- /
import apiService from '@/services/api';
import get from 'lodash/get';
import set from 'lodash/set';
import { logAndToastError, resetState } from '@/utils';

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	contracts: {},
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	getContracts: state => businessRelationId => get(state, `contracts[${businessRelationId}]`, null),
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	async getContracts({ commit, state }, { businessRelationId }) {
		let url;
		try {
			if (!businessRelationId) {
				logAndToastError(commit, 'Failed to get contracts', null, { businessRelationId });

				return;
			}
			const contracts = get(state, `contracts[${businessRelationId}]`);
			if (contracts && contracts.length !== 0) {
				return contracts;
			}

			url = `/businessrelations/${businessRelationId}/contracts`;
			const response = await apiService.get(url);
			commit('setContracts', {
				businessRelationId,
				contracts: response.data,
			});
		} catch (err) {
			logAndToastError(commit, 'Failed to get contracts from the server', err, {
				url,
				businessRelationId,
			});
		}
	},
	resetContracts({ commit }) {
		commit('resetContracts');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setContracts(state, { businessRelationId, contracts }) {
		set(state, `contracts[${businessRelationId}]`, contracts);
	},
	resetContracts(state) {
		resetState(state, initialState);
	},
};

// Helpers
// ------------------------------------------------------------------------- /

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
