import RnIcon from '@/components/icon/icon.vue';
import RnButton from '@/components/button/button.vue';
import { fieldMixin } from '@/mixins';

export default {
	name: 'cs-field-file',
	components: { RnIcon, RnButton },
	mixins: [fieldMixin],
	props: {
		icon: {
			type: Object,
			default: () => ({
				name: '', // icon name
				direction: '', // icon direction
				pos: '', // left or right
				original: false,
				width: '16px',
				height: '16px',
			}),
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		renderFileName: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			files: null,
		};
	},
	computed: {
		fileNames() {
			if (this.files && this.files.length) {
				return [].map.call(this.files, file => this.truncateFilename(file.name));
			}
		},
	},
	methods: {
		truncateFilename(name) {
			return name.length > 20 ? `${name.substring(0, 13)}...${name.substring(name.length - 8)}` : name;
		},
		updateFiles(e) {
			this.files = e.target.files;
			this.$emit('update:value', e.target.files);
		},
	},
};
