/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'collect': {
    width: 16,
    height: 16,
    viewBox: '0 0 182 114.68',
    data: '<defs/><g id="Layer_2" data-name="Layer 2"><g id="collect"><path pid="0" class="cls-1" d="M38.86 0v23.54a1 1 0 0 0 2 0V2h28.83v19H88V2h28.8v73.23a1 1 0 0 0 2 0V0zM86 19H71.69V2H86z"/><path pid="1" class="cls-2" d="M131 99.43H79.55a1 1 0 0 0 0 2H131a1 1 0 0 0 0-2z"/><path pid="2" class="cls-2" d="M178.17 65.9l-9.69-3.39c-.78-2.82-4.75-16-12.88-25.39a1 1 0 0 0-.76-.34H130.9a1 1 0 0 0-1 1v37.46H31.06a1 1 0 0 0-1 1v24.19a1 1 0 0 0 1 1h13.78v.51a12.74 12.74 0 1 0 .25-2.51h-13V77.24h97.81v12.63a1 1 0 0 0 2 0V38.78h22.48c8.43 9.95 12.26 24.59 12.3 24.74a1 1 0 0 0 .64.7l10.19 3.56c.1 0 2.49 1 2.49 4.5v23.28a4.28 4.28 0 0 1-1 3.25 2.57 2.57 0 0 1-1.77.63H165.8a12.74 12.74 0 1 0 .25 2.51v-.51h11.23a4.55 4.55 0 0 0 3-1.13 6.09 6.09 0 0 0 1.72-4.75V72.28c0-5.01-3.67-6.28-3.83-6.38zM57.56 91.2a10.74 10.74 0 1 1-10.74 10.74A10.75 10.75 0 0 1 57.56 91.2zm95.75 21.48a10.74 10.74 0 1 1 10.74-10.74 10.75 10.75 0 0 1-10.74 10.74z"/><path pid="3" class="cls-2" d="M143.06 65.07a1 1 0 0 0 1 1H154a1 1 0 0 0 1-1.18c-1.08-6-6.23-15.57-6.45-16a1 1 0 1 0-1.76 1c0 .09 4.55 8.49 6 14.21h-8.69a1 1 0 0 0-1.04.97zM24.26 62.94H5.64a1 1 0 0 0 0 2h18.62a1 1 0 1 0 0-2zM28.71 15.27a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h26.71a1 1 0 0 0 1-1zM15.47 33.49a1 1 0 0 0 1 1h24.19a1 1 0 0 0 0-2H16.47a1 1 0 0 0-1 1zM18.93 51.7a1 1 0 0 0 1 1h33a1 1 0 0 0 0-2h-33a1 1 0 0 0-1 1z"/></g></g>'
  }
})
