import RnIcon from '../icon/icon.vue';
import { BrandClass } from './brand.enums';

export default {
	name: 'rn-brand',
	components: {
		RnIcon,
	},
	props: {
		caption: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: '',
		},
	},
	computed: {
		brandClasses() {
			return {
				[BrandClass.Base]: true,
				[BrandClass.White]: this.color === 'white',
			};
		},
	},
};
