/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'package': {
    width: 16,
    height: 16,
    viewBox: '0 0 151.26 156.92',
    data: '<defs/><g id="Layer_2" data-name="Layer 2"><path pid="0" class="cls-1" d="M81.17 153.93V65.46l35-18.26v24.57a1 1 0 0 0 2 0V46.15l31.09-16.22V113zm70.09-39.85v-86.5L76.76.13 76.39 0 0 30.72v83l80.08 43.17zM76.43 2.14l71.37 26.3L117 44.51l-71.31-30zm3.77 61.57L3.55 31.45l39.5-15.89 71.63 30.16zM2 33l77.17 32.45v88.71L2 112.55z" id="package"/></g>'
  }
})
