import get from 'lodash/get';

import apiService from '@/services/api';
import { MessageTypes } from '@/enums/message-types';

const passwordResetMixin = {
	data() {
		return {
			passwordResetFormModel: {
				newPassword: null,
				newPasswordRepeat: null,
			},
			passwordResetFormFieldErrors: {
				newPassword: '',
				newPasswordRepeat: '',
				notMatching: '',
			},
		};
	},
	computed: {
		passwordResetFieldGroups() {
			return [
				{
					fields: [
						{
							inputProps: {
								id: 'new-password',
								placeholder: this.$t('password-reset.new-password.placeholder'),
								type: 'password',
							},
							label: this.$t('password-reset.new-password'),
							modelKey: 'newPassword',
							required: true,
							onValueChanged: () => this.validate('newPassword'),
						},
						{
							inputProps: {
								id: 'new-password-repeat',
								placeholder: this.$t('password-reset.new-password-repeat.placeholder'),
								type: 'password',
							},
							label: this.$t('password-reset.new-password-repeat'),
							modelKey: 'newPasswordRepeat',
							required: true,
							onValueChanged: () => this.validate('newPasswordRepeat'),
						},
					],
				},
			];
		},
		passwordResetFormErrors() {
			return this.$store.state.forms.passwordReset.errors;
		},
	},
	methods: {
		validate(prop) {
			// If the input field has an error, and the value changes, re-validate the form
			if (this.passwordResetFormFieldErrors[prop]) {
				// Validate the form after the values are set
				setTimeout(() => this.validatePasswordResetFormModel(this.passwordResetFormModel));
			}
		},
		passwordResetFormAction() {
			if (this.validatePasswordResetFormModel(this.passwordResetFormModel)) {
				const token = this.$store.getters.resetPasswordToken;

				apiService
					.put(`/users/${token}/newpassword`, { newPassword: this.passwordResetFormModel.newPassword })
					.then(({ data: response }) => {
						if (response.success) {
							this.$store.dispatch('setMessage', { text: 'message.passwordReset.success', type: MessageTypes.SUCCESS });
							this.$router.push({ name: 'Login', params: this.$router.history.current.params });
						} else if (response.errors) {
							this.$store.dispatch('setFormErrors', { form: 'passwordReset', errors: get(response, 'errors', ['default']) });
						}
					})
					.catch(error => {
						this.$store.dispatch('setFormErrors', { form: 'passwordReset', errors: get(error, 'response.data.errors', ['default']) });
					});
			}
		},
		clearPasswordResetFormFieldErrors() {
			this.passwordResetFormFieldErrors = {
				newPassword: '',
				newPasswordRepeat: '',
				notMatching: '',
			};
		},
		clearPasswordResetFormError() {
			this.$store.dispatch('clearFormErrors', { form: 'passwordReset' });
		},
		validatePasswordResetFormModel(model) {
			this.clearPasswordResetFormError();
			this.clearPasswordResetFormFieldErrors();

			let valid = true;

			if (!model.newPassword) {
				this.passwordResetFormFieldErrors.newPassword = this.$t('error.required', [this.$t('password-reset.new-password')]);
				valid = false;
			}

			if (!model.newPasswordRepeat) {
				this.passwordResetFormFieldErrors.newPasswordRepeat = this.$t('error.required', [this.$t('password-reset.new-password-repeat')]);
				valid = false;
			}

			return valid;
		},
	},
};

export default passwordResetMixin;
