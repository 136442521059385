import get from 'lodash/get';

import RnField from '../field/field.vue';

export default {
	name: 'rn-form',
	components: {
		RnField,
	},
	props: {
		model: {
			type: Object,
			default: () => ({}),
		},
		fieldGroups: {
			type: Array,
			default: () => [],
		},
		fieldErrors: {
			type: Object,
			default: () => ({}),
		},
		formErrors: {
			type: Array,
			default: () => [],
		},
		id: {
			type: Number,
			default: 0,
		},
	},
	data: () => ({
		internalModel: get(this, 'model', {}),
	}),
	watch: {
		internalModel: {
			handler(newModel, oldModel) {
				if (JSON.stringify(newModel) && JSON.stringify(oldModel)) {
					this.$emit('watch:model', newModel, oldModel);
				}
			},
			deep: true,
		},
	},
	computed: {
		formModel: {
			get() {
				return get(this, 'internalModel');
			},
			set(newValue) {
				this.internalModel = {
					...this.internalModel,
					...newValue,
					id: this.id,
				};

				this.$emit('update:model', this.internalModel);
			},
		},

		hasActionSlot() {
			return !!this.$slots.secondary || !!this.$slots.main;
		},
	},
	methods: {
		updateModel(key, value, onValueChanged) {
			if (onValueChanged) {
				onValueChanged(value);
			}

			this.formModel = {
				[key]: value,
			};
		},
	},
};
