import get from 'lodash/get';
import Vue from 'vue';

import RnButton from '../button/button.vue';
import RnIcon from '../icon/icon.vue';
import RnAlert from '../alert/alert.vue';

import { FieldTypes, FieldClasses } from './field.enums';
import * as fields from './fields';

export default Vue.extend({
	name: 'rn-field',
	components: {
		RnButton,
		RnIcon,
		RnAlert,
		...fields,
	},
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		error: {
			type: [String, Array, Number, Boolean],
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		subLabel: {
			type: String,
			default: '',
		},
		link: {
			type: Object,
			default: null,
		},
		alert: {
			type: Object,
			default: null,
		},
		subLabelLink: {
			type: String,
			default: '',
		},
		icon: {
			type: Object,
			default: () => ({
				name: '', // icon name
				direction: '', // icon direction
				pos: '', // left or right
				original: false,
				width: '16px',
				height: '16px',
			}),
		},
		inputProps: {
			type: Object,
			required: true,
		},
		inverted: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		columnLayout: {
			type: Boolean,
			default: false,
		},
		value: {
			type: [String, Number, Boolean, FileList, Array],
			default: '',
		},
		fieldFunction: {
			type: Function,
			default: () => null,
		},
	},
	data() {
		return {
			fieldTypes: { ...FieldTypes },
			collectionFieldTypes: {
				input: [FieldTypes.Text, FieldTypes.Email, FieldTypes.Password, FieldTypes.Number, FieldTypes.Date],
				toggle: [FieldTypes.Checkbox, FieldTypes.Radio],
				datetime: [FieldTypes.DateTime],
				amount: [FieldTypes.Amount],
			},
		};
	},
	computed: {
		fieldClasses() {
			return {
				[FieldClasses.Base]: true,
				// Icons
				[FieldClasses.IconLeft]: (this.icon as any).pos === 'left',
				[FieldClasses.IconRight]: (this.icon as any).pos === 'right',
				// Color schemes
				[FieldClasses.Inverted]: this.inverted,
				// States
				[FieldClasses.Disabled]: this.disabled,
				[FieldClasses.HasError]: this.error,
				// Exceptions
				[FieldClasses.ColumnLayout]: this.columnLayout,
			};
		},
		fieldName() {
			let fieldType = (this.inputProps as any).type;

			const typeGroups = Object.keys(this.collectionFieldTypes);

			for (const typeGroup of typeGroups) {
				if ((this.collectionFieldTypes as any)[typeGroup].includes(fieldType)) {
					fieldType = typeGroup;
					break;
				}
			}

			return `cs-field-${fieldType}`;
		},
		fieldProps() {
			return {
				...this.inputProps,
				disabled: this.disabled,
				icon: this.icon,
				readonly: this.readonly,
				value: this.value,
			};
		},
	},
	mounted() {
		if ((this as any).value) {
			(this as any).updateValue((this as any).value);
		}
	},
	methods: {
		updateValue(value) {
			if ((this as any).inputProps.type === 'checkbox' && get(this as any, 'inputProps.options.length') === 1) {
				this.$emit('update:value', (this as any).$el.querySelector('[type="checkbox"]').checked);

				return;
			}

			if ((this as any).inputProps.type === 'select') {
				this.$emit('update:value', value.toString());

				return;
			}

			// Other input or multiple checkboxes => use value
			this.$emit('update:value', value);
		},
	},
});
