/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'checklist': {
    width: 16,
    height: 16,
    viewBox: '0 0 185 197.98',
    data: '<defs/><g id="Layer_2" data-name="Layer 2"><g id="checklist"><path pid="0" class="cls-1" d="M139.47 106.9A45.53 45.53 0 1 0 185 152.43a45.58 45.58 0 0 0-45.53-45.53zm0 89.06A43.53 43.53 0 1 1 183 152.43 43.58 43.58 0 0 1 139.47 196z"/><path pid="1" class="cls-1" d="M159.63 134.81L137.05 164l-17.31-14.06a1 1 0 1 0-1.26 1.55l18.9 15.36L161.21 136a1 1 0 0 0-1.58-1.23z"/><path pid="2" d="M90.23 196H10.66A8.8 8.8 0 0 1 2 187.07V37.86A8.8 8.8 0 0 1 10.66 29h11.83a1 1 0 0 0 0-2H10.66A10.8 10.8 0 0 0 0 37.86v149.21A10.8 10.8 0 0 0 10.66 198h79.57a1 1 0 0 0 0-2zM129.76 27h-12.31a1 1 0 0 0 0 2h12.31a8.66 8.66 0 0 1 8.65 8.66v54.2a1 1 0 0 0 2 0V37.61A10.67 10.67 0 0 0 129.76 27zM103.23 15.77H87.05a17.06 17.06 0 0 0-34 0h-16.2v26.57h66.38zm-2 24.57H38.85V17.77H55v-1a15.07 15.07 0 0 1 30.13 0v1h16.11z"/><path pid="3" d="M111 80H45.56a1 1 0 0 0 0 2H111a1 1 0 0 0 0-2zM94.88 105.89H45.56a1 1 0 1 0 0 2h49.32a1 1 0 0 0 0-2zM76.21 132.82a1 1 0 0 0-1-1H45.56a1 1 0 0 0 0 2h29.65a1 1 0 0 0 1-1zM45.56 157.76a1 1 0 0 0 0 2h25.2a1 1 0 0 0 0-2zM19.55 75.46v11a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-11a1 1 0 0 0-1-1h-11a1 1 0 0 0-1 1zm2 1h9v9h-9zM20.52 113.37h11a1 1 0 0 0 1-1v-11a1 1 0 0 0-1-1h-11a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1zm1-11h9v9h-9zM32.56 127.31a1 1 0 0 0-1-1h-11a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1zm-2 10h-9v-9h9zM31.51 152.2h-11a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-11a1 1 0 0 0-1-1zm-1 11h-9v-9h9z"/></g></g>'
  }
})
