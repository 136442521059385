/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'correctly-packed': {
    width: 16,
    height: 16,
    viewBox: '0 0 189.26 159.77',
    data: '<defs/><g id="Layer_2" data-name="Layer 2"><g id="correctly-packed"><path pid="0" class="cls-1" d="M76.76.13L76.39 0 0 30.72v83l80.08 43.17 25.32-15.29a1 1 0 0 0-1-1.72l-23.19 14V65.46l35-18.26v24.57a1 1 0 0 0 2 0V46.15l31.09-16.22v40.84a1 1 0 0 0 2 0V27.58zm-.33 2l71.37 26.3L117 44.51l-71.31-30zm3.77 61.58L3.55 31.45l39.5-15.89 71.63 30.16zM2 33l77.17 32.45v88.71L2 112.55z"/><path pid="1" class="cls-2" d="M149.81 80.87a39.45 39.45 0 1 0 39.45 39.45 39.45 39.45 0 0 0-39.45-39.45zm-12.11 62.46L119.32 125l4.67-4.63L137.7 134l34.82-34.78 4.63 4.63z"/></g></g>'
  }
})
