// Global store module
// ---
//
// ------------------------------------------------------------------------- /
import get from 'lodash/get';

import config from '@/config';
import { fetchLanguages, fetchCountries, fetchFilteredCountries } from '@/services/masterData';
import { moment, logAndToastError, resetState } from '@/utils';

import apiService from '@/services/api';

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	currentLanguage: config.defaultLanguage,
	isLocked: false,
	businessRoles: [],
	languages: [],
	countries: [],
	filteredCountries: [],
	resetPasswordToken: null,
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	currentLanguage: state => state.currentLanguage,
	isLocked: state => state.isLocked,
	businessRoles: state => state.businessRoles || [],
	businessRoleByName: (state, { businessRoles }) => roleName => businessRoles.find(br => br.name === roleName),
	businessRoleIds: (state, getter) => roles =>
		roles.map(roleName => {
			const role = getter.businessRoleByName(roleName);

			return get(role, 'id');
		}),
	languages: state => state.languages || [],
	countries: state => state.countries || [],
	filteredCountries: state => state.filteredCountries || [],
	resetPasswordToken: state => state.resetPasswordToken,
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	setLanguage({ commit }, language) {
		commit('setLanguage', language);
	},
	setResetPasswordToken({ commit }, token) {
		commit('setResetPasswordToken', token);
	},
	setScrollLock({ commit }, isLocked) {
		commit('setScrollLock', isLocked);
	},
	async getLanguages({ commit }) {
		const languages = await fetchLanguages();

		if (languages && languages.length) {
			commit('setLanguages', { languages });
		}
	},
	async getFilteredCountries({ commit }, { brandIds }) {
		const countries = await fetchFilteredCountries(brandIds);

		if (countries && countries.length) {
			commit('setFilteredCountries', { countries });
		}
	},
	async getCountries({ commit }) {
		const countries = await fetchCountries();

		if (countries && countries.length) {
			commit('setCountries', { countries });
		}
	},
	getBusinessRoles({ commit }) {
		const url = 'businessrelations/roles';
		apiService
			.get(url)
			.then(({ data: roles }) => {
				commit('setBusinessRoles', { roles });
			})
			.catch(err => {
				logAndToastError(commit, 'Failed to get business roles from the server', err, { url });
			});
	},
	resetGlobal({ commit }) {
		commit('resetGlobal');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setLanguage(state, language) {
		state.currentLanguage = language;
		moment.locale(language);
	},
	setResetPasswordToken(state, resetPasswordToken) {
		state.resetPasswordToken = resetPasswordToken;
	},
	setScrollLock(state, isLocked) {
		state.isLocked = isLocked;
	},
	setBusinessRoles(state, { roles }) {
		state.businessRoles = roles;
	},
	setLanguages(state, { languages }) {
		state.languages = languages;
	},
	setCountries(state, { countries }) {
		state.countries = countries;
	},
	setFilteredCountries(state, { countries }) {
		state.filteredCountries = countries;
	},
	resetGlobal(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
