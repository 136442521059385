// Receptacles store module
// ---
//
// ------------------------------------------------------------------------- /
import apiService from '@/services/api';
import get from 'lodash/get';
import set from 'lodash/set';
import { logAndToastError, resetState } from '@/utils';
import RecursiveError from '@/services/recursiveError';

// Helpers
// ------------------------------------------------------------------------- /
const getPackagingTypes = () =>
	new Promise((resolve, reject) => {
		const url = '/logistics/packagingtypes';
		apiService
			.get(url)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(err => {
				reject(new RecursiveError('Failed to get packaging types from the server', err, { url }));
			});
	});

/**
 * @param commit
 * @param state
 * @param businessRelationId
 * @param brandId
 * @param packagingTypeName: "Battery Packaging" | "Transport Packaging"
 * @return {Promise<void>} Results are stored in the store
 */
const getPackagings = async (commit, state, businessRelationId, brandId, packagingTypeName) => {
	let url;

	try {
		if (!businessRelationId || !brandId) {
			logAndToastError(commit, 'Failed to get packagings', null, {
				businessRelationId,
				brandId,
			});

			return;
		}

		let types = state.packagingTypes;
		if (!types || types.length === 0) {
			try {
				types = await getPackagingTypes();
				commit('setPackagingTypes', types);
			} catch (err) {
				logAndToastError(commit, 'Failed to get packaging types from the server', err);
			}
		}

		const packagingType = types.filter(type => type.name === packagingTypeName)[0];

		if (!packagingType) {
			throw new RecursiveError('Failed to find packaging type', null, {
				types,
				packagingTypeName,
			});
		}

		url = `/businessrelations/${businessRelationId}/packagings?typeId=${packagingType.id}&brandId=${brandId}`;
		const response = await apiService.get(url);
		commit('setPackagings', {
			businessRelationId,
			brandId,
			packagingTypeName,
			packagings: response.data.items,
		});
	} catch (err) {
		logAndToastError(commit, 'Failed to get battery packagings from the server', err, {
			url,
			businessRelationId,
			brandId,
		});
	}
};

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	packagingTypes: [],
	packagings: {},
	collectionOrderTransportPackagings: [],
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	getBatteryPackagings: state => (businessRelationId, brandId) =>
		get(state, `packagings["Battery Packaging"][${businessRelationId}][${brandId}]`, []).map(packaging => ({ ...packaging, value: packaging.id })),
	getTransportPackagings: state => (businessRelationId, brandId) => get(state, `packagings["Transport Packaging"][${businessRelationId}][${brandId}]`, null),
	collectionOrderTransportPackagings: state => state.collectionOrderTransportPackagings,
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	async getBatteryPackagings({ commit, state }, { businessRelationId, brandId }) {
		await getPackagings(commit, state, businessRelationId, brandId, 'Battery Packaging');
	},

	async getTransportPackagings({ commit, state }, { businessRelationId, brandId }) {
		await getPackagings(commit, state, businessRelationId, brandId, 'Transport Packaging');
	},

	getCollectionOrderPackagings({ commit }, { collectionOrderId }) {
		// Assumption that there won't be more than 50 packagings per collection order
		const url = `/logistics/collectionorders/${collectionOrderId}/packagings?page=0&pageSize=50`;
		apiService
			.get(url)
			.then(data => {
				commit('setCollectionOrderTransportPackagings', get(data, 'data.items'));
			})
			.catch(err => {
				logAndToastError(commit, 'Failed to get packagings for collection order from the server', err, { url });
			});
	},
	resetPackaging({ commit }) {
		commit('resetPackaging');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setPackagingTypes(state, packagingTypes) {
		state.packagingTypes = packagingTypes;
	},
	setPackagings(state, { businessRelationId, brandId, packagingTypeName, packagings }) {
		set(state, `packagings[${packagingTypeName}][${businessRelationId}][${brandId}]`, packagings);
	},
	setCollectionOrderTransportPackagings(state, packagings) {
		set(state, 'collectionOrderTransportPackagings', packagings);
	},
	resetPackaging(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
