import { mapGetters } from 'vuex';
import find from 'lodash/find';

import {
	batteryFormKeys,
	getBatteryConditionFields,
	getBatteryPackagingFields,
	getBatteryTypeFields,
	validateBatteryConditionProperties,
	validateBatteryPackagingProperties,
	validateBatteryTypeProperties,
} from '@/services/new-battery';
import batteriesMixin from '../batteries';
import formHelpersMixin from './helpers';

const editBatteryFormMixin = {
	mixins: [formHelpersMixin, batteriesMixin],
	data() {
		return {
			batteryFormModel: {
				...batteryFormKeys,
			},
			batteryFormFieldErrors: {
				...batteryFormKeys,
			},
			icons: {
				upload: {
					name: 'attachment-r',
					pos: 'right',
					width: '16px',
					height: '16px',
				},
			},
		};
	},
	computed: {
		...mapGetters(['businessRelationId']),
		batteryModels() {
			return this.$store.getters.batteryModels;
		},
		variationsForModel() {
			return this.batteryFormModel.model ? find(this.batteryModels, { batteryModelId: this.batteryFormModel.model }) : null;
		},
		editBatteryFieldGroupsBatteryType() {
			return getBatteryTypeFields(this);
		},
		editBatteryFieldGroupsBatteryCondition() {
			return getBatteryConditionFields(this);
		},
		editBatteryFieldGroupsBatteryPackaging() {
			return getBatteryPackagingFields(this);
		},
		editBatteryForm() {
			return {
				id: 'edit-battery-form',
				title: this.$t('addBattery.title'),
				model: this.batteryFormModel,
				fieldGroups: this.batteryFieldGroups,
				modelUpdateHandler: this.updateBatteryFormModel,
				modelEditHandler: this.updateBatteryFormModel,
				fieldErrors: this.batteryFormFieldErrors,
			};
		},
	},
	methods: {
		updateBatteryFormModel(newModel) {
			const tmpModel = newModel;

			if (tmpModel.brand && tmpModel.brand !== this.batteryFormModel.brand) {
				tmpModel.partNumber = null;
			}

			this.batteryFormModel = {
				...this.batteryFormModel,
				...tmpModel,
			};
		},
		clearEditBatteryFormError() {
			this.$store.dispatch('clearFormErrors', { form: 'editBattery' });
		},
		clearBatteryFormFieldErrors() {
			this.batteryFormFieldErrors = {
				...batteryFormKeys,
			};
		},
		/**
		 * @param model
		 * @returns {boolean}
		 */
		validateBatteryFormModel(reference, model) {
			this.clearEditBatteryFormError();
			this.clearBatteryFormFieldErrors();
			let valid = true;

			valid = valid && validateBatteryTypeProperties(model, this.batteryFormFieldErrors, this.$t, reference);

			valid = valid && validateBatteryConditionProperties(model, this.batteryFormFieldErrors, this.$t, true, true, reference);

			valid = valid && validateBatteryPackagingProperties(model, this.batteryFormFieldErrors, this.$t);

			return valid;
		},
	},
};

export default editBatteryFormMixin;
