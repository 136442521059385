/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'packaged': {
    width: 16,
    height: 16,
    viewBox: '0 0 189.3 159.8',
    data: '<g _fill="#191a1a"><path pid="0" d="M80.1 156.9l-.5-.3L0 113.7v-83L76.4 0l.4.1 74.5 27.5v43.2c0 .6-.4 1-1 1s-1-.4-1-1V29L76.4 2.1 2 32.1v80.5l78 42.1 24.3-14.7c.5-.3 1.1-.1 1.4.3.3.5.1 1.1-.3 1.4z"/><path pid="1" d="M80.3 65.9L.9 32.5c-.5-.2-.7-.8-.5-1.3s.8-.7 1.3-.5l78.5 33 69.6-36.3c.5-.3 1.1-.1 1.3.4.3.5.1 1.1-.4 1.3z" _stroke="#000"/><path pid="2" d="M80.2 156.3c-.6 0-1-.4-1-1V65.1c0-.6.4-1 1-1s1 .4 1 1v90.2c0 .5-.5 1-1 1z" _stroke="#000"/></g><path pid="3" d="M47.5 11.6l-14.6 5.8 76.6 32.1-.1 32.9 12.6-7.6.1-32.4z" _fill="#cacccc"/><circle pid="4" cx="149.8" cy="120.3" _fill="#48c683" r="39.5"/><path pid="5" d="M137.7 134L124 120.3l-4.7 4.7 18.4 18.3 39.4-39.4-4.6-4.6z" _fill="#fff"/>'
  }
})
