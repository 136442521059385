import * as validators from '@/utils/validators';

const loginFormMixin = {
	data() {
		return {
			loginFormModel: {
				email: null,
				password: null,
			},
			loginFormFieldErrors: {
				email: '',
				password: '',
			},
			loading: false,
		};
	},
	computed: {
		loginFieldGroups() {
			return [
				{
					fields: [
						{
							inputProps: {
								id: 'login-email',
								placeholder: this.$t('login.email.placeholder'),
								type: 'email',
							},
							label: this.$t('login.email'),
							modelKey: 'email',
							required: true,
							onValueChanged: () => this.validate('email'),
						},
						{
							inputProps: {
								id: 'login-pass',
								placeholder: this.$t('login.password.placeholder'),
								type: 'password',
							},
							label: this.$t('login.password'),
							modelKey: 'password',
							required: true,
							onEnter: () => this.loginFormAction(),
							onValueChanged: () => this.validate('password'),
						},
					],
				},
			];
		},
		loginFormErrors() {
			if (this.$store.state.forms.login.errors.length > 0) {
				this.loading = false;
			}

			return this.$store.state.forms.login.errors;
		},
	},
	methods: {
		validate(prop) {
			// If the input field has an error, and the value changes, re-validate the form
			if (this.loginFormFieldErrors[prop]) {
				// Validate the form after the values are set
				setTimeout(() => this.validateLoginFormModel(this.loginFormModel));
			}
		},
		loginFormAction() {
			if (this.validateLoginFormModel(this.loginFormModel)) {
				this.loading = true;
				this.$store.dispatch('login', this.loginFormModel);
			}
		},
		clearLoginFormFieldErrors() {
			this.loginFormFieldErrors = {
				email: '',
				password: '',
			};
		},
		clearLoginFormError() {
			this.$store.dispatch('clearFormErrors', { form: 'login' });
		},
		validateLoginFormModel(model) {
			this.clearLoginFormError();
			this.clearLoginFormFieldErrors();

			let valid = true;

			if (!model.email) {
				this.loginFormFieldErrors.email = this.$t('error.required', [this.$t('login.email')]);
				valid = false;
			} else if (!validators.email(model.email)) {
				this.loginFormFieldErrors.email = this.$t('error.invalid', [this.$t('login.email')]);
				valid = false;
			}

			if (!model.password) {
				this.loginFormFieldErrors.password = this.$t('error.required', [this.$t('login.password')]);
				valid = false;
			}

			return valid;
		},
	},
};

export default loginFormMixin;
