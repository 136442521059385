import { mapGetters } from 'vuex';
import get from 'lodash/get';
//
import { TagColors } from '@/components/tag/tag.enums';
import { ITEMS_PER_PAGE } from '@/services/constants';
import { RnActionHeader, RnNewTable, RnFilters, RnButton, RnNotification, RnIcon, RnPagination, RnAlert, RnTag } from '@/components';

export default {
	name: 'rn-pickup-points-view',
	components: {
		RnActionHeader,
		RnButton,
		RnNotification,
		RnIcon,
		RnPagination,
		RnNewTable,
		RnAlert,
		RnFilters,
	},
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.default,
			page: 0,
		};
	},
	created() {
		this.getPickupPoints();
	},
	computed: {
		...mapGetters(['user', 'locationsByBrand', 'locationsByBrandCount', 'isLoading']),

		brandId() {
			return get(this.user, 'businessRelationId');
		},

		pickupPoints() {
			const locationsWithAddress = this.locationsByBrand.map(location => {
				return {
					...location,
					address: `${location.street} ${location.number}, ${location.zipCode} ${location.city}`,
				};
			});

			return locationsWithAddress;
		},

		pickupPointsCount() {
			return this.locationsByBrandCount;
		},
		pupHeaders() {
			return [
				{
					text: this.$t('user.addressInfo.companyName'),
					value: 'businessRelation.name',
				},
				{
					text: this.$t('user.addressInfo.country'),
					value: 'country',
				},
				{
					text: this.$t('user.addressInfo.address'),
					value: 'address',
				},
				{
					text: this.$t('user.contactInfo.status'),
					value: 'active',
					component: RnTag,
					componentProps: value => ({
						text: this.$t(`table.pup.status.${value.active ? 'active' : 'notActive'}`),
						color: TagColors[value.active ? 'active' : 'notActive'],
					}),
				},
			];
		},
	},
	methods: {
		getPickupPoints(page = 0, excel = false) {
			this.page = page;
			const filters = {
				page: page || 0,
				brandId: this.brandId,
				excel,
			};

			this.$store.dispatch('getLocationsByBrand', filters);
		},

		// Disabled as only adding PUP's was in scope for now. API can also only create PUP's at the moment.
		// getEditButtonComponentProps(pup) {
		// 	return {
		// 		icon: {
		// 			name: 'pencil-alt',
		// 			width: '14px',
		// 			height: '14px',
		// 		},
		// 		color: 'blue',
		// 		outline: true,
		// 		round: true,
		// 		title: this.$t('common.edit'),
		// 		method: () => {
		// 			this.editPup(pup);
		// 		},
		// 	};
		// },
		// getDeleteButtonComponentProps(pup) {
		// 	return {
		// 		icon: {
		// 			name: 'cross',
		// 			width: '16px',
		// 			height: '16px',
		// 		},
		// 		color: 'grey',
		// 		outline: false,
		// 		round: true,
		// 		title: this.$t('common.delete'),
		// 		method: () => {
		// 			this.deletePup(pup);
		// 		},
		// 	};
		// },
		// async editPup(pup) {
		// 	console.log("EDIT PUP", pup);
		// },
		// async deletePup(pup) {
		// 	console.log("DELETE PUP", pup);
		// },
	},
};
