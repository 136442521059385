// Resources store module
// ---
//
// ------------------------------------------------------------------------- /
import serverService from '@/services/server';
import { logAndToastError, resetState } from '@/utils';

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	items: [],
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	resources: state => state.items || [],
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	getAllResources({ commit }) {
		const url = '/resources';
		serverService
			.get(url)
			.then(({ data: resources }) => {
				commit('setResources', resources);
			})
			.catch(err => {
				logAndToastError(commit, 'Failed to get all resources from the server', err, { url });
			});
	},
	resetResources({ commit }) {
		commit('resetResources');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setResources(state, resources) {
		state.items = resources;
	},
	resetResources(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
