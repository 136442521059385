// Message store module
// ---
//
// ------------------------------------------------------------------------- /

// Initial state
// ------------------------------------------------------------------------- /
import { resetState } from '../../utils';

const initialState = {
	text: '',
	type: null,
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	message: state => state,
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	setMessage({ commit }, { text, type }) {
		commit('setMessage', { text, type });
	},
	clearMessage({ commit }) {
		commit('clearMessage');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setMessage(state, message) {
		Object.assign(state, message);
	},
	clearMessage(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
