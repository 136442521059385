var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-table"},[(_vm.scroll.left || _vm.scroll.right)?_c('div',{staticClass:"m-table__navigation"},[(_vm.scroll.left)?_c('rn-button',{staticClass:"m-table__navigation--left",attrs:{"icon":{ name: 'arrow', direction: 'left', width: '16px', height: '16px' },"method":_vm.scrollLeft,"size":"small","round":"","color":"grey"}}):_vm._e(),(_vm.scroll.right)?_c('rn-button',{staticClass:"m-table__navigation--right",attrs:{"icon":{ name: 'arrow', direction: 'right', width: '16px', height: '16px' },"method":_vm.scrollRight,"size":"small","round":"","color":"grey"}}):_vm._e()],1):_vm._e(),_c('v-data-table',{class:{
			'm-table--collapsible': _vm.collapsible,
		},attrs:{"headers":_vm.filteredHeaders,"items-per-page":100,"items":_vm.data,"mobile-breakpoint":"0","item-key":"id","custom-sort":_vm.customSort,"hide-default-footer":""},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item",fn:function(ref){
		var item = ref.item;
		var rowIndex = ref.index;
		var headers = ref.headers;
return [_c('tr',{on:{"click":function () { return (_vm.collapsible ? _vm.clickRow(item) : null); }}},_vm._l((headers),function(header,index){return _c('td',{key:((header.value) + "-" + index),class:{ 'm-table__cell--compact': header.compact },attrs:{"rowspan":item.rowspan,"align":header.align || 'left'}},[(header.component && (!header.renderCondition || (header.renderCondition && header.renderCondition(item))))?_c(header.component,_vm._b({tag:"component"},'component',header.componentProps(item, rowIndex),false)):(header.value)?[_vm._v(" "+_vm._s(_vm.getValue(item, header))+" ")]:_vm._e()],2)}),0)]}}])}),_c('rn-sidebar',{staticClass:"m-table__sidebar",attrs:{"open":_vm.isSidebarOpen,"withOverlay":false},on:{"update:open":function($event){_vm.isSidebarOpen=$event}}},[_c('h2',{attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v(_vm._s(_vm.detailLabel))])]),(_vm.selectedRow)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('dl',{},_vm._l((_vm.headers),function(header,index){return _c('div',{key:((header.value) + "-" + index),staticClass:"m-table__sidebar-pair"},[_c('dt',{staticClass:"m-table__sidebar-key"},[_vm._v(" "+_vm._s(header.text)+" ")]),_c('dd',{staticClass:"m-table__sidebar-value"},[(
								(header.component || header.detailComponent) &&
									(!header.renderCondition || (header.renderCondition && header.renderCondition(_vm.selectedRow)))
							)?_c(header.detailComponent ? header.detailComponent : header.component,_vm._b({tag:"component",staticClass:"m-table__sidebar-component"},'component',header.detailComponent ? header.detailComponentProps(_vm.selectedRow, index) : header.componentProps(_vm.selectedRow, index),false)):(header.value)?_c('span',{staticClass:"m-table__sidebar-text"},[_vm._v(" "+_vm._s(_vm.getValue(_vm.selectedRow, header))+" ")]):_c('span',{staticClass:"m-table__sidebar-text"},[_vm._v("-")])],1)])}),0),(_vm.details && _vm.nestedHeaders)?_c('div',[_c('p',{staticClass:"m-table__sidebar-subtitle"},[_vm._v(" "+_vm._s(_vm.nestedPath)+" ")]),_vm._l((_vm.details),function(detail){return _c('dl',{staticClass:"m-table__sidebar-detail"},_vm._l((_vm.nestedHeaders),function(header,index){return _c('div',{key:("nested-" + (header.value) + "-" + index),staticClass:"m-table__sidebar-pair"},[_c('dt',{staticClass:"m-table__sidebar-key"},[_vm._v(" "+_vm._s(header.text)+" ")]),_c('dd',{staticClass:"m-table__sidebar-value"},[(
									(header.component || header.detailComponent) &&
										(!header.renderCondition || (header.renderCondition && header.renderCondition(detail)))
								)?_c(header.detailComponent ? header.detailComponent : header.component,_vm._b({tag:"component",staticClass:"m-table__sidebar-component"},'component',header.detailComponent ? header.detailComponentProps(detail, index) : header.componentProps(detail, index),false)):(header.value)?_c('span',{staticClass:"m-table__sidebar-text"},[_vm._v(" "+_vm._s(_vm.getValue(detail, header))+" ")]):_c('span',{staticClass:"m-table__sidebar-text"},[_vm._v("-")])],1)])}),0)})],2):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }