import apiService from '@/services/api';
import { hasData } from '@/utils/service-helpers';

export const createAttachment = (fileInfo, type) =>
	apiService
		.post('/attachments', fileInfo)
		.then(hasData)
		.then(attachmentId => ({
			attachmentId,
			fileInfo,
			type,
		}));

export const linkAttachmentsToBattery = async (attachmentIds, batteryId) => {
	if (!attachmentIds.length) {
		return;
	}

	let baseUrl = `/batteries/${batteryId}/attachments?`;

	attachmentIds.forEach(id => (baseUrl += `attachmentId=${id}&`));

	return await apiService.post(baseUrl);
};
