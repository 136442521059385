import { mapGetters } from 'vuex';
import get from 'lodash/get';

import { getInvoiceFile } from '@/services/invoices';
import { ITEMS_PER_PAGE } from '@/services/constants';
import { RnNewTable, RnActionHeader, RnTabs, RnButton, RnSearch, RnField } from '@/components';
import RnPagination from '@/components/pagination/pagination.vue';
import { formatAsDate } from '@/utils/format-as-date';

export default {
	name: 'rn-cp-invoices-view',
	components: {
		RnNewTable,
		RnActionHeader,
		RnButton,
		RnPagination,
		RnSearch,
		RnField,
		RnTabs,
	},
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.DEFAULT,
			page: 0,
			date: null,
			search: '',
		};
	},
	computed: {
		...mapGetters(['businessRoles', 'businessRelationId', 'invoices', 'invoicesCount']),

		invoiceHeaders: {
			get() {
				return [
					{
						text: this.$t('table.invoice.date'),
						value: 'date',
						display: formatAsDate,
						sort: 'date',
					},
					{
						text: this.$t('table.invoice.name'),
						value: 'number',
					},
					{
						text: this.$t('table.invoice.debtor'),
						value: 'debtor',
						sortable: false,
						display: debtor => `${debtor.name} ${get(debtor, 'roles[0].name') ? `(${get(debtor, 'roles[0].name')})` : ''}`,
					},
					this.actionHeader,
				];
			},
			set() {
				return [
					{
						text: this.$t('table.invoice.date'),
						value: 'date',
						display: formatAsDate,
						sort: 'date',
					},
					{
						text: this.$t('table.invoice.name'),
						value: 'number',
					},
					{
						text: this.$t('table.invoice.debtor'),
						value: 'debtor',
						sortable: false,
						display: debtor => `${debtor.name} ${get(debtor, 'roles[0].name') ? `(${get(debtor, 'roles[0].name')})` : ''}`,
					},
					this.actionHeader,
				];
			},
		},
		actionHeader() {
			return {
				text: '',
				value: 'action',
				compact: true,
				align: 'right',
				component: RnButton,
				componentProps: invoice => ({
					method: () => {
						getInvoiceFile(this.businessRelationId, invoice.id);
					},
					outline: true,
					disableWrap: true,
					text: this.$t('common.download'),
					size: 'x-small',
					color: 'blue',
				}),
			};
		},

		totalItems() {
			return this.invoicesCount;
		},
	},
	created() {
		this.getInvoices();
	},
	watch: {
		businessRoles(newValue, oldValue) {
			if (newValue.length > oldValue.length) {
				this.getInvoices();
			}
		},
	},
	methods: {
		onSearchChange(value) {
			this.search = value;
			this.getInvoices();
		},
		onDateChange(date) {
			this.date = date;
			this.getInvoices();
		},
		getInvoices(page = 0) {
			this.page = page;

			this.getNewInvoices('getAllInvoices', page);
		},
		getNewInvoices(type, page = 0) {
			this.$store.dispatch(`${type}`, {
				businessRelationId: this.businessRelationId,
				page,
				pageSize: ITEMS_PER_PAGE.DEFAULT,
				search: this.search,
				date: this.date,
			});
		},
	},
};
