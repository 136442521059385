import get from 'lodash/get';

export const mapNewPupData = data => {
	return {
		pupName: get(data, 'businessRelation.name', ''),
		languageId: get(data, 'businessRelation.language', ''),
		email: get(data, 'businessRelation.email', ''),
		phoneNumber: get(data, 'businessRelation.phone', ''),
		streetName: get(data, 'address[1].streetName', ''),
		streetNumber: get(data, 'address[1].streetNumber', ''),
		postOfficeBox: get(data, 'address[1].bus', ''),
		zipCode: get(data, 'address[1].zipCode', ''),
		city: get(data, 'address[1].city', ''),
		companyNumber: get(data, 'contactPerson.companyNumber', ''),
		countryId: get(data, 'address[1].country', ''),
		firstName: get(data, 'contactPerson.firstName', ''),
		lastName: get(data, 'contactPerson.lastName', ''),
		contactPersonEmail: get(data, 'contactPerson.email', ''),
		contactPersonPhoneNumber: get(data, 'contactPerson.phone', ''),
		contactPersonLanguageId: get(data, 'contactPerson.language', ''),
	};
};
