import { ActionHeaderClasses } from './action-header.enums';

export default {
	name: 'rn-action-header',
	props: {
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: '',
		},
	},
	computed: {
		actionHeaderClasses() {
			return {
				[ActionHeaderClasses.Base]: true,
				// Sizes
				[ActionHeaderClasses.Small]: this.size === 'small',
			};
		},
	},
};
