/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'transport': {
    width: 16,
    height: 16,
    viewBox: '0 0 137.655 125',
    data: '<g _fill="#f86439"><path pid="0" d="M62.254 55.959l-3.629 5.893h3.855l-3.437 7.873a.885.885 0 0 0 .347 1.112.883.883 0 0 0 1.159-.194l9.794-12.156h-3.862l3.542-5.892h-3.84l3.437-7.873a.884.884 0 0 0-.346-1.112.888.888 0 0 0-1.161.193L58.32 55.959h3.934zm2.664-3.837l-.727 1.823h3.446l-3.543 5.892h3.427l-5.869 7.284 2.89-6.62h-3.5l2.995-4.863.544-1.029h-3.438l5.87-7.286-2.095 4.799zM91.581 85.563h1.35v1.967h-1.35zM48.347 85.563h1.351v1.967h-1.351z"/><path pid="1" d="M113.358 66.22l-5.742-16.081a10.79 10.79 0 0 0-10.139-7.146h-2.721v-9.557H33.908v47.89c0 3.47 2.302 6.414 5.459 7.383.989 4.418 4.942 7.731 9.655 7.731 4.592 0 8.463-3.144 9.573-7.393h24.088c1.11 4.248 4.981 7.393 9.573 7.393 5.455 0 9.893-4.438 9.893-9.893l-.001-.11h11.355V66.628l-.145-.408zM84.202 86.286H57.077c-.139-4.327-3.694-7.807-8.055-7.807-4.199 0-7.656 3.225-8.031 7.328-2.203-.313-3.908-2.193-3.908-4.481v-4.443h54.498v1.631a8.068 8.068 0 0 0-7.379 7.772zm-28.462.26c0 3.704-3.013 6.718-6.717 6.718s-6.718-3.014-6.718-6.718 3.014-6.718 6.718-6.718 6.717 3.014 6.717 6.718zm54.588-17.514h-10.241a.65.65 0 0 1-.648-.648c0-.356.291-.647.648-.647h10.241v1.295zm-17.397-2.646V46.168h4.546c3.2 0 6.073 2.025 7.149 5.039l5.419 15.18H92.931zm-1.35-27.024H37.084v-2.751h54.498v2.751zm0 1.351v34.82H37.084v-34.82h54.497zm.675 39.116c3.704 0 6.718 3.014 6.718 6.718s-3.014 6.718-6.718 6.718-6.718-3.014-6.718-6.718 3.014-6.718 6.718-6.718zm.675-1.316V67.736h5.275c-.071.204-.117.42-.117.647a2 2 0 0 0 1.998 1.998h10.241v12.88H99.617a8.076 8.076 0 0 0-6.686-4.748z"/></g>'
  }
})
