import { mapGetters } from 'vuex';
import find from 'lodash/find';

import {
	batteryFormKeys,
	getBatteryConditionFields,
	getBatteryPackagingFields,
	getBatteryTypeFields,
	validateBatteryConditionProperties,
	validateBatteryPackagingProperties,
	validateBatteryTypeProperties,
} from '@/services/new-battery';
import batteriesMixin from '../batteries';
import formHelpersMixin from './helpers';

const addBatteryFormMixin = {
	mixins: [formHelpersMixin, batteriesMixin],
	data() {
		return {
			batteryFormModel: {
				...batteryFormKeys,
			},
			batteryFormFieldErrors: {
				...batteryFormKeys,
			},
		};
	},
	computed: {
		...mapGetters(['brands, businessRelationId', 'batteryConditions']),
		batteryModels() {
			return this.$store.getters.batteryModels;
		},
		variationsForModel() {
			return this.batteryFormModel.model ? find(this.batteryModels, { batteryModelId: this.batteryFormModel.model }) : null;
		},
		addBatteryFieldGroupsBatteryType() {
			return getBatteryTypeFields(this);
		},
		addBatteryFieldGroupsBatteryCondition() {
			return getBatteryConditionFields(this);
		},
		addBatteryFieldGroupsBatteryPackaging() {
			return getBatteryPackagingFields(this);
		},
		addBatteryForm() {
			return {
				id: 'add-battery-form',
				title: this.$t('addBattery.title'),
				model: this.batteryFormModel,
				modelUpdateHandler: this.updateBatteryFormModel,
				modelEditHandler: this.updateBatteryFormModel,
				fieldErrors: this.batteryFormFieldErrors,
			};
		},
		batteryStatusInfo() {
			return getBatteryStatusInfo(this.$t, this.batteryConditions);
		},
		activeBatteryStatus() {
			return this.batteryStatusInfo.find(status => status.id === this.batteryFormModel.batteryConditionId);
		},
	},
	created() {
		this.$store.dispatch('getAllBrands', this.businessRelationId);
	},
	methods: {
		updateBatteryFormModel(newModel) {
			if (newModel.brand && newModel.brand !== this.batteryFormModel.brand) {
				newModel.partNumber = null;
			}

			if (this.batteryFormModel.batteryPackagingId && (newModel.customBatteryPackaging || newModel.noBatteryPackaging)) {
				newModel.batteryPackagingId = null;
			}

			if (this.batteryFormModel.noBatteryPackaging && newModel.customBatteryPackaging) {
				newModel.noBatteryPackaging = false;
			}

			if (this.batteryFormModel.customBatteryPackaging && newModel.batteryPackagingId) {
				newModel.customBatteryPackaging = false;
			}

			if (this.batteryFormModel.customBatteryPackaging && newModel.noBatteryPackaging) {
				newModel.customBatteryPackaging = false;
			}

			if (!this.batteryFormModel.isBatteryStable && newModel.isBatteryStable && !this.batteryFormModel.damaged && !newModel.damaged) {
				newModel.damagedDescription = '';
			}

			this.batteryFormModel = {
				...this.batteryFormModel,
				...newModel,
				id: this.batteryFormModel.id,
			};
		},
		clearAddBatteryFormErrors() {
			this.batteryFormFieldErrors = {
				...batteryFormKeys,
			};
			this.$store.dispatch('clearFormErrors', { form: 'addBattery' });
		},
		/**
		 * Step is optional
		 * - if not provided, the complete model will we checked
		 * - if provided, only the fields in that step will be validated
		 * @param model
		 * @param step
		 * @param skipImageAttachments
		 * @param skipBmsAttachment
		 * @returns {boolean}
		 */
		validateBatteryFormModel(reference, model, step, skipImageAttachments = false, skipBmsAttachment = false) {
			this.clearAddBatteryFormErrors();
			let valid = true;

			if (step === 1) {
				valid = valid && validateBatteryTypeProperties(model, this.batteryFormFieldErrors, this.$t, reference);
			}

			if (step === 2) {
				valid =
					valid &&
					validateBatteryConditionProperties(model, this.batteryFormFieldErrors, this.$t, skipImageAttachments, skipBmsAttachment, reference);
			}

			if (step === 3) {
				valid = valid && validateBatteryPackagingProperties(model, this.batteryFormFieldErrors, this.$t);
			}

			return valid;
		},
	},
};

export default addBatteryFormMixin;
