import RnIcon from '../icon/icon.vue';

export default {
	name: 'rn-accordeon',
	components: {
		RnIcon,
	},
	props: {
		title: {
			type: String,
			default: 'even',
		},
		method: {
			type: Function,
			default: () => {
				// do nothing
			},
		},
	},
	data() {
		return {
			isOpen: false,
		};
	},
	methods: {
		handleOpenAccordeon() {
			this.isOpen = !this.isOpen;
			this.method();
		},
	},
};
