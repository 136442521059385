/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'chevron': {
    width: 16,
    height: 16,
    viewBox: '-472.5 277 11.5 7.1',
    data: '<path pid="0" d="M-471.1 284.1l4.4-4.4 4.4 4.4 1.4-1.3-5.8-5.8-5.8 5.8z"/>'
  }
})
