/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'photo-add': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 8v2.99s-1.99.01-2 0V8h-3s.01-1.99 0-2h3V3h2v3h3v2h-3zm-3 4V9h-3V6H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-8h-3zM5 20l3-4 2 3 3-4 4 5H5z" _fill="#6B6D6D"/>'
  }
})
