/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'receptacle-cardboard': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.38 4.022L31 6.328v11.427a8.003 8.003 0 0 0-1-.685V7.2l-.18-.025-5.112 1.16v4.009l-4.527 1.142V9.362l-5.542 1.258v16.26l3.546-1.163c.072.33.164.65.275.963l-4.09 1.34L1 22.54V6.344l13.38-2.32zm.018 1.012l-2.722.472L24.453 7.88l3.966-.9-14.021-1.946zM8.266 6.098l12.415 2.75v3.995l3.527-.89v-3.61L10.261 5.752l-1.995.346zm-5.088.882l11.218 3.183 4.892-1.11L6.97 6.322l-3.792.657zM2 21.87l12.139 4.975V10.61L2.042 7.177 2 7.184V21.87z" _fill="#191A1A"/><path pid="1" d="M31 20.682a6.027 6.027 0 0 0-1-1.154V21l.5.5-1.833 1.833L31 22.568v-1.886zM22.39 25.39l-2.029.666c0-.002 0-.004-.002-.006a5.96 5.96 0 0 1-.26-.96l1.82-.597-.214.212.686.686zM26.565 24.022l-3.108 1.02-.793-.793 5.47-1.794-1.569 1.567z" _fill="#191A1A"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M32 24a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm-9.585 0l2.085 2.085 5.295-5.29.705.705-6 6-2.795-2.795.71-.705z" _fill="#10BC60"/>'
  }
})
