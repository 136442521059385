/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'plus-r': {
    width: 16,
    height: 16,
    viewBox: '-466 268 26 26',
    data: '<path pid="0" d="M-453 268c-7.2 0-13 5.8-13 13s5.8 13 13 13 13-5.8 13-13-5.8-13-13-13zm5.2 14h-4.3v4.3h-1.9V282h-4.3v-2h4.3v-4.3h1.9v4.3h4.3z"/>'
  }
})
