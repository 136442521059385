import config from '@/config';

import * as validators from '@/utils/validators';
import { mapGetters } from 'vuex';

const contactInfoModel = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	language: '',
	companyNumber: '',
};

const contactInformationFormMixin = {
	data() {
		return {
			contactInformationFormModel: {
				...contactInfoModel,
			},
			contactInformationFormFieldErrors: {
				...contactInfoModel,
			},
			pending: false,
		};
	},
	created() {
		if (this.locationId) {
			this.$store.dispatch('getLocation', { locationId: this.locationId });
		}
	},
	computed: {
		...mapGetters(['isContactInformationChangesPending', 'locationId', 'locationsCompanyNumber']),
		companyNumber() {
			return this.$store.getters.locationsCompanyNumber(this.locationId);
		},
		companyNumberRequired() {
			return this.$store.getters.locationsCompanyNumberRequired(this.locationId);
		},
		companyNumberFormat() {
			return this.$store.getters.locationsCompanyNumberFormat(this.locationId);
		},
		contactInformationFieldGroups() {
			return [
				{
					fields: [
						{
							cols: '6',
							inputProps: {
								id: 'contact-info-first-name',
								placeholder: this.$t('user.contactInfo.firstName.placeholder'),
								type: 'text',
							},
							label: this.$t('user.contactInfo.firstName'),
							modelKey: 'firstName',
							required: true,
							onValueChanged: () => this.validate('firstName'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'contact-info-last-name',
								placeholder: this.$t('user.contactInfo.lastName.placeholder'),
								type: 'text',
							},
							label: this.$t('user.contactInfo.lastName'),
							modelKey: 'lastName',
							required: true,
							onValueChanged: () => this.validate('lastName'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'contact-info-email',
								placeholder: this.$t('user.contactInfo.email.placeholder'),
								type: 'email',
							},
							label: this.$t('user.contactInfo.email'),
							subLabel: this.$t('user.contactInfo.email.description'),
							modelKey: 'email',
							required: true,
							disabled: true,
							onValueChanged: () => this.validate('email'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'contact-info-phone',
								placeholder: this.$t('user.contactInfo.phone.placeholder'),
								type: 'text',
							},
							label: this.$t('user.contactInfo.phone'),
							modelKey: 'phone',
							required: true,
							onValueChanged: () => this.validate('phone'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'contact-info-language',
								options: config.supportedLanguages,
								placeholder: this.$t('user.contactInfo.language.placeholder'),
								type: 'select',
							},
							label: this.$t('user.contactInfo.language'),
							modelKey: 'language',
							required: true,
							onValueChanged: () => this.validate('language'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'contact-info-companyNumber',
								placeholder: this.$t('user.contactInfo.companyNumber.placeholder'),
								type: 'text',
							},
							label: this.$t('user.contactInfo.companyNumber'),
							modelKey: 'companyNumber',
							required: this.companyNumberRequired,
							onValueChanged: () => this.validate('companyNumber'),
						},
					],
				},
			];
		},
		contactInfoForm() {
			return this.$store.state.forms.contactInformation;
		},
		isPending() {
			return this.pending;
		},
		contactInformationForm() {
			return {
				model: this.contactInformationFormModel,
				fieldGroups: this.contactInformationFieldGroups,
				modelUpdateHandler: this.updateContactInformationFormModel,
				actionHandler: this.contactInformationFormAction,
				form: this.contactInfoForm,
				fieldErrors: this.contactInformationFormFieldErrors,
			};
		},
	},
	methods: {
		validate(prop) {
			// If the input field has an error, and the value changes, re-validate the form
			if (this.contactInformationFormFieldErrors[prop]) {
				// Validate the form after the values are set
				setTimeout(() => this.validateContactInformationFormModel(this.contactInformationFormModel));
			}
		},
		updateContactInformationFormModel(newModel) {
			this.contactInformationFormModel = this.contactInformationFormModel = {
				...this.contactInformationFormModel,
				...newModel,
			};
		},
		contactInformationFormAction() {
			if (this.validateContactInformationFormModel(this.contactInformationFormModel)) {
				this.$store.dispatch('updateContactInformation', this.contactInformationFormModel);

				const { companyNumber } = this.contactInformationFormModel;

				if (this.locationId && companyNumber) {
					this.$store.dispatch('updateLocation', { locationId: this.locationId, companyNumber: companyNumber });
				}
			}
		},
		clearContactInformationFormFieldErrors() {
			this.contactInformationFormFieldErrors = {
				...contactInfoModel,
			};
		},
		clearContactInformationFormError() {
			this.$store.dispatch('clearFormErrors', { form: 'contactInformation' });
		},
		validateContactInformationFormModel(model) {
			this.clearContactInformationFormError();
			this.clearContactInformationFormFieldErrors();

			let valid = true;

			if (!model.firstName) {
				this.contactInformationFormFieldErrors.firstName = this.$t('error.required', [this.$t('user.contactInfo.firstName')]);
				valid = false;
			}

			if (!model.lastName) {
				this.contactInformationFormFieldErrors.lastName = this.$t('error.required', [this.$t('user.contactInfo.lastName')]);
				valid = false;
			}

			if (!model.email) {
				this.contactInformationFormFieldErrors.email = this.$t('error.required', [this.$t('user.contactInfo.email')]);
				valid = false;
			} else if (!validators.email(model.email)) {
				this.contactInformationFormFieldErrors.email = this.$t('error.invalid', [this.$t('user.contactInfo.email')]);
				valid = false;
			}

			if (!model.phone) {
				this.contactInformationFormFieldErrors.phone = this.$t('error.required', [this.$t('user.contactInfo.phone')]);
				valid = false;
			}

			if (!model.language) {
				this.contactInformationFormFieldErrors.language = this.$t('error.required', [this.$t('user.contactInfo.language')]);
				valid = false;
			}

			const companyNumberRegex = new RegExp(this.companyNumberFormat);

			if (this.companyNumberRequired && !model.companyNumber) {
				this.contactInformationFormFieldErrors.companyNumber = this.$t('error.required', [this.$t('user.contactInfo.companyNumber')]);
				valid = false;
			} else if (model.companyNumber && !companyNumberRegex.test(model.companyNumber)) {
				this.contactInformationFormFieldErrors.companyNumber = this.$t('error.invalid', [this.$t('user.contactInfo.companyNumber')]);
				valid = false;
			}

			return valid;
		},
	},
	watch: {
		companyNumber: {
			immediate: true,
			handler(value) {
				this.contactInformationFormModel = {
					...this.contactInformationFormModel,
					companyNumber: value,
				};
				this.$store.dispatch('updateContactInformationChangesPending');
			},
		},
		user: {
			immediate: true,
			handler() {
				this.contactInformationFormModel = {
					...this.contactInformationFormModel,
					firstName: this.user.firstName,
					lastName: this.user.lastName,
					email: this.user.email,
					phone: this.user.phone,
					language: this.user.language.substring(0, 2),
				};
				this.$store.dispatch('updateContactInformationChangesPending');
			},
		},
	},
};

export default contactInformationFormMixin;
