/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'duplicate': {
    width: 16,
    height: 16,
    viewBox: '0 0 595.3 841.9',
    data: '<path pid="0" d="M495.3 111.8c-8.7-8.7-20.4-13.6-32.7-13.6H233.9c-25.5 0-46.3 20.8-46.3 46.3v81.3H63.8c-25.5 0-46.3 20.8-46.3 46.3v425.2c0 25.5 20.8 46.3 46.3 46.3h297.6c25.5 0 46.3-20.8 46.3-46.3V616h123.8c25.5 0 46.3-20.8 46.3-46.3V213.5c0-12.4-4.8-24-13.6-32.7l-68.9-69zM378.9 689.4c0 14-11.4 25.5-25.5 25.5H71.8c-14 0-25.5-11.4-25.5-25.5V280.1c0-14 11.4-25.5 25.5-25.5h115.8v315.1c0 25.5 20.8 46.3 46.3 46.3H379v73.4zM549 561.8c0 14-11.4 25.5-25.5 25.5H241.8c-14 0-25.5-11.4-25.5-25.5V152.5c0-14 11.4-25.5 25.5-25.5h178.3v114.4c0 7.9 6.5 14.4 14.4 14.4H549v306zm0-334.7H449v-100h10.3c6.8 0 13.2 2.6 18 7.5l64.3 64.3c4.8 4.8 7.5 11.2 7.5 18v10.2z"/>'
  }
})
