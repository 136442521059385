import { mapGetters } from 'vuex';

import { RnHeader, RnButton, RnPagination, RnNewTable, RnOrderPlanner, RnSpinner } from '@/components';
import { ITEMS_PER_PAGE } from '@/services/constants';
import { getDate } from '@/utils';

export default {
	name: 'rn-rc-dashboard-view',
	components: {
		RnHeader,
		RnButton,
		RnPagination,
		RnNewTable,
		RnOrderPlanner,
		RnSpinner,
	},
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.DEFAULT,
			collectionOrdersTableHeaders: [
				{
					text: this.$t('table.order.number'),
					value: 'number',
					compact: true,
				},
				{
					text: this.$t('table.order.dateRequested'),
					value: 'orderCreationDate',
					customSort: 'date',
					display: date => getDate(date),
					compact: true,
				},
				{
					text: this.$t('table.order.pickupDate'),
					value: 'pickUpDate',
					customSort: 'date',
					display: date => getDate(date),
					compact: true,
				},
				{
					text: this.$t('common.collectionPoint'),
					value: 'sourceLocation.name',
				},
				{
					text: this.$t('table.order.collectedBy'),
					value: 'owner.name',
				},
				{
					text: this.$t('addBattery.brand'),
					value: 'batteries[0].batteryDescription.brand.name',
				},
				{
					text: '',
					value: 'action',
					compact: true,
					component: RnButton,
					componentProps: ({ id }) => ({
						method: () => {
							this.checkCollection(id);
						},
						outline: true,
						disableWrap: true,
						text: this.$t('common.checkCollection'),
						size: 'x-small',
						color: 'blue',
					}),
				},
			],
			page: 0,
		};
	},
	computed: {
		...mapGetters(['userName', 'businessRelationId', 'incomingUncheckedCollectionOrders', 'incomingUncheckedCollectionOrdersCount']),
		collectionOrdersState() {
			if (!this.incomingUncheckedCollectionOrders) {
				return 'loading';
			}
			if (this.incomingUncheckedCollectionOrders.length) {
				return 'results';
			}
			return 'noResults';
		},
	},
	created() {
		this.updateCollectionOrdersPaginated(0);
	},
	methods: {
		checkCollection(collectionOrderId) {
			this.$router.push({ name: 'RCOrderChecking', params: { id: collectionOrderId } });
		},
		updateCollectionOrdersPaginated(page) {
			this.page = page;
			this.$store.dispatch('getIncomingUncheckedCollectionOrders', {
				businessRelationId: this.businessRelationId,
				page: page || 0,
				pageSize: this.ITEMS_PER_PAGE,
			});
		},
	},
};
