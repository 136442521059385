/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'pallet': {
    width: 105,
    height: 39,
    viewBox: '0 0 105 39',
    data: '<defs><path pid="0" d="M105.262 57.46l-7.503 1.187-36.034-13.302 6.147-.705a5.422 5.422 0 0 1 2.39.257l35.046 12.051c.264.09.23.47-.046.512zM69.89 63.056L37.547 48.119l7.993-.917 33.958 14.334-9.608 1.52zm-26.002 4.113a6.606 6.606 0 0 1-4.282-.791l-24.01-14.911a.456.456 0 0 1 .178-.85l6.022-.69 29.772 16.027-7.68 1.215zM33.358 48.6l32.636 15.073-10.848 1.716-29.534-15.9 7.746-.888zm23.54-2.7l36.431 13.449-9.73 1.539-33.626-14.193 6.925-.795zm54.808 9.515L70.737 41.26a4.157 4.157 0 0 0-1.896-.236L9.148 48.137A1.278 1.278 0 0 0 8 49.402v3.485c0 .426.216.824.575 1.06l2.43 1.594v1.684c0 .351.17.681.459.887l4.87 3.481c.018.012.037.021.054.032l.065.04c.034.018.068.033.104.047.021.01.043.02.065.027.04.013.08.023.12.032l.059.014a1.13 1.13 0 0 0 .183.016 1.1 1.1 0 0 0 .156-.012c.007-.002.013-.001.02-.002l2.37-.375 2.99 2.06v1.976c0 .35.17.68.458.886l5.216 3.735c.018.012.037.021.055.033.023.014.044.028.067.04.033.018.067.033.102.047.022.009.043.02.066.027.04.013.08.022.12.031l.058.014c.06.01.12.016.181.016h.001c.055 0 .11-.006.165-.014.007 0 .014 0 .022-.002l2.692-.452 4.033 2.778v2.326c0 .352.17.682.459.887l5.586 3.992c.018.014.038.023.057.035l.066.04c.033.018.067.032.101.046.022.009.044.019.067.027.038.013.078.022.118.031l.058.014c.06.01.12.015.18.015h.002c.04 0 .078-.003.117-.007.011 0 .023-.003.034-.004l.047-.006 8.128-1.462c.527-.095.91-.548.91-1.077v-2.676l16.936-2.935 3.993 2.47c.007.005.014.008.022.012l.053.028a1.073 1.073 0 0 0 .284.101l.054.012a1.1 1.1 0 0 0 .299.007l.034-.005c.013-.001.027-.002.04-.005l8.128-1.461c.527-.095.91-.548.91-1.078v-2.476l13.593-2.356 4.189 2.3c.048.026.098.048.149.067l.042.014c.043.013.085.025.128.033l.048.009a1.123 1.123 0 0 0 .301.006c.01 0 .021-.002.032-.004l.037-.005 8.127-1.462c.527-.094.91-.548.91-1.077V64.64l1.336-.22A1.98 1.98 0 0 0 113 62.476V57.26c0-.821-.515-1.557-1.294-1.846z" id="svgicon-pallet-a"/></defs><use _fill="#EC6244" xlink:href="#svgicon-pallet-a" transform="translate(-8 -41)" fill-rule="evenodd"/>'
  }
})
