import { moment } from '@/utils';

import { mapGetters } from 'vuex';
import RnCalendar from '../calendar/calendar.vue';
import RnButton from '../button/button.vue';
import RnNewTable from '../new-table/table.vue';
import RnIcon from '../icon/icon.vue';
import { formatAsDate } from '@/utils/format-as-date';

export default {
	name: 'rn-order-planner',
	components: {
		RnCalendar,
		RnButton,
		RnNewTable,
		RnIcon,
	},
	filters: {
		formatDate(date, format) {
			return new moment(date).format(format);
		},
		capitalize(value) {
			if (!value) {
				return '';
			}

			return (
				value
					.toString()
					.charAt(0)
					.toUpperCase() + value.slice(1)
			);
		},
	},
	data() {
		return {
			selectedDate: new Date(),
			collectionOrdersForDay: [],
		};
	},
	computed: {
		...mapGetters(['businessRelationId', 'plannedOnMonthCollectionOrders']),
		tableHeaders() {
			return [
				{
					text: this.$t('orderPlanner.hour'),
					value: 'pickUpDate',
					display: formatAsDate,
				},
				{
					text: this.$t('orderPlanner.collectionPoint'),
					value: 'sourceLocation.name',
				},
			];
		},
	},
	mounted() {
		this.initialMonth();
	},
	methods: {
		initialMonth() {
			const minDate = moment(this.selectedDate).startOf('month');
			const maxDate = moment(this.selectedDate).endOf('month');

			this.$store.dispatch('getPlannedOnMonthCollectionOrders', {
				businessRelationId: this.businessRelationId,
				minDate: minDate.toISOString(),
				maxDate: maxDate.toISOString(),
				page: 0,
				pageSize: 1000,
			});
		},
		changeMonth(modifier) {
			this.selectedDate = new moment(this.selectedDate).add(modifier, 'months').toDate();

			const minDate = moment(this.selectedDate).startOf('month');
			const maxDate = moment(this.selectedDate).endOf('month');

			this.$store.dispatch('getPlannedOnMonthCollectionOrders', {
				businessRelationId: this.businessRelationId,
				minDate: minDate.toISOString(),
				maxDate: maxDate.toISOString(),
				page: 0,
				pageSize: 1000,
			});
		},
		planNewTransport() {
			this.$router.push({ name: 'CPNewTransport', params: { selectedDate: this.selectedDate } });
		},
		onUpdateSelectedDay(data) {
			this.selectedDate = data.date;
			this.collectionOrdersForDay = data.collections;
		},
	},
};
