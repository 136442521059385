import { mapGetters } from 'vuex';

import { RnButton, RnField, RnTag, RnActionHeader, RnNewTable, RnSpinner, RnPagination } from '@/components';
import { batteriesMixin } from '@/mixins';
import { ITEMS_PER_PAGE } from '../../../services/constants';

export default {
	name: 'rn-cp-warehouse-view',
	components: {
		RnButton,
		RnField,
		RnTag,
		RnActionHeader,
		RnNewTable,
		RnSpinner,
		RnPagination,
	},
	mixins: [batteriesMixin],
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.DEFAULT,
			page: 0,
		};
	},
	computed: {
		...mapGetters(['locations', 'businessRelationId', 'checkedBatteries', 'checkedBatteriesCount']),
		batteryTableHeaders() {
			return [this.brandBatteryTableHeader, ...this.existingBatteryTableHeaders];
		},
		batteriesState() {
			if (!this.checkedBatteries) {
				return 'loading';
			}
			if (this.checkedBatteries.length) {
				return 'results';
			}

			return 'noResults';
		},
	},
	created() {
		this.updatePage(0);
	},
	methods: {
		updatePage(page) {
			this.page = page;
			this.$store.dispatch('getCheckedBatteries', {
				businessRelationId: this.businessRelationId,
				page: this.page,
				pageSize: ITEMS_PER_PAGE.DEFAULT,
			});
		},
	},
};
