/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'receptacle-pallet': {
    width: 16,
    height: 16,
    viewBox: '-394 249 169.7 63.8',
    data: '<path pid="0" d="M-236.8 275.9l-12.1 1.9-58.2-21.8 9.9-1.2c1.3-.2 2.6 0 3.9.4l56.6 19.7c.4.3.3 1-.1 1zm-57.2 9.2l-52.3-24.4 12.9-1.5 54.9 23.4zm-42 6.7c-2.4.4-4.8-.1-6.9-1.3l-38.8-24.4c-.6-.4-.4-1.3.3-1.4l9.7-1.1 48.1 26.2zm-17-30.4l52.7 24.7-17.5 2.8-47.7-26zm38-4.4l58.9 22-15.7 2.5-54.3-23.2zm88.6 15.6l-66.2-23.2c-.8-.3-1.5-.4-2.3-.4h-.7l-96.5 11.6c-1.1.1-1.9 1-1.9 2.1v5.7c0 .7.3 1.3.9 1.7l3.9 2.6v2.8c0 .6.3 1.1.7 1.5l7.9 5.7s.1 0 .1.1c0 0 .1 0 .1.1.1 0 .1.1.2.1h.1c.1 0 .1 0 .2.1h.7l3.8-.6 4.8 3.4v3.2c0 .6.3 1.1.7 1.5l8.4 6.1s.1 0 .1.1c0 0 .1 0 .1.1.1 0 .1.1.2.1h.1c.1 0 .1 0 .2.1h.7l4.4-.7 6.5 4.5v3.8c0 .6.3 1.1.7 1.5l9 6.5s.1 0 .1.1c0 0 .1 0 .1.1.1 0 .1.1.2.1h.1c.1 0 .1 0 .2.1h.8l13.1-2.4c.9-.2 1.5-.9 1.5-1.8v-4.4l27.4-4.8 6.5 4h.1c.1 0 .1.1.2.1h.1c.1 0 .1 0 .2.1h.8l13.1-2.4c.9-.2 1.5-.9 1.5-1.8v-4.1l22-3.9 6.8 3.8c.1 0 .2.1.2.1h.1c.1 0 .1 0 .2.1h.8l13.1-2.4c.9-.2 1.5-.9 1.5-1.8v-3.8l2.2-.4c1.6-.3 2.7-1.6 2.7-3.2v-8.5c-.4-1.3-1.2-2.6-2.5-3z"/>'
  }
})
