/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'track': {
    width: 16,
    height: 16,
    viewBox: '0 0 183 151',
    data: '<defs/><g id="Layer_2" data-name="Layer 2"><g id="track"><path pid="0" class="cls-1" d="M144.17 23.27v-.07a.84.84 0 0 0-.18-.28l-.13-.12-.13-.06L90.09.08a1 1 0 0 0-.8 0l-50.7 22.6-.11.05a1.07 1.07 0 0 0-.16.14.79.79 0 0 0-.18.29v.07a.87.87 0 0 0-.06.32v60.67a1 1 0 0 0 .61.92l51.88 22.28a.84.84 0 0 0 .35.07.84.84 0 0 0 .35-.07l52.36-22.28a1 1 0 0 0 .61-.92V23.61a1 1 0 0 0-.07-.34zM89.71 2.09l50.94 21.51-23.09 9.62L67.4 12zm1.17 42.25L41.52 23.58l23.59-10.52a1 1 0 0 0 .43.36L115 34.3zM40 25.11l49.88 21V105L40 83.56zm102.24 58.45L91.88 105V46.09l25-10.4V52a1 1 0 0 0 2 0V34.85l23.39-9.74z"/><path pid="1" d="M167.5 120a15.51 15.51 0 0 0-15.5 15.5h-45a15.5 15.5 0 0 0-31 0H31a15.59 15.59 0 1 0-.14 2h45.28a15.49 15.49 0 0 0 30.72 0h45.28A15.5 15.5 0 1 0 167.5 120zm-152 29A13.5 13.5 0 1 1 29 135.5 13.52 13.52 0 0 1 15.5 149zm76 0a13.5 13.5 0 1 1 13.5-13.5A13.52 13.52 0 0 1 91.5 149zm76 0a13.5 13.5 0 1 1 13.5-13.5 13.52 13.52 0 0 1-13.5 13.5z"/><circle pid="2" cx="91.5" cy="135.5" r="3"/></g></g>'
  }
})
