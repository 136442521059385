/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'pallet-grey': {
    width: 16,
    height: 16,
    viewBox: '0 0 105 39',
    data: '<defs/><g id="Layer_2" data-name="Layer 2"><g id="pallet"><g id="_-Symbols" data-name="⚙-Symbols"><g id="atom-_-illustration-_-pallet" data-name="atom-/-illustration-/-pallet"><g id="pallet-2" data-name="pallet"><path pid="0" id="path-1" class="cls-1" d="M97.26 16.46l-7.5 1.19-36-13.3 6.14-.71a5.39 5.39 0 0 1 2.39.26L97.31 16a.26.26 0 0 1-.05.46zm-35.37 5.6L29.55 7.12l8-.92 34 14.34zm-26 4.11a6.57 6.57 0 0 1-4.28-.79L7.6 10.47a.45.45 0 0 1 .17-.85l6-.69L43.57 25zM25.36 7.6L58 22.67l-10.85 1.72-29.54-15.9zM48.9 4.9l36.43 13.45-9.73 1.54L42 5.69zm54.81 9.51L62.74.26A4.27 4.27 0 0 0 61.29 0h-.45L1.15 7.14A1.27 1.27 0 0 0 0 8.4v3.49A1.29 1.29 0 0 0 .58 13L3 14.54v1.68a1.1 1.1 0 0 0 .46.89l4.87 3.48h.12l.11.05h.6l2.37-.38 3 2.06v2a1.07 1.07 0 0 0 .46.88l5.21 3.74h.13l.1.05h.59l2.69-.45 4 2.78v2.32a1.12 1.12 0 0 0 .45.89l5.59 4H34.6l8.13-1.46a1.1 1.1 0 0 0 .91-1.08v-2.22l16.93-2.94 4 2.47H64.74a.23.23 0 0 0 .11 0h.43l8.25-1.3a1.09 1.09 0 0 0 .91-1.08v-2.48L88 26.08l4.19 2.3.15.07H93l8.09-1.45a1.09 1.09 0 0 0 .91-1v-2.36l1.34-.22a2 2 0 0 0 1.66-1.94v-5.22a2 2 0 0 0-1.29-1.85z"/></g></g></g></g></g>'
  }
})
