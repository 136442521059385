import * as validators from '@/utils/validators';
import { mapGetters } from 'vuex';

const contactPersonModel = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	language: '',
	companyNumber: '',
};

const contactPersonFormMixin = {
	data() {
		return {
			contactPersonFormModel: {
				...contactPersonModel,
			},
			contactPersonFormFieldErrors: {
				...contactPersonModel,
			},
			pending: false,
		};
	},
	computed: {
		...mapGetters(['isContactInformationChangesPending', 'languages', 'businessRelationId']),
		companyNumberRequired() {
			return this.$store.getters.brandCompanyNumberRequired(this.businessRelationId);
		},
		companyNumberFormat() {
			return this.$store.getters.brandCompanyNumberFormat(this.businessRelationId);
		},
		contactPersonFieldGroups() {
			return [
				{
					fields: [
						{
							cols: '6',
							inputProps: {
								id: 'contact-person-first-name',
								placeholder: this.$t('user.contactInfo.firstName.placeholder'),
								type: 'text',
							},
							label: this.$t('user.contactInfo.firstName'),
							modelKey: 'firstName',
							required: true,
							onValueChanged: () => this.validate('firstName'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'contact-person-last-name',
								placeholder: this.$t('user.contactInfo.lastName.placeholder'),
								type: 'text',
							},
							label: this.$t('user.contactInfo.lastName'),
							modelKey: 'lastName',
							required: true,
							onValueChanged: () => this.validate('lastName'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'contact-person-email',
								placeholder: this.$t('user.contactInfo.email.placeholder'),
								type: 'email',
							},
							label: this.$t('user.contactInfo.email'),
							modelKey: 'email',
							required: true,
							onValueChanged: () => this.validate('email'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'contact-person-phone',
								placeholder: this.$t('user.contactInfo.phone.placeholder'),
								type: 'text',
							},
							label: this.$t('user.contactInfo.phone'),
							modelKey: 'phone',
							required: true,
							onValueChanged: () => this.validate('phone'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'contact-person-language',
								options: this.languageOptions,
								placeholder: this.$t('user.contactInfo.language.placeholder'),
								type: 'select',
							},
							label: this.$t('user.contactInfo.language'),
							modelKey: 'language',
							required: true,
							onValueChanged: () => this.validate('language'),
						},
						{
							cols: '6',
							inputProps: {
								id: 'contact-person-companyNumber',
								placeholder: this.$t('user.contactInfo.companyNumber.placeholder'),
								type: 'text',
							},
							label: this.$t('user.contactInfo.companyNumber'),
							modelKey: 'companyNumber',
							required: this.companyNumberRequired,
							onValueChanged: () => this.validate('companyNumber'),
						},
					],
				},
			];
		},
		contactPersonForm() {
			return {
				model: this.contactPersonFormModel,
				fieldGroups: this.contactPersonFieldGroups,
				modelUpdateHandler: this.updateContactPersonFormModel,
				form: this.contactPersonFormModel,
				fieldErrors: this.contactPersonFormFieldErrors,
			};
		},
		languageOptions() {
			return this.languages.map(lang => ({
				value: lang.id,
				name: lang.name,
			}));
		},
	},
	methods: {
		validate(prop) {
			// If the input field has an error, and the value changes, re-validate the form
			if (this.contactPersonFormFieldErrors[prop]) {
				// Validate the form after the values are set
				setTimeout(() => this.validateContactPersonFormModel(this.contactPersonFormModel));
			}
		},
		updateContactPersonFormModel(newModel) {
			this.contactPersonFormModel = {
				...this.contactPersonFormModel,
				...newModel,
			};
		},
		clearContactPersonFormFieldErrors() {
			this.contactPersonFormFieldErrors = {
				...contactPersonModel,
			};
		},
		clearContactPersonForm() {
			this.contactPersonFormModel = {
				...contactPersonModel,
			};
		},
		validateContactPersonFormModel(model) {
			this.clearContactPersonFormFieldErrors();

			let valid = true;

			if (!model.firstName) {
				this.contactPersonFormFieldErrors.firstName = this.$t('error.required', [this.$t('user.contactInfo.firstName')]);
				valid = false;
			}

			if (!model.lastName) {
				this.contactPersonFormFieldErrors.lastName = this.$t('error.required', [this.$t('user.contactInfo.lastName')]);
				valid = false;
			}

			if (!model.email) {
				this.contactPersonFormFieldErrors.email = this.$t('error.required', [this.$t('user.contactInfo.email')]);
				valid = false;
			} else if (!validators.email(model.email)) {
				this.contactPersonFormFieldErrors.email = this.$t('error.invalid', [this.$t('user.contactInfo.email')]);
				valid = false;
			}

			if (!model.phone) {
				this.contactPersonFormFieldErrors.phone = this.$t('error.required', [this.$t('user.contactInfo.phone')]);
				valid = false;
			}

			if (!model.language) {
				this.contactPersonFormFieldErrors.language = this.$t('error.required', [this.$t('user.contactInfo.language')]);
				valid = false;
			}

			const companyNumberRegex = new RegExp(this.companyNumberFormat);

			if (this.companyNumberRequired && !model.companyNumber) {
				this.contactPersonFormFieldErrors.companyNumber = this.$t('error.required', [this.$t('user.contactInfo.companyNumber')]);
				valid = false;
			} else if (model.companyNumber && !companyNumberRegex.test(model.companyNumber)) {
				this.contactPersonFormFieldErrors.companyNumber = this.$t('error.invalid', [this.$t('user.contactInfo.companyNumber')]);
				valid = false;
			}

			return valid;
		},
	},
};

export default contactPersonFormMixin;
