import apiService from '@/services/api';
import logger from '@/services/logger';
import { hasData } from '@/utils';
import RecursiveError from '@/services/recursiveError';

export const setBattery = (batteryInfo, locationId) =>
	new Promise((resolve, reject) => {
		apiService
			.post(`/locations/${locationId}/batteries`, batteryInfo)
			.then(hasData)
			.then(data => {
				resolve(data.id);
			})
			.catch(err => {
				logger.error('Failed to send battery to server', err);
				reject(err);
			});
	});

export const setCollectionOrder = (locationId, orderData) =>
	new Promise((resolve, reject) => {
		apiService
			.post(`/locations/${locationId}/collectionorders`, orderData)
			.then(response => {
				if (response && response.data) {
					resolve(response.data);
				} else {
					reject(new RecursiveError('Response of create collection order call was empty'));
				}
			})
			.catch(err => {
				reject(new RecursiveError('Failed to plan new transport', err, { orderData }));
			});
	});

export const setPackaging = (packagingData, locationId) =>
	new Promise((resolve, reject) => {
		apiService
			.post(`/locations/${locationId}/packaging`, packagingData)
			.then(hasData)
			.then(data => {
				resolve(data.id);
			})
			.catch(err => {
				logger.error('Failed to send packaging to server', err);
				reject(err);
			});
	});

export const deleteBattery = batteryId =>
	new Promise((resolve, reject) => {
		apiService
			.delete(`/batteries/${batteryId}`)
			.then(hasData)
			.then(data => {
				resolve(data);
			})
			.catch(err => {
				logger.error('Failed to send battery to server', err);
				reject(err);
			});
	});

export const patchBattery = (batteryId, batteryInfo) =>
	new Promise((resolve, reject) => {
		apiService
			.patch(`/batteries/${batteryId}`, batteryInfo)
			.then(hasData)
			.then(data => {
				resolve(data);
			})
			.catch(err => {
				logger.error('Failed to send battery to server', err);
				reject(err);
			});
	});
