const TagColors = Object.freeze({
	created: 'orange',
	planned: 'blue',
	executed: 'green',
	executedwithremarks: 'red',
	cancelled: 'red',
	blocked: 'red',
	active: 'green',
	notActive: 'orange',
});

export { TagColors };
