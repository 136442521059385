const RecursiveError = require('./recursiveError').default;

function log(level, messageOrError) {
	if (process.env.NODE_ENV !== 'production') {
		if (typeof messageOrError === 'string') {
			// eslint-disable-next-line no-console
			console[level](messageOrError);
		} else {
			// eslint-disable-next-line no-console
			console[level](JSON.stringify(messageOrError, null, 2));
		}
	}
}

function info(message) {
	log('info', message);
}

function debug(message) {
	log('debug', message);
}

function warn(message, err, additionalInfo) {
	log('warn', new RecursiveError(message, err, additionalInfo));
}

function error(message, err, additionalInfo) {
	log('error', new RecursiveError(message, err, additionalInfo));
}

function fatal(message, err, additionalInfo) {
	log('fatal', new RecursiveError(message, err, additionalInfo));
}

export default {
	info,
	debug,
	warn,
	error,
	fatal,
};
