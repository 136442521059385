/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'file-add': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<path pid="0" d="M14.5 2h-8c-1.1 0-1.99.9-1.99 2L4.5 20c0 1.1.89 2 1.99 2H18.5c1.1 0 2-.9 2-2V8l-6-6zm4 18h-12V4h7v5h5v11zm-10-4.99l1.41 1.41 1.59-1.58V19h2v-4.16l1.59 1.59 1.41-1.42L12.51 11 8.5 15.01z" _fill="#6B6D6D"/>'
  }
})
