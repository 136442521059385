var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-cp-update-collection"},[_c('rn-header',{attrs:{"title":_vm.$t('cp.scheduleUpdateCollection'),"size":"small","center":""}},[_c('router-link',{staticClass:"a-link a-link--back",attrs:{"slot":"action","to":{ name: 'Dashboard' }},slot:"action"},[_c('rn-icon',{attrs:{"width":"8px","height":"8px","name":"chevron","direction":"left"}}),_c('span',[_vm._v(_vm._s(_vm.$t('common.cancelAndReturn')))])],1)],1),_c('div',{staticClass:"o-section o-section--grey"},[_c('div',{staticClass:"u-container"},[_c('div',{staticClass:"o-section__content"},[(_vm.collectionOrdersState === 'loading')?_c('rn-spinner'):_vm._e(),(_vm.collectionOrdersState === 'noResults')?_c('div',{staticClass:"a-no-results"},[_vm._v(" "+_vm._s(_vm.$t('common.noResults'))+" ")]):_vm._e(),(_vm.collectionOrdersState === 'results')?_c('div',{staticClass:"o-overview u-grid"},[_c('div',{staticClass:"o-overview__section u-grid__12-12"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('cp.newCollection.selectDateAndDestination')))])]),_c('div',{staticClass:"u-grid__4-12"},[_c('rn-field',{attrs:{"input-props":{
								id: 'update-collection-date',
								type: 'datetime-local',
								format: 'date',
							},"label":_vm.$t('cp.newCollection.date')},on:{"update:value":function (value) { return _vm.setFormValue('date', value); }}})],1),_c('div',{staticClass:"u-grid__4-12"},[_c('rn-field',{attrs:{"input-props":{
								id: 'update-collection-dest',
								options: _vm.destinationLocations,
								type: 'select',
								placeholder: _vm.$t('cp.newCollection.destination.placeholder'),
							},"required":true,"error":_vm.state.destinationError,"label":_vm.$t('common.destination'),"value":_vm.state.destination},on:{"update:value":function($event){return _vm.$set(_vm.state, "destination", $event)}}})],1),_c('div',{staticClass:"o-overview__section u-grid__12-12"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('common.generalInformation')))]),_c('rn-card-list',{attrs:{"card-data":_vm.overviewCardList}})],1),_c('div',{staticClass:"o-overview__section u-grid__12-12"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('common.packagingMethod')))]),_c('rn-card-list',{attrs:{"card-data":_vm.transportPackagingCardList}})],1),_c('div',{staticClass:"o-overview__section u-grid__12-12"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('cp.newCollection.orderDetails')))]),(_vm.collectionOrder.batteries && _vm.collectionOrder.batteries.length)?_c('rn-new-table',{attrs:{"headers":_vm.existingBatteryTableHeaders,"data":_vm.collectionOrder.batteries}}):_vm._e()],1),_c('div',{staticClass:"o-overview__section u-grid__12-12"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('stepForm.overview.comments')))]),_c('p',[_vm._v(" "+_vm._s(_vm.collectionOrder.sourceRemarks || _vm.$t('common.noComments'))+" ")])]),_c('div',{staticClass:"m-form__footer u-grid__12-12"},[_c('rn-button',{attrs:{"icon":{ name: 'check', pos: 'left', width: '16px', height: '16px' },"method":_vm.scheduleCollection,"text":_vm.state.saveInProgress ? _vm.$t('common.saving') : _vm.$t('common.scheduleCollection')}})],1)]):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }