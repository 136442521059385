import apiService from '@/services/api';
import logger from '@/services/logger';
import { hasData } from '@/utils';

export const setPackaging = (packagingData, locationId) =>
	new Promise((resolve, reject) => {
		apiService
			.post(`/locations/${locationId}/packaging`, packagingData)
			.then(hasData)
			.then(data => {
				resolve(data.id);
			})
			.catch(err => {
				logger.error('Failed to send packaging to server', err);
				reject(err);
			});
	});
