import { mapGetters } from 'vuex';
import Vue from 'vue';

import { RnButton, RnField, RnForm, RnSpinner } from '@/components';
import { loginFormMixin, passwordResetRequestFormMixin } from '@/mixins';

export default {
	name: 'rn-login-view',
	components: {
		RnButton,
		RnField,
		RnForm,
		RnSpinner,
	},
	mixins: [loginFormMixin, passwordResetRequestFormMixin],
	data() {
		return {
			currentView: 'login',
		};
	},
	computed: {
		...mapGetters(['user']),
	},
	watch: {
		user(value) {
			if (value) {
				Vue.nextTick(() => {
					this.$router.push({
						name: 'Dashboard',
						params: this.$router.history.current.params,
					});
				});
			}
		},
	},
	methods: {
		changeView(name) {
			this.currentView = name;
		},
	},
};
