import Vue from 'vue';
import config from '@/config';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export default new VueI18n({
	locale: config.defaultLanguage,
	fallbackLocale: 'en',
	messages: {
		en: { ...require('@/assets/translations/en.json') },
		nl: { ...require('@/assets/translations/nl.json') },
		de: { ...require('@/assets/translations/de.json') },
		fr: { ...require('@/assets/translations/fr.json') },
		es: { ...require('@/assets/translations/es.json') },
		it: { ...require('@/assets/translations/it.json') },
		cs: { ...require('@/assets/translations/cs.json') },
		el: { ...require('@/assets/translations/el.json') },
		pl: { ...require('@/assets/translations/pl.json') },
		pt: { ...require('@/assets/translations/pt.json') },
		ro: { ...require('@/assets/translations/ro.json') },
	},
});
