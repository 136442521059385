import RnIcon from '../../icon/icon.vue';
import { CardClass } from './card.enums';

export default {
	name: 'rn-card',
	components: {
		RnIcon,
	},
	props: {
		card: {
			type: Object,
			default: () => ({}),
		},
		removeable: {
			type: Boolean,
			default: false,
		},
		removeHandler: {
			type: Function,
			default: null,
		},
	},
	computed: {
		cardClasses() {
			return {
				[CardClass.Base]: true,
				[CardClass.Removeable]: this.removeable,
			};
		},
	},
};
