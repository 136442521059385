import get from 'lodash/get';

import RnIcon from '@/components/icon/icon.vue';
import { fieldMixin } from '@/mixins';
import { ToggleClasses, ToggleTypes, ToggleTypeClasses } from '../../field.enums';

export default {
	name: 'cs-field-toggle',
	components: { RnIcon },
	mixins: [fieldMixin],
	data() {
		return {
			toggles: {
				[ToggleTypes.Checkbox]: ToggleClasses.Checkbox,
				[ToggleTypes.Radio]: ToggleClasses.Radio,
				[ToggleTypes.RadioIcon]: ToggleClasses.RadioIcon,
			},
			toggleTypes: {
				[ToggleTypes.Checkbox]: ToggleTypeClasses.Checkbox,
				[ToggleTypes.Radio]: ToggleTypeClasses.Radio,
				[ToggleTypes.RadioIcon]: ToggleTypeClasses.RadioIcon,
			},
		};
	},
	computed: {
		isRadioIcon() {
			if (this.type === ToggleTypes.Radio && this.options && this.options.length) {
				return !!this.options.find(option => option.icon);
			}

			return false;
		},
		toggleClasses() {
			return [ToggleClasses.Base, this.toggles[this.toggleType]];
		},
		toggleType() {
			let toggleType = ToggleTypes.Checkbox;

			if (this.type !== toggleType) {
				toggleType = this.isRadioIcon ? ToggleTypes.RadioIcon : ToggleTypes.Radio;
			}

			return toggleType;
		},
		toggleTypeClass() {
			return this.toggleTypes[this.toggleType];
		},
	},
	methods: {
		getCheckedValue(option) {
			const checked = get(option, 'checked', null);

			if (checked !== null) {
				return checked === option.value;
			}

			return option.value === this.value;
		},
	},
};
