import { ITEMS_PER_PAGE } from '@/services/constants';
import { RnNewTable, RnActionHeader, RnButton, RnSearch } from '@/components';
import RnPagination from '@/components/pagination/pagination.vue';
import { formatAsDate } from '@/utils/format-as-date';
import { mapGetters } from 'vuex';

export default {
	name: 'rn-mf-contracts-view',
	components: {
		RnNewTable,
		RnActionHeader,
		RnButton,
		RnPagination,
		RnSearch,
	},
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.DEFAULT,
			page: 0,
		};
	},
	computed: {
		...mapGetters(['businessRelationId']),
		/**
		 * example contract structure: See bottom of file
		 */
		contracts() {
			return (this.$store.getters.getContracts(this.businessRelationId) || []).slice(this.page * ITEMS_PER_PAGE, (this.page + 1) * ITEMS_PER_PAGE);
		},
		totalNumberOfContracts() {
			return (this.$store.getters.getContracts(this.businessRelationId) || []).length;
		},
		contractTableHeaders: {
			get() {
				return [
					{
						text: this.$t('contracts.overview.name'),
						value: 'contract',
					},
					{
						text: this.$t('contracts.overview.spoc'),
						value: 'singlePointOfContact.name',
					},
					{
						text: this.$t('contracts.overview.startDate'),
						value: 'startDate',
						display: formatAsDate,
						compact: true,
					},
					{
						text: this.$t('contracts.overview.endDate'),
						value: 'endDate',
						display: formatAsDate,
						compact: true,
					},
				];
			},
		},
	},
	created() {
		this.$store.dispatch('getContracts', { businessRelationId: this.businessRelationId });
	},
	methods: {
		setPage(page) {
			this.page = page;
		},
	},
};

// [
// 	{
// 		"contract": "string",
// 		"singlePointOfContact": {
// 			"id": "00000000-0000-0000-0000-000000000000",
// 			"name": "string"
// 		},
// 		"brand": "string",
// 		"startDate": "2020-10-08T07:41:40.698Z",
// 		"endDate": "2020-10-08T07:41:40.698Z",
// 		"contractCosts": [
// 			{
// 				"category": "string",
// 				"costType_Name": "string",
// 				"unitOfCostType_Name": "string",
// 				"unit_Name": "string",
// 				"completionType_Name": "string",
// 				"chemicalFamilyType_Name": "string",
// 				"amount": 0,
// 				"price": 0,
// 				"remarks": "string"
// 			}
// 		],
// 		"subcontracts": [
// 			{
// 				"subcontract": "string",
// 				"country": "string",
// 				"zipcodeStart": 0,
// 				"zipcodeStop": 0,
// 				"collectionOrganisation": {
// 					"id": "00000000-0000-0000-0000-000000000000",
// 					"name": "string"
// 				},
// 				"brandReceptacle_Ids": [
// 					"00000000-0000-0000-0000-000000000000"
// 				],
// 				"subcontractCosts": [
// 					{
// 						"category": "string",
// 						"costType_Name": "string",
// 						"unitOfCostType_Name": "string",
// 						"unit_Name": "string",
// 						"completionType_Name": "string",
// 						"chemicalFamilyType_Name": "string",
// 						"amount": 0,
// 						"price": 0,
// 						"remarks": "string"
// 					}
// 				]
// 			}
// 		]
// 	}
// ]
