import get from 'lodash/get';
import set from 'lodash/set';
import flatten from 'lodash/flatten';
import { getInvoices } from '@/services/invoices';
import { resetState } from '../../../utils/reset-state';

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	invoices: [],
	invoicesCount: 0,
	pickupPointInvoices: [],
	pickupPointInvoicesCount: 0,
	collectionPartnerInvoices: [],
	collectionPartnerInvoicesCount: 0,
	brandInvoices: [],
	brandInvoicesCount: 0,
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	invoices: state => state.invoices,
	invoicesCount: state => state.invoicesCount || 0,
	pickupPointInvoices: state => state.pickupPointInvoices,
	pickupPointInvoicesCount: state => state.pickupPointInvoicesCount || 0,
	collectionPartnerInvoices: state => state.collectionPartnerInvoices,
	collectionPartnerInvoicesCount: state => state.collectionPartnerInvoicesCount || 0,
	brandInvoices: state => state.brandInvoices,
	brandInvoicesCount: state => state.brandInvoicesCount || 0,
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	getAllInvoices({ commit }, { businessRelationId, page, pageSize, date, search }) {
		getInvoices(commit, businessRelationId, page, pageSize, date, search).then(response => {
			commit('setInvoices', {
				items: flatten(get(response, 'data.items', [])),
				storePath: 'invoices',
			});
			commit('setInvoicesCount', {
				count: get(response, 'data.totalItems', 0),
				storePath: 'invoicesCount',
			});
		});
	},
	getPickupPointInvoices({ commit }, { businessRelationId, page, pageSize, date, search, role }) {
		getInvoices(commit, businessRelationId, page, pageSize, date, search, role).then(response => {
			commit('setPickupPointInvoices', {
				items: flatten(get(response, 'data.items', [])),
				storePath: 'pickupPointInvoices',
			});
			commit('setPickupPointInvoicesCount', {
				count: get(response, 'data.totalItems', 0),
				storePath: 'pickupPointInvoicesCount',
			});
		});
	},
	getCollectionPartnerInvoices({ commit }, { businessRelationId, page, pageSize, date, search, role }) {
		getInvoices(commit, businessRelationId, page, pageSize, date, search, role).then(response => {
			commit('setCollectionPartnerInvoices', {
				items: flatten(get(response, 'data.items', [])),
				storePath: 'collectionPartnerInvoices',
			});
			commit('setCollectionPartnerInvoicesCount', {
				count: get(response, 'data.totalItems', 0),
				storePath: 'collectionPartnerInvoicesCount',
			});
		});
	},
	getBrandInvoices({ commit }, { businessRelationId, page, pageSize, date, search, role }) {
		getInvoices(commit, businessRelationId, page, pageSize, date, search, role).then(response => {
			commit('setBrandInvoices', {
				items: flatten(get(response, 'data.items', [])),
				storePath: 'brandInvoices',
			});
			commit('setBrandInvoicesCount', {
				count: get(response, 'data.totalItems', 0),
				storePath: 'brandInvoicesCount',
			});
		});
	},
	resetSpocInvoices({ commit }) {
		commit('resetSpocInvoices');
	},
};

const mutations = {
	setInvoices(state, { items, storePath }) {
		set(state, storePath, items);
	},
	setInvoicesCount(state, { count, storePath }) {
		set(state, storePath, count);
	},
	setPickupPointInvoices(state, { items, storePath }) {
		set(state, storePath, items);
	},
	setPickupPointInvoicesCount(state, { count, storePath }) {
		set(state, storePath, count);
	},
	setCollectionPartnerInvoices(state, { items, storePath }) {
		set(state, storePath, items);
	},
	setCollectionPartnerInvoicesCount(state, { count, storePath }) {
		set(state, storePath, count);
	},
	setBrandInvoices(state, { items, storePath }) {
		set(state, storePath, items);
	},
	setBrandInvoicesCount(state, { count, storePath }) {
		set(state, storePath, count);
	},
	resetSpocInvoices(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
