/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'invoice': {
    width: 16,
    height: 16,
    viewBox: '0 0 137.655 125',
    data: '<g _fill="#f86439"><path pid="0" d="M88.667 30.49h-46.15c-8.181 0-14.59 10.261-14.59 23.36v1.55H54.007v46.873l7.318-3.806 5.769 3 5.769-3 5.769 3 5.77-3 5.769 3 5.769-3 7.318 3.806V53.85c-.001-13.099-6.409-23.36-14.591-23.36zM31.062 52.3c.466-10.292 5.522-18.71 11.455-18.71s10.989 8.419 11.455 18.71h-22.91zm69.095 44.867l-4.219-2.194-5.769 3-5.769-3-5.77 3-5.769-3-5.769 3-5.769-3-4.219 2.194V53.85c0-8.77-2.875-16.262-7.229-20.26h38.789c6.229 0 11.49 9.278 11.49 20.26v43.317z"/><path pid="1" d="M80.077 39.667a.75.75 0 0 0-.75-.749h-.001l-21.618.022a.749.749 0 1 0 .001 1.5h.001l21.619-.022a.75.75 0 0 0 .748-.751zM86.468 40.438h.003l5.757-.019a.75.75 0 0 0-.003-1.5h-.003l-5.757.019a.75.75 0 0 0 .003 1.5zM82.449 46.407a.75.75 0 0 0-.75-.749h-.001l-21.619.022a.749.749 0 1 0 .001 1.5h.001l21.619-.022a.749.749 0 0 0 .749-.751zM93.271 81.961l-21.62.022a.75.75 0 0 0 .001 1.5h.001l21.619-.022a.75.75 0 0 0-.001-1.5zM93.187 59.162l-30.528.032a.749.749 0 1 0 .001 1.5h.001l30.527-.032a.75.75 0 0 0-.001-1.5zM93.187 66.011l-30.528.032a.75.75 0 0 0 .001 1.5h.001l30.527-.032a.75.75 0 0 0-.001-1.5zM88.089 46.431a.75.75 0 0 0 .75.748h.002l5.758-.019a.75.75 0 0 0-.002-1.5h-.002l-5.758.019a.75.75 0 0 0-.748.752zM67.955 81.962h-.002l-5.758.02a.75.75 0 0 0 .002 1.5h.002l5.758-.02a.75.75 0 0 0-.002-1.5zM93.271 88.794l-21.62.022a.75.75 0 0 0 .001 1.5h.001l21.619-.022a.75.75 0 0 0-.001-1.5zM67.955 88.796h-.002l-5.758.019a.75.75 0 0 0 .002 1.5h.002l5.758-.019a.75.75 0 0 0-.002-1.5z"/></g>'
  }
})
