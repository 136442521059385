export const mapNamesToLabels = (name, t) => {
	switch (name) {
		case 'Name':
			return t('user.addressInfo.companyName');
		case 'Street':
			return t('user.addressInfo.streetName');
		case 'Number':
			return t('user.addressInfo.streetNumber');
		case 'Zipcode':
			return t('user.addressInfo.zipCode');
		case 'City':
			return t('user.addressInfo.city');
		case 'Country':
			return t('user.addressInfo.country');
		case 'CollectedBy':
			return t('common.collectedBy');
		case 'PickupPoint':
			return t('table.invoice.pickup');
		case 'SerialNumber':
			return t('addBattery.serialNumber');
		case 'Year':
			return t('addBattery.year');
		case 'Status':
			return t('addBattery.status');
		case 'BatteryStatus':
			return t('addBattery.batteryStatus');
		case 'PlannedCollectionDate':
			return t('table.order.plannedPickUpDate');
		case 'CollectionDate':
			return t('table.order.pickupDate');
		case 'number':
			return t('table.order.number');
		default:
			return name;
	}
};

const mapStatusNamesToLabels = (name, t) => {
	switch (name) {
		case 'Active':
			return t('table.pup.status.active');
		case 'notActive':
			return t('table.pup.status.notActive');
		case 'Created':
			return t('table.order.status.Created');
		case 'Planned':
			return t('table.order.status.Planned');
		case 'Executed':
			return t('table.order.status.Executed');
		case 'ExecutedWithRemarks':
			return t('table.order.status.ExecutedWithRemarks');
		case 'Cancelled':
			return t('table.order.status.Cancelled');
		default:
			return name;
	}
};

export const mapBatteryStatusNamesToLabels = (name, t) => {
	switch (name) {
		case 'AtStockLocation':
			return t('batteryStatuses.AtStockLocation');
		case 'Created':
			return t('batteryStatuses.Created');
		case 'Missing':
			return t('batteryStatuses.Missing');
		case 'PickUpPlanned':
			return t('batteryStatuses.PickUpPlanned');
		case 'Processed':
			return t('batteryStatuses.Processed');
		case 'ReadyForPickUp':
			return t('batteryStatuses.ReadyForPickUp');
		default:
			return name;
	}
};

export const mapOptionNamesToLabels = (name, t, filterName) => {
	if (filterName === 'Status') {
		return mapStatusNamesToLabels(name, t);
	}

	if (filterName === 'BatteryStatus') {
		return mapBatteryStatusNamesToLabels(name, t);
	}
};
