import { mapGetters } from 'vuex';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';

import {
	RnButton,
	RnField,
	RnIcon,
	RnForm,
	RnHeader,
	RnNewTable,
	RnCardList,
	RnDock,
	RnSidebar,
	RnStepForm,
	RnToggleButton,
	RnSpinner,
	RnTag,
	RnEditBatterySidebar,
} from '@/components';
import { IconDirections } from '@/components/icon/icon.enums';
import { MessageTypes } from '@/enums/message-types';
import { Roles } from '@/enums/roles';
import apiService from '@/services/api';
import { batteriesMixin, transportPackagingFormMixin, editBatteryFormMixin } from '@/mixins';

import { ITEMS_PER_PAGE } from '@/services/constants';
import { logAndToastError } from '@/utils';
import RnPagination from '@/components/pagination/pagination.vue';
import { packagingService } from '@/services/packagings';
import { formatWeight } from '@/utils/units';
import { getPatchItem } from './new-transport/new-transport.helper';

export default {
	name: 'rn-cp-new-transport-view',
	components: {
		RnPagination,
		RnButton,
		RnField,
		RnIcon,
		RnForm,
		RnHeader,
		RnNewTable,
		RnCardList,
		RnDock,
		RnSidebar,
		RnStepForm,
		RnToggleButton,
		RnSpinner,
		RnTag,
		RnEditBatterySidebar,
	},
	mixins: [batteriesMixin, transportPackagingFormMixin, editBatteryFormMixin],
	data() {
		return {
			page: 0,
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.DEFAULT,
			dock: {
				button: {
					color: 'blue',
					outline: true,
					size: 'small',
					icon: {
						name: 'arrow',
						width: '16px',
						height: '16px',
						direction: IconDirections.Right,
						pos: 'right',
					},
					method: () => this.$refs.stepForm.nextStep(),
					text: this.$t('stepForm.steps.selectPackaging'),
				},
				tableData: [],
				open: false,
				expanded: false,
			},
			sidebarOpen: false,
			editSidebarOpen: false,
			state: {
				currentStep: 1,
				currentEditBatteryStep: 1,
				toEditBattery: {},
				isEditing: false,
				selectedPackaging: [],
				selectedBatteries: [],
				date: null,
				comments: null,
				location: null,
				brand: null,
				editableSelectedTransportPackagingCardList: [],
				selectedTransportPackagingCardList: [],
			},
		};
	},
	computed: {
		...mapGetters([
			'businessRoles',
			'locationId',
			'location',
			'locations',
			'locationsByRoles',
			'checkedBatteries',
			'checkedBatteriesCount',
			'businessRelationId',
			'chemicalFamilies',
			'batteryConditions',
			'businessRelationId',
		]),
		steps() {
			const stepOneValid = !!this.state.selectedBatteries.length;
			const stepTwoValid = !!this.state.selectedPackaging.length;
			const stepThreeValid = stepOneValid && stepTwoValid && !!this.state.destinationLocationId;

			return [
				{
					text: this.$t('stepForm.steps.selectBatteries'),
					form: this.editdBatteryForm,
					valid: () => {
						const battery = this.state.selectedBatteries[0];

						if (!battery) {
							this.$store.dispatch('setMessage', {
								text: 'cp.newTransport.batterySelection.warning',
								type: MessageTypes.WARNING,
							});

							return;
						}

						// Get transport packaging for the brand of the first selected battery
						// all selected batteries have to be the same brand anyways
						this.$store.dispatch('getTransportPackagings', {
							businessRelationId: this.businessRelationId,
							brandId: this.selectedBrandId,
						});

						this.updateTransportPackagingFormModel({
							id: null,
							transportPackaging: null,
						});
						// Get location address for selected batteries
						const locationId = get(battery, 'location.id');
						if (locationId) {
							this.$store.dispatch('getLocation', { locationId });
						}

						return stepOneValid;
					},
				},
				{
					text: this.$t('stepForm.steps.selectPackaging'),
					valid: stepTwoValid,
				},
				{
					text: this.$t('stepForm.steps.checkAndRequest'),
					valid: stepThreeValid,
					action: {
						icon: {
							name: 'plus',
							pos: 'right',
							width: '16px',
							height: '16px',
						},
						color: 'blue',
						method: this.assembleTransport,
						text: this.$t('common.planNewTransport'),
					},
				},
			];
		},
		summary() {
			const getNettoWeight = battery => {
				return battery.nettoWeight || get(battery, 'batteryDescription.nettoWeight', 0);
			};
			const getPackagingWeight = battery => {
				if (battery.brutoWeight) {
					return battery.brutoWeight - getNettoWeight(battery);
				}
				return get(battery, 'batteryPackaging.weight', 0);
			};
			const getBrutoWeight = battery => {
				if (battery.brutoWeight) {
					return battery.brutoWeight;
				}
				return getNettoWeight(battery) + getPackagingWeight(battery);
			};

			const nettoWeight = this.state.selectedBatteries.reduce((acc, battery) => {
				return acc + getNettoWeight(battery);
			}, 0);

			const totalBatteryPackagingWeight = this.state.selectedBatteries.reduce((acc, battery) => {
				return acc + getPackagingWeight(battery);
			}, 0);

			const brandId = get(this.state, 'selectedBatteries[0].batteryDescription.brand.id');
			const transportPackagings = brandId ? this.$store.getters.getTransportPackagings(this.businessRelationId, brandId) : [];

			const totalTransportPackagingWeight = (this.state.selectedPackaging || []).reduce((acc, packaging) => {
				const transportPackagingId = packaging.id || packaging.receptacleId;
				const transportPackaging = transportPackagings.find(packagingInfo => packagingInfo.id === transportPackagingId);

				return acc + get(transportPackaging, 'weight', 0);
			}, 0);

			const totalWeight = this.state.selectedBatteries.reduce((acc, battery) => {
				return acc + getBrutoWeight(battery);
			}, 0);

			return [
				{
					nettoWeight: formatWeight(nettoWeight),
					totalBatteryPackagingWeight: formatWeight(totalBatteryPackagingWeight),
					totalTransportPackagingWeight: formatWeight(totalTransportPackagingWeight),
					totalWeight: formatWeight(totalWeight),
				},
			];
		},
		activeForm() {
			const activeIndex = Math.max(0, this.state.currentStep - 1);

			return this.steps[activeIndex].form || {};
		},
		batteriesLoadingState() {
			if (!this.checkedBatteries) {
				return 'loading';
			}
			if (this.checkedBatteries.length) {
				return 'results';
			}

			return 'noResults';
		},
		batteriesToSelect() {
			return (this.checkedBatteries || []).map(battery => {
				this.$set(
					battery,
					'selected',
					this.state.selectedBatteries.find(selectedBattery => selectedBattery.id === battery.id)
				);

				return battery;
			});
		},
		lastSelectedBattery() {
			return this.state.selectedBatteries[0] || null;
		},
		defaultBatteryTableHeaders() {
			return [this.brandBatteryTableHeader, ...this.editExistingBatteryTableHeaders];
		},
		selectBatteryTableHeaders() {
			return [
				...this.defaultBatteryTableHeaders,
				{
					text: '',
					value: 'selected',
					component: RnToggleButton,
					componentProps: value => {
						const isSelected = !!value.selected;

						return {
							active: isSelected,
							color: isSelected ? 'orange' : 'grey',
							icon: {
								name: isSelected ? 'check' : 'plus',
								width: '14px',
								height: '14px',
							},
							toggleColor: 'grey',
							toggleIcon: {
								name: 'cross',
								width: '14px',
								height: '14px',
							},
							method: () => {
								this.handleRowPress(value);
							},
						};
					},
				},
			];
		},
		removeBatteryTableHeaders() {
			return [
				...this.defaultBatteryTableHeaders,
				{
					text: '',
					value: 'selected',
					component: RnButton,
					componentProps: value => {
						return {
							icon: {
								name: 'cross',
								width: '14px',
								height: '14px',
							},
							color: 'grey',
							method: () => {
								this.removeBattery(value);
							},
							round: true,
						};
					},
				},
			];
		},
		overviewBatteryTableHeaders() {
			return [
				...this.defaultBatteryTableHeaders,
				{
					compact: true,
					text: '',
					component: RnButton,
					componentProps: value => {
						return {
							icon: {
								name: 'cross',
								width: '14px',
								height: '14px',
							},
							color: 'grey',
							method: () => {
								this.removeBattery(value);
							},
							round: true,
						};
					},
				},
			];
		},
		summaryTableHeaders() {
			return [
				{
					text: this.$t('stepForm.overview.batteries.nettoWeight'),
					value: 'nettoWeight',
				},
				{
					text: this.$t('stepForm.overview.batteries.totalBatteryPackagingWeight'),
					value: 'totalBatteryPackagingWeight',
				},
				{
					text: this.$t('stepForm.overview.batteries.totalTransportPackagingWeight'),
					value: 'totalTransportPackagingWeight',
				},
				{
					text: this.$t('stepForm.overview.batteries.totalWeight'),
					value: 'totalWeight',
				},
			];
		},
		dockOpen() {
			const dockIsOpen = this.state.currentStep === 1 && this.dock.open && !!this.state.selectedBatteries.length;

			if (!dockIsOpen) {
				this.updateDock(false);
			}

			return dockIsOpen;
		},
		toggleDockLabel() {
			if (this.dock.expanded) {
				return this.$t('cp.newTransport.hideAllBatteries');
			}
			return this.$t('cp.newTransport.showAllBatteries');
		},
		fromLocation() {
			if (!this.state.selectedBatteries[0]) {
				return '';
			}

			const location = this.$store.getters.locationsLocation(this.state.selectedBatteries[0].location.id);

			if (!location || !location.addresses || !location.addresses.length) {
				return '';
			}

			const fromLocation = {
				name: location.name,
				...location.addresses[0],
			};

			return this.formatAddress(fromLocation);
		},
		destinationLocations() {
			return this.locationsByRoles.map(location => ({
				name: location.name,
				value: location.id,
			}));
		},
		selectableLocations() {
			return uniqBy(
				(this.batteries || []).map(battery => battery.location),
				'id'
			);
		},
		selectableBrands() {
			return uniqBy(
				(this.batteries || []).map(battery => ({
					name: battery.batteryDescription.brand.name,
					value: battery.batteryDescription.brand.id,
				})),
				'value'
			);
		},
	},
	watch: {
		businessRoles: {
			immediate: true,
			handler(value) {
				if (value && value.length) {
					const roles = [Roles.RC, Roles.SD];
					const roleIds = this.$store.getters.businessRoleIds(roles);

					this.$store.dispatch('getLocationsByRoles', { roleIds, businessRelationId: this.businessRelationId });
				}
			},
		},
	},
	created() {
		this.updateBatteries(0);
	},
	methods: {
		handleRowPress(battery) {
			if (!battery) {
				return;
			}

			if (battery.selected) {
				this.removeBattery(battery.id);

				return;
			}

			this.addBattery(battery);
		},
		setFormValue(prop, value) {
			this.state[prop] = value;
			if (this.state.destinationError) {
				this.validateForm();
			}
		},
		validateForm() {
			this.state.destinationError = this.state.destination ? null : this.$t('error.required', [this.$t('common.destination')]);

			return this.state.destination;
		},
		editBattery(battery) {
			this.toggleEditSidebar(true);

			this.state.isEditing = true;
			this.state.toEditBattery = battery;
		},
		async saveBattery(battery, editBatteryFormModal) {
			const patchItems = await getPatchItem(battery, editBatteryFormModal, this.$store, this.$t);

			this.$store.commit('setUpdatedBattery', { ...battery, ...patchItems });
			this.editSidebarOpen = false;
		},
		getModelUpdater(model) {
			this.activeForm.modelEditHandler(model);
		},
		goBack() {
			this.$router.push({ name: 'Dashboard' });
		},
		editBatteryValidateAndGotoNextStep() {
			// Validate part of the editBattery model
			if (this.validateBatteryFormModel(this, this.batteryFormModel, this.state.currentEditBatteryStep, true, true)) {
				this.state.currentEditBatteryStep += 1;
				// Get batteryPackagings for the chosen brand, so they are ready when the user gets to step 3
				if (this.state.currentEditBatteryStep === 2) {
					// Get packaging methods for batteries in this location and this brand
					this.$store.dispatch('getTransportPackagings', {
						businessRelationId: this.businessRelationId,
						brandId: this.batteryFormModel.brand,
					});
				}
			}
		},
		updateBatteries(page) {
			this.page = page;
			this.$store.dispatch('getCheckedBatteries', {
				businessRelationId: this.businessRelationId,
				page: this.page,
				pageSize: ITEMS_PER_PAGE.DEFAULT,
			});
		},
		addBattery(battery) {
			if (this.state.selectedBatteries.length && battery.location.id !== this.state.selectedBatteries[0].location.id) {
				logAndToastError(this.$store.dispatch, this.$t('cp.newTransport.onlyOneLocation'));

				return;
			}

			if (!battery.selected) {
				battery.selected = true;

				this.state.selectedBatteries.push(battery);
				this.dock.open = true;

				if (!this.dock.expanded) {
					this.dock.tableData = [this.lastSelectedBattery];
				}
			}
		},
		removeBattery(batteryToRemove) {
			const batteryIndex = this.state.selectedBatteries.findIndex(battery => battery.id === batteryToRemove.id);

			this.state.selectedBatteries.splice(batteryIndex, 1);
			(this.checkedBatteries || []).forEach(battery => {
				if (battery.id === batteryToRemove.id) {
					battery.selected = false;
				}
			});
			this.updateDock(this.dock.expanded);
		},
		addTransportPackaging() {
			const { selectedPackaging } = this.state;
			const existingSelectedTransportPackaging = selectedPackaging.find(p => {
				return p.receptacleId === this.transportPackagingFormModel.receptacleId;
			});

			if (!this.validateTransportPackagingFormModel(this.transportPackagingFormModel)) {
				return;
			}

			// If the package is already in the list only update its amount
			if (existingSelectedTransportPackaging) {
				existingSelectedTransportPackaging.amount = this.transportPackagingFormModel.amount;
			} else {
				selectedPackaging.push({
					...this.transportPackagingFormModel,
				});
			}

			this.state.editableSelectedTransportPackagingCardList = this.getSelectedTransportPackagingCardList({
				editable: true,
				removeable: true,
			});
			this.state.selectedTransportPackagingCardList = this.getSelectedTransportPackagingCardList({
				editable: false,
				removeable: false,
			});
			this.resetAddPackagingFormModel();
			this.toggleSidebar(false);
		},
		formatAddress(location) {
			const joinStrArray = (strArray, separator) => strArray.filter(str => str).join(separator);

			return joinStrArray(
				[location.name, joinStrArray([location.street, location.number], ' '), joinStrArray([location.zipCode, location.city], ' ')],
				', '
			);
		},
		getSelectedTransportPackagingCardList(props = {}) {
			const brandId = get(this.state, 'selectedBatteries[0].batteryDescription.brand.id');

			if (!brandId) {
				return [];
			}

			const transportPackagings = this.$store.getters.getTransportPackagings(this.businessRelationId, brandId);

			if (!transportPackagings) {
				return [];
			}

			const packagingInfos = this.state.selectedPackaging.map(selectedPackaging => {
				return {
					...selectedPackaging,
					...(transportPackagings.find(tp => tp.id === selectedPackaging.receptacleId) || {}),
				};
			});

			const cardGridOptions = { grid: { cols: 4 }, ...props };

			return packagingService.packagingsToCardInfos(
				packagingInfos,
				transportPackagings,
				cardGridOptions,
				this.$t,
				this.removeTransportPackaging,
				this.updateAmount
			);
		},
		updateAmount(updatedAmount, tempId) {
			const toUpdatePackage = this.state.selectedPackaging.find(packaging => tempId === packaging.receptacleId);
			const toUpdatePackageIndex = this.state.selectedPackaging.indexOf(toUpdatePackage);

			toUpdatePackage.amount = updatedAmount;

			const newSelectedPackaging = [...this.state.selectedPackaging];
			newSelectedPackaging.splice(toUpdatePackageIndex, 1, toUpdatePackage);

			this.state.selectedPackaging = newSelectedPackaging;
			this.state.editableSelectedTransportPackagingCardList = this.getSelectedTransportPackagingCardList({
				editable: true,
				removeable: true,
			});
			this.state.selectedTransportPackagingCardList = this.getSelectedTransportPackagingCardList({
				editable: false,
				removeable: false,
			});
		},
		removeTransportPackaging({ tempId }) {
			const toRemovePackage = this.state.selectedPackaging.find(packaging => tempId === packaging.receptacleId);
			const toRemovePackageIndex = this.state.selectedPackaging.indexOf(toRemovePackage);

			const newSelectedPackaging = [...this.state.selectedPackaging];
			newSelectedPackaging.splice(toRemovePackageIndex, 1);

			this.state.selectedPackaging = newSelectedPackaging;
			this.state.editableSelectedTransportPackagingCardList = this.getSelectedTransportPackagingCardList({
				editable: true,
				removeable: true,
			});
			this.state.selectedTransportPackagingCardList = this.getSelectedTransportPackagingCardList({
				editable: false,
				removeable: false,
			});
		},
		async assembleTransport() {
			let url;
			let body;
			try {
				const transportPackagings = this.state.selectedPackaging.map(receptacle => {
					const recept = {
						...receptacle,
					};
					recept.id = recept.receptacleId;
					delete recept.receptacleId;

					return recept;
				});

				const batteryLocationId = get(this.state, 'selectedBatteries[0].location.id');
				if (!batteryLocationId) {
					logAndToastError(this.$store.dispatch, 'cp.newTransport.noLocationIdFound', null, {
						selectedBatteries: this.state.selectedBatteries,
					});
				}

				url = `/locations/${batteryLocationId}/collectionorders`;
				body = {
					transportPackagings,
					batteryIds: this.state.selectedBatteries.map(battery => battery.id),
					remarks: this.state.comments,
					destinationLocationId: this.state.destinationLocationId,
					plannedPickUpDate: this.state.date ? this.state.date : null,
				};

				const response = await apiService.post(url, body);

				if (response.data.success) {
					this.$store.dispatch('setMessage', {
						text: 'message.assembleTransport.success',
						type: MessageTypes.SUCCESS,
					});
					this.$router.push({ name: 'Dashboard' });
				}
			} catch (err) {
				logAndToastError(this.$store.dispatch, 'cp.newTransport.failedToPlanTransport', err, {
					url,
					body,
				});
				throw err;
			}
		},
		updateDock(expanded) {
			this.dock.expanded = expanded;

			if (this.state.selectedBatteries.length) {
				this.dock.tableData = expanded ? this.state.selectedBatteries : [this.lastSelectedBattery];
			}
		},
		toggleSidebar(openState) {
			this.sidebarOpen = openState;
		},
		toggleEditSidebar(openState) {
			this.editSidebarOpen = openState;
		},
		closeSidebar(model) {
			this.resetFormModel(model);
			this.toggleSidebar(false);
			this.state.isEditing = false;
		},
		onSidebarOpenOrClosed(isOpen) {
			if (!isOpen) {
				this.state.isEditing = false;
			}
		},
	},
};
