import { mapGetters } from 'vuex';

import { RnActionHeader, RnForm, RnButton, RnNotification, RnIcon, RnAlert } from '@/components';
import { contactInformationFormMixin, addressInformationFormMixin, passwordFormMixin } from '@/mixins';
import { Roles } from '../../enums/roles';

export default {
	name: 'rn-user-view',

	components: {
		RnActionHeader,
		RnForm,
		RnButton,
		RnNotification,
		RnIcon,
		RnAlert,
	},

	mixins: [contactInformationFormMixin, addressInformationFormMixin, passwordFormMixin],

	data() {
		return {
			currentTabIndex: 0,
			isDirty: false,
		};
	},

	mounted() {
		this.unwatchContactForm = this.$watch('contactInformationForm.model', this.handleWatcher, {
			deep: true,
		});

		this.unwatchPasswordForm = this.$watch('changePasswordForm.model', this.handleWatcher, {
			deep: true,
		});
	},

	destroyed() {
		this.unwatchContactForm();
		this.unwatchPasswordForm();
	},

	computed: {
		...mapGetters(['user', 'userName', 'location', 'addresses', 'loading']),

		steps() {
			return [
				{
					label: this.$t('user.contactInfo.title'),
					form: this.contactInformationForm,
				},
				{
					label: this.$t('user.addressInfo.title'),
					form: this.addressInformationForm,
				},
				{
					label: this.$t('user.password.title'),
					form: this.changePasswordForm,
				},
			];
		},

		isPup() {
			return this.user.businessRelation.roles[0].name === Roles.PUP;
		},

		currentlyActiveForm() {
			return this.steps[this.currentTabIndex].form;
		},
	},

	methods: {
		handleWatcher(newVal, oldVal) {
			if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
				this.isDirty = true;
			}
		},
	},
};
