import multiguard from 'vue-router-multiguard';

import config from '@/config';
import { Roles } from '@/enums/roles';

import { RnLanguageWrapper } from '@/components';
import { authGuard, roleGuard, languageGuard, tokenGuard } from '@/router/guards';

import {
	RnLoginView,
	RnAppView,
	RnErrorView,
	RnUserView,
	RnHelpdeskView,
	RnHelpView,
	RnDashboardView,
	RnNotificationsView,
	RnResourcesView,
	RnResetPasswordView,

	// PUP Views
	RnPUPNewRequestView,
	RnPUPCollectionsView,
	// RnPUPInvoicesView,

	// CP Views
	RnCPNewCollectionView,
	RnCPUpdateCollectionView,
	RnCPNewTransportView,
	RnCPWarehouseView,
	RnCPCollectionsView,
	RnCPInvoicesView,
	RnCPOpsInvoicesView,
	RnCPOrderCheckingView,

	// RC Views
	RnRCDeliveriesView,
	RnRCOrderCheckingView,

	// MF (Brand) Views
	RnMFContractsView,
	RnMFPickupPointsView,
	RnMFNewPickupPointView,
} from '@/views';

export default [
	{
		path: '/',
		redirect: { name: 'App', params: { language: config.defaultLanguage } },
	},

	{
		path: '/:language',
		component: RnLanguageWrapper,
		beforeEnter: languageGuard,

		children: [
			{
				path: 'login',
				name: 'Login',
				component: RnLoginView,
				meta: {
					transparentNavigation: true,
				},
			},
			{
				path: 'reset',
				name: 'Reset password',
				component: RnResetPasswordView,
				beforeEnter: tokenGuard,
				meta: {
					transparentNavigation: true,
				},
			},
			{
				path: 'helpdesk',
				name: 'Helpdesk',
				component: RnHelpdeskView,
			},

			{
				path: 'help',
				name: 'Help',
				component: RnHelpView,
			},

			{
				path: 'error',
				name: 'Error',
				component: RnErrorView,
			},

			{
				path: '',
				name: 'App',
				component: RnAppView,
				beforeEnter: multiguard([authGuard, roleGuard]),
				children: [
					// General routes
					{
						path: 'user',
						name: 'User',
						component: RnUserView,
						meta: {
							allowedRoles: [Roles.PUP, Roles.CP, Roles.RC, Roles.SD],
						},
					},

					{
						path: 'notifications',
						name: 'Notifications',
						component: RnNotificationsView,
						meta: {
							allowedRoles: [Roles.PUP, Roles.CP, Roles.RC, Roles.SD],
						},
					},

					{
						path: 'dashboard',
						name: 'Dashboard',
						component: RnDashboardView,
						meta: {
							allowedRoles: [Roles.PUP, Roles.CP, Roles.RC, Roles.SD, Roles.MF, Roles.NMSC],
							transparentNavigation: true,
						},
					},

					{
						path: 'resources',
						name: 'Resources',
						component: RnResourcesView,
						meta: {
							allowedRoles: [Roles.PUP, Roles.CP, Roles.RC, Roles.SD, Roles.MF, Roles.NMSC],
						},
					},

					// PUP routes
					// ------------------------------------------------------------------------- /

					{
						path: 'new-request',
						name: 'PUPNewRequest',
						component: RnPUPNewRequestView,
						meta: {
							allowedRoles: [Roles.PUP],
						},
					},

					{
						path: 'collections',
						name: 'PUPCollections',
						component: RnPUPCollectionsView,
						meta: {
							allowedRoles: [Roles.PUP],
						},
					},

					// Temp disabled, since PUP's do not need access to invoices for now
					// https://studiohyperdrive.atlassian.net/browse/RENEO-372
					// {
					// 	path: "pup-invoices",
					// 	name: "PUPInvoicing",
					// 	component: RnPUPInvoicesView,
					// 	meta: {
					// 		allowedRoles: [Roles.PUP],
					// 	},
					// },

					// CP routes
					// ------------------------------------------------------------------------- /

					{
						path: 'new-collection/:collectionOrderId',
						name: 'CPNewCollection',
						component: RnCPNewCollectionView,
						meta: {
							allowedRoles: [Roles.CP],
						},
					},

					{
						path: 'update-collection/:collectionOrderId',
						name: 'CPUpdateCollection',
						component: RnCPUpdateCollectionView,
						meta: {
							allowedRoles: [Roles.CP],
						},
					},

					{
						path: 'new-transport',
						name: 'CPNewTransport',
						component: RnCPNewTransportView,
						meta: {
							allowedRoles: [Roles.CP],
						},
					},

					{
						path: 'warehouse',
						name: 'CPWarehouse',
						component: RnCPWarehouseView,
						meta: {
							allowedRoles: [Roles.CP],
						},
					},

					{
						path: 'ops-invoices',
						name: 'CPOpsInvoices',
						component: RnCPOpsInvoicesView,
						meta: {
							allowedRoles: [Roles.CP],
						},
					},

					{
						path: 'collection-orders',
						name: 'CPCollections',
						component: RnCPCollectionsView,
						meta: {
							allowedRoles: [Roles.CP],
						},
					},

					{
						path: 'invoices',
						name: 'CPInvoices',
						component: RnCPInvoicesView,
						meta: {
							allowedRoles: [Roles.CP],
						},
					},

					{
						path: 'order-checking/:id',
						name: 'CPOrderChecking',
						component: RnCPOrderCheckingView,
						meta: {
							allowedRoles: [Roles.CP],
						},
					},

					// RC routes
					// ------------------------------------------------------------------------- /

					{
						path: 'orders',
						name: 'RCOrders',
						component: RnRCDeliveriesView,
						meta: {
							allowedRoles: [Roles.RC, Roles.SD],
						},
					},

					{
						path: 'order-checking/:id',
						name: 'RCOrderChecking',
						component: RnRCOrderCheckingView,
						meta: {
							allowedRoles: [Roles.RC, Roles.SD],
						},
					},

					// MF routes
					// ------------------------------------------------------------------------- /

					{
						path: 'contracts',
						name: 'MFContracts',
						component: RnMFContractsView,
						meta: {
							allowedRoles: [Roles.MF],
						},
					},

					{
						path: 'pickup-points',
						name: 'MFPickupPoints',
						component: RnMFPickupPointsView,
						meta: {
							allowedRoles: [Roles.MF],
						},
					},

					{
						path: 'new-pickup-point',
						name: 'MFNewPickupPoint',
						component: RnMFNewPickupPointView,
						meta: {
							allowedRoles: [Roles.MF],
						},
					},
				],
			},

			{
				path: '*',
				redirect: { name: 'Error' },
			},
		],
	},
];
