/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'download': {
    width: 16,
    height: 16,
    viewBox: '-472 272 10 16.9',
    data: '<path pid="0" d="M-467 285l5-5-.7-.7-3.9 4V272h-.9v11.3l-3.9-4-.7.7zm-5 3v.9h10v-.9z" _fill="#fff"/><path pid="1" d="M-467 285l5-5-.7-.7-3.9 4V272h-.9v11.3l-3.9-4-.6.7zm-5 3v.9h10v-.9z"/>'
  }
})
