/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'cardboard-orange': {
    width: 16,
    height: 16,
    viewBox: '0 0 65.29 58.72',
    data: '<defs/><g id="Layer_2" data-name="Layer 2"><path pid="0" class="cls-1" d="M32.64 58.72a1.44 1.44 0 0 1-.46-.08L1 48.28a1.48 1.48 0 0 1-1-1.41V11.72a1.49 1.49 0 0 1 1-1.42L32.18.07a1.58 1.58 0 0 1 .93 0L64.27 10.3a1.49 1.49 0 0 1 1 1.42v35.15a1.48 1.48 0 0 1-1 1.41L33.11 58.64a1.5 1.5 0 0 1-.47.08zm.91-36v32.64l28.76-9.57V13.16l-9.1 3v8.57a.89.89 0 0 1-.62.85l-8.16 2.72a.92.92 0 0 1-.29 0 .86.86 0 0 1-.53-.17.9.9 0 0 1-.37-.73v-8zM3 45.79l28.76 9.57V22.73L3 13.16zM17.77 7.94l26.53 9.43a.91.91 0 0 1 .74.89v8l6.36-2.16v-7.91l-28.22-10zM5.3 12l27.34 9.1 8.7-2.89L15 8.86zM26 5.24l26.3 9.35L60 12 32.65 3z" id="cardboard"/></g>'
  }
})
