import RnIcon from '@/components/icon/icon.vue';

export default {
	name: 'rn-battery-status',
	components: {
		RnIcon,
	},
	props: {
		batteryProps: {
			type: Object,
			default: () => ({}),
		},
		packageProps: {
			type: Object,
			default: () => ({}),
		},
	},
};
