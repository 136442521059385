import { RnButton, RnField, RnForm } from '@/components';
import { passwordResetFormMixin } from '@/mixins';

export default {
	name: 'rn-password-reset',
	components: {
		RnButton,
		RnField,
		RnForm,
	},
	mixins: [passwordResetFormMixin],
	data() {
		return {
			currentView: 'password-reset',
		};
	},
};
