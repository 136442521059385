import { moment } from '@/utils/index';

export function formatAsDate(date, separator) {
	if (!date) {
		return '-';
	}

	return moment.utc(date).format(`DD${separator || '/'}MM${separator || '/'}YYYY`);
}

export function formatAsMonthYear(date) {
	if (!date) {
		return '-';
	} else if (new Date(date).getYear() < 0) {
		return '-';
	}

	return moment.utc(date).format(`MMM - YYYY`);
}
