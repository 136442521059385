/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'battery-add': {
    width: 16,
    height: 16,
    viewBox: '0 0 197 159',
    data: '<path pid="0" d="M115 126.4H0V18h164v59c0 .6-.4 1-1 1s-1-.4-1-1V20H2v104.4h113c.6 0 1 .4 1 1s-.4 1-1 1z" _fill="#191a1a" _stroke="#000"/><path pid="1" d="M197 130.1h-28.9V159h-9.7v-28.9h-28.9v-9.7h28.9V91.5h9.7v28.9H197z" _fill="#ec6244"/><path pid="2" d="M145 13h-36.2V0H145zm-34.2-2H143V2h-32.2zM55 13H18.8V0H55zm-34.2-2H53V2H20.8z" _fill="#191a1a"/><g _fill="#cacccc"><path pid="3" d="M18.8 35h14v74h-14zM37.5 35h14v74h-14zM56.2 35h14v74h-14zM74.8 35h14v74h-14zM93.5 35h14v74h-14zM112.2 35h14v74h-14zM130.8 35h14v74h-14z"/></g>'
  }
})
