/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'battery': {
    width: 16,
    height: 16,
    viewBox: '0 0 164 126',
    data: '<defs/><g id="Layer_2" data-name="Layer 2"><g id="battery"><path pid="0" class="cls-1" d="M0 18v108h164V18zm162 106H2V20h160zM145 0h-36v14h36zm-2 12h-32V2h32zM55 0H19v14h36zm-2 12H21V2h32z"/><path pid="1" class="cls-2" d="M15 36h14v72H15zM35 36h14v72H35zM55 36h14v72H55zM75 36h14v72H75zM95 36h14v72H95zM115 36h14v72h-14zM135 36h14v72h-14z"/></g></g>'
  }
})
