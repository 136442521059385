import head from 'lodash/head';

import { LocationTypes } from '@/enums/location-types';

export const getCollectionAddress = locations => {
	const location = head(locations);

	if (!location) {
		return '';
	}

	const { street, number, city, zipCode } = location.addresses.find(address => address.type.name === LocationTypes.Collection);

	return `${location.name}, ${street} ${number}, ${city} ${zipCode}`;
};

export const getCollectionName = locations => {
	const location = head(locations);

	if (!location) {
		return '';
	}

	return location.name;
};
