import { mapGetters } from 'vuex';
import RnIcon from '../icon/icon.vue';

export default {
	name: 'rn-message',
	components: {
		RnIcon,
	},
	props: {
		offset: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapGetters(['message']),
		messageClass() {
			if (this.message.type) {
				return `m-message--${this.message.type}`;
			}
		},
	},
	watch: {
		message: {
			deep: true,
			handler(value) {
				if (value && value.text) {
					setTimeout(() => {
						this.$store.dispatch('clearMessage');
					}, 7000);
				}
			},
		},
	},
};
