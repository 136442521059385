// metadata store module
// ---
//
// ------------------------------------------------------------------------- /
import apiService from '@/services/api';
import get from 'lodash/get';
import set from 'lodash/set';
import { logAndToastError, resetState } from '@/utils';

// Initial state
// ------------------------------------------------------------------------- /
const initialState = {
	metadata: [],
};

// Getters
// ------------------------------------------------------------------------- /
const getters = {
	getMetadata: state => get(state, 'metadata', null),
};

// Actions
// ------------------------------------------------------------------------- /
const actions = {
	async getMetadata({ commit, state }, { role = null }) {
		let url;
		try {
			const metadata = get(state, 'metadata');
			if (metadata && metadata.length !== 0) {
				return metadata;
			}

			url = `/businessrelations/collectionorders/search/metadata/${role}`;
			const response = await apiService.get(url);
			commit('setMetadata', {
				metadata: response.data,
			});
		} catch (err) {
			logAndToastError(commit, 'Failed to get metadata from the server', err, {
				url,
			});
		}
	},
	resetMetadata({ commit }) {
		commit('resetMetadata');
	},
};

// Mutations
// ------------------------------------------------------------------------- /
const mutations = {
	setMetadata(state, { metadata }) {
		set(state, 'metadata', metadata);
	},
	resetMetadata(state) {
		resetState(state, initialState);
	},
};

export default {
	state: { ...initialState },
	getters,
	actions,
	mutations,
};
