export default {
	name: 'rn-spinner',
	props: {
		color: {
			type: String,
			required: false,
			default: '#cacccc',
		},
	},
};
