/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'support': {
    width: 16,
    height: 16,
    viewBox: '0 0 137.655 125',
    data: '<path pid="0" d="M105.735 68.252v-9.96c0-7.74-2.4-15.12-6.96-21.38l-.32-.44a39.864 39.864 0 0 0-3.43-3.94c-6.91-6.91-16.06-10.72-25.77-10.72-11.4 0-22.31 5.48-29.19 14.66-4.77 6.36-7.29 13.91-7.29 21.82v9.95l-3.15 1.71v19.09l8.29 4.53v3.98h8.87v-36.11h-8.87v4l-2.04 1.11v-8.26c0-7.24 2.31-14.14 6.67-19.96.54-.72 1.11-1.41 1.7-2.08 7.46-4.92 16.04-7.5 25.01-7.5 8.97 0 17.56 2.59 25.02 7.51.49.56.97 1.14 1.44 1.74l.27.35c4.35 5.81 6.65 12.7 6.65 19.94v8.27l-2.04-1.11v-4h-8.88v34.62h-9.64a5.082 5.082 0 0 0-10.11.75c0 2.8 2.28 5.09 5.09 5.09 2.54 0 4.66-1.88 5.02-4.34h18.52v-3.99l8.29-4.53v-19.08l-3.15-1.72zm-64.72-3.71h2.67v29.91h-2.67v-29.91zm-1.5 3.56v22.81l-6.79-3.71v-15.41l6.79-3.69zm29.74-40.85c-7.11 0-13.98 1.57-20.27 4.59 5.77-4.43 12.91-6.93 20.27-6.93 7.44 0 14.52 2.45 20.34 6.97-6.31-3.05-13.21-4.63-20.34-4.63zm9.78 69.58a1.991 1.991 0 0 1-1.98 1.98c-1.1 0-1.99-.9-1.99-1.99a1.985 1.985 0 1 1 3.97 0m18.46-2.35h-2.68v-29.92h2.68v29.92zm8.29-7.26l-6.79 3.71v-22.81l6.79 3.7v15.4z" _fill="#f86439"/>'
  }
})
