import { ButtonClasses } from '../button/button.enums';
import RnButton from '../button/button.vue';
import RnIcon from '../icon/icon.vue';

export default {
	name: 'rn-toggle-button',
	components: {
		RnButton,
		RnIcon,
	},
	props: {
		active: {
			type: Boolean,
			required: true,
		},
		color: {
			type: String,
			default: '',
		},
		icon: {
			type: Object,
			default: () => ({
				name: '', // icon name
				direction: '', // icon direction
				original: false,
				width: '16px',
				height: '16px',
			}),
		},
		method: {
			type: Function,
			default: () => {
				// do nothing
			},
		},
		toggleIcon: {
			type: Object,
			default: () => ({
				name: '', // icon name
				direction: '', // icon direction
				original: false,
				width: '16px',
				height: '16px',
			}),
		},
		toggleColor: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			hovered: false,
		};
	},
	computed: {
		toggleButtonClasses() {
			return [
				'a-toggle-button',
				ButtonClasses.Base,
				ButtonClasses.Icon,
				ButtonClasses.Round,
				// Color
				`a-button--${this.hovered && this.active ? this.toggleColor : this.color}`,
				{
					'a-toggle-button--active': this.active,
				},
			];
		},
	},
};
