import RnIcon from '@/components/icon/icon.vue';

export default {
	name: 'rn-battery-attachments',
	components: {
		RnIcon,
	},
	props: {
		pdf: {
			type: Object,
			default: null,
			required: false,
		},
		image: {
			type: Object,
			default: null,
			required: false,
		},
	},
};
