const HeaderClasses = Object.freeze({
	Base: 'm-header',
	// Size
	Small: 'm-header--s',
});

const InnerClasses = Object.freeze({
	container: 'u-container',
	// Position
	center: 'm-header--inner',
});

export { HeaderClasses, InnerClasses };
