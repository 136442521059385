import { mapGetters } from 'vuex';
import { moment } from '@/utils';

import apiService from '@/services/api';
import { interpolate } from '@/utils/string';
import { logAndToastError } from '@/utils';
import RnButton from '../button/button.vue';

export default {
	name: 'rn-notification',
	components: {
		RnButton,
	},
	props: {
		notification: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapGetters(['userId']),
		message() {
			const notificationTranslationKey = `notifications.${this.notification.code}`;
			let messageTemplate = this.$t(notificationTranslationKey);
			if (messageTemplate === notificationTranslationKey) {
				messageTemplate = this.notification.messageTemplate;
			}

			return interpolate(messageTemplate, this.notification.parameters || {});
		},
		timestamp() {
			return new moment(this.notification.createdOn).fromNow();
		},
	},
	methods: {
		markAsRead() {
			const url = `/users/${this.userId}/notification/${this.notification.id}`;
			const body = { seenOn: new Date() };
			apiService
				.patch(url, body)
				.then(({ data: response }) => {
					if (response.success) {
						this.$store.dispatch('getAllNotifications');
					}
				})
				.catch(err => {
					logAndToastError(this.$store.dispatch, 'Failed to mark notification as read', err, {
						url,
						body,
					});
				});
		},
	},
};
