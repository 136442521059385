var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-rc-order-checking"},[_c('rn-header',{attrs:{"title":_vm.$t('rc.orderChecking.title'),"size":"small"}},[_c('router-link',{staticClass:"a-link a-link--back",attrs:{"slot":"action","to":{ name: 'Dashboard' }},slot:"action"},[_c('rn-icon',{attrs:{"name":"chevron","direction":"left","width":"16px","height":"16px"}}),_c('span',[_vm._v(_vm._s(_vm.collectionOrder && _vm.collectionOrder.status === 'Executed' ? _vm.$t('common.back') : _vm.$t('common.cancelAndReturn')))])],1)],1),_c('div',{staticClass:"o-section o-section--grey"},[(_vm.collectionOrdersState === 'loading')?_c('rn-spinner'):_vm._e(),(_vm.collectionOrdersState === 'noResults')?_c('div',{staticClass:"a-no-results"},[_vm._v(" "+_vm._s(_vm.$t('common.noResults'))+" ")]):_vm._e(),(_vm.collectionOrdersState === 'results')?_c('div',{staticClass:"u-container u-container--full"},[_c('div',{staticClass:"o-overview"},[(_vm.collectionOrder.status !== 'Executed')?_c('div',{staticClass:"o-overview__section"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('table.order.pickupDate')))]),_c('div',{staticClass:"u-grid"},[_c('rn-field',{staticClass:"u-grid__4-12",attrs:{"input-props":{
								id: 'new-collection-date',
								type: 'date',
								format: 'date',
							},"required":true,"error":_vm.state.dateError,"label":_vm.$t('cp.newCollection.date')},on:{"update:value":function (value) { return _vm.setFormValue('date', value); }}})],1)]):_vm._e(),_c('div',{staticClass:"o-overview__section"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('common.generalInformation')))]),_c('rn-card-list',{attrs:{"card-data":_vm.overviewCardList}})],1),_c('div',{staticClass:"o-overview__section"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('common.packagingMethod')))]),_c('rn-card-list',{attrs:{"card-data":_vm.transportPackagingCardList}})],1),_c('div',{staticClass:"o-overview__section"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('rc.orderChecking.overviewBatteriesReadyForTransportation')))]),(_vm.collectionOrder.batteries && _vm.collectionOrder.batteries.length)?_c('rn-new-table',{attrs:{"headers":_vm.nestedBatteryTableHeaders,"data":_vm.collectionOrder.batteries}}):_vm._e()],1),_c('div',{staticClass:"o-overview__section"},[_c('h4',{staticClass:"a-title"},[_vm._v(_vm._s(_vm.$t('common.comments')))]),_c('span',[_vm._v(_vm._s(_vm.collectionOrder.remarks || _vm.$t('common.noComments')))])])]),(_vm.collectionOrder.status !== 'Executed')?_c('div',{staticClass:"m-form__footer"},[_c('hr'),_c('rn-button',{attrs:{"method":_vm.openErrorReportModal,"outline":true,"text":_vm.$t('rc.orderChecking.reportError.button'),"color":"blue"}}),_c('rn-button',{attrs:{"icon":{ name: 'check', pos: 'left', width: '16px', height: '16px' },"method":_vm.markCollectionOrderAsChecked,"text":_vm.state.saveCheckedInProgress ? _vm.$t('common.saving') : _vm.$t('rc.orderChecking.checked')}})],1):_vm._e()]):_vm._e()],1),(_vm.state.showModal)?_c('rn-modal',{attrs:{"title":_vm.$t('rc.orderChecking.reportingError.modal.title'),"message":_vm.$t('rc.orderChecking.reportingError.modal.description'),"on-close":function () { return (_vm.state.showModal = false); }}},[_c('template',{slot:"body"},[_c('rn-field',{attrs:{"input-props":{
					id: 'order-checking-error',
					placeholder: _vm.$t('stepForm.overview.comments.placeholder'),
					type: 'textarea',
				},"value":_vm.state.errorMessage},on:{"update:value":function($event){return _vm.$set(_vm.state, "errorMessage", $event)}}})],1),_c('template',{slot:"actions"},[_c('rn-button',{attrs:{"text":_vm.state.saveErrorInProgress ? _vm.$t('common.saving') : _vm.$t('rc.orderChecking.reportingError.modal.button')},nativeOn:{"click":function($event){return _vm.addErrorReport.apply(null, arguments)}}})],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }