/* eslint-disable */
import * as icon from 'vue-svgicon'
icon.register({
  'attachment': {
    width: 16,
    height: 16,
    viewBox: '-475 273 8 16',
    data: '<path pid="0" d="M-468.1 276.6v8.4c0 1.6-1.3 2.9-2.9 2.9s-2.9-1.3-2.9-2.9v-9.1c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8v7.6c0 .4-.3.7-.7.7s-.7-.3-.7-.7v-6.9h-1.1v6.9c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8v-7.6c0-1.6-1.3-2.9-2.9-2.9s-2.9 1.3-2.9 2.9v9.1c0 2.2 1.8 4 4 4s4-1.8 4-4v-8.4z"/>'
  }
})
