import { mapGetters } from 'vuex';
import get from 'lodash/get';

import { RnNewTable, RnButton, RnHeader, RnSpinner, RnIcon, RnCardList, RnModal, RnField } from '@/components';
import { batteriesMixin } from '@/mixins';
import { MessageTypes } from '@/enums/message-types';
import apiService from '@/services/api';
import { packagingService } from '@/services/packagings';
import { downloadAttachment } from '@/utils/download-attachment';
import { getDate, logAndToastError } from '@/utils';
import { getCollectionAddress } from '@/utils/locations';
import { formatWeight } from '@/utils/units';

export default {
	name: 'rn-rc-order-checking-view',
	components: {
		RnNewTable,
		RnButton,
		RnHeader,
		RnSpinner,
		RnIcon,
		RnCardList,
		RnModal,
		RnField,
	},
	mixins: [batteriesMixin],
	data() {
		return {
			state: {
				date: null,
				dateError: null,
				saveCheckedInProgress: false,
				saveErrorInProgress: false,
				showModal: false,
				errorMessage: '',
			},
		};
	},
	computed: {
		...mapGetters(['collectionOrders', 'collectionOrderTransportPackagings', 'businessRelationId', 'locationId', 'locations']),
		collectionOrder() {
			return get(this.collectionOrders, '[0]', null);
		},
		location() {
			return this.$store.getters.locationsLocation(this.collectionOrder.sourceLocation.id);
		},
		overviewCardList() {
			const cardProps = { grid: { cols: 4 } };
			const createCard = (label, value, props = cardProps) => ({
				info: [
					{
						label,
						value,
					},
				],
				...props,
			});

			const addressCard = createCard(this.$t('pup.newRequest.address'), getCollectionAddress([this.location]));
			const weightCard = {
				info: [
					{
						label: this.$t('common.orderNumber'),
						value: this.collectionOrder.formattedCollectionOrderHeaderNumber,
					},
					{
						label: this.$t('common.totalWeight'),
						value: formatWeight(this.collectionOrder.batteries.reduce((acc, battery) => acc + battery.nettoWeight, 0)),
					},
				],
			};
			const deliveryNoteCard = {
				info: [
					{
						label: this.$t('common.deliveryNote'),
					},
				],
			};

			const datesCard = {
				info: [
					{
						label: this.$t('table.order.dateRequested'),
						value: getDate(this.collectionOrder.orderCreationDate),
					},
					{
						label: this.$t('table.order.pickupDate'),
						value: getDate(this.collectionOrder.pickUpDate),
					},
				],
			};

			if (this.collectionOrder.packingSlipId) {
				deliveryNoteCard.info[0].component = {
					name: RnButton,
					props: {
						text: this.$t('common.downloadDeliveryNote'),
						color: 'blue',
						outline: true,
						disableWrap: true,
						icon: {
							name: 'download', // icon name
							pos: 'right', // left or right
							width: '20px',
							height: '20px',
							original: false,
							color: 'blue',
						},
						method: this.startDownloadDeliveryNote,
					},
				};
			} else {
				deliveryNoteCard.info[0].value = this.$t('common.noDeliveryNoteAvailable');
			}

			return [addressCard, weightCard, deliveryNoteCard, datesCard];
		},
		transportPackagingCardList() {
			const cardGridOptions = { grid: { cols: 4 } };
			const transportPackagings = this.$store.getters.getTransportPackagings(this.businessRelationId, this.batteryBrandId);

			return packagingService.packagingsToCardInfos(
				this.collectionOrderTransportPackagings,
				transportPackagings,
				cardGridOptions,
				this.$t,
				this.removeReceptacle
			);
		},
		collectionOrdersState() {
			if (!this.collectionOrders) {
				return 'loading';
			}
			if (this.collectionOrders[0]) {
				return 'results';
			}
			return 'noResults';
		},
		batteryBrandId() {
			return get(this.collectionOrder, 'batteries[0].batteryDescription.brand.id', null);
		},
	},
	watch: {
		collectionOrder(newOrders) {
			const collectionOrderId = get(newOrders, '[0].id');
			if (collectionOrderId) {
				this.$store.dispatch('getCollectionOrderPackagings', { collectionOrderId });
			}

			const sourceLocation = get(newOrders, 'sourceLocation.id', null);
			if (sourceLocation) {
				this.$store.dispatch('getLocation', { locationId: sourceLocation });
			}

			const batteryBrandId = get(newOrders, 'batteries[0].batteryDescription.brand.id', null);
			if (batteryBrandId) {
				this.$store.dispatch('getBatteryPackagings', {
					businessRelationId: this.businessRelationId,
					brandId: batteryBrandId,
				});
			}
		},
	},
	created() {
		this.$store.dispatch('getCollectionOrder', {
			businessRelationId: this.businessRelationId,
			collectionOrderId: this.$route.params.id,
		});
	},
	methods: {
		startDownloadDeliveryNote() {
			downloadAttachment(this.collectionOrder.packingSlipId, 'message.deliveryOrder.error', this.$store);
		},
		setFormValue(prop, value) {
			this.state[prop] = value;
			this.validateForm();
		},
		validateForm() {
			this.state.dateError = this.state.date ? null : this.$t('error.required', [this.$t('table.order.pickupDate')]);

			return this.state.date;
		},
		markCollectionOrderAsChecked() {
			if (!this.validateForm()) {
				return;
			}

			const plannedPickUpDate = new Date(this.collectionOrder.plannedPickUpDate);
			const pickUpDate = this.state.date;
			const body = this.getCheckOrderBody(plannedPickUpDate, new Date(pickUpDate), null);
			this.state.saveCheckedInProgress = true;
			const url = `/businessrelations/${this.businessRelationId}/collectionorders/${this.collectionOrder.id}`;
			apiService
				.patch(url, body)
				.then(() => {
					this.$store.dispatch('setMessage', {
						text: 'message.checkOrder.success',
						type: MessageTypes.SUCCESS,
					});
					this.$router.push({ name: 'Dashboard' });
					this.state.saveCheckedInProgress = false;
				})
				.catch(err => {
					logAndToastError(this.$store.dispatch, 'Failed to check collection order', err, {
						url,
						body,
					});
					this.state.saveCheckedInProgress = false;
				});
		},
		openErrorReportModal() {
			this.state.showModal = true;
		},
		addErrorReport() {
			const body = {
				pickUpDate: this.state.date || new Date().toISOString(),
				deliveryRemarks: (this.state.errorMessage || '').trim(),
			};
			this.state.saveErrorInProgress = true;
			const url = `/businessrelations/${this.businessRelationId}/collectionorders/${this.collectionOrder.id}`;
			apiService
				.patch(url, body)
				.then(() => {
					this.$store.dispatch('setMessage', {
						text: 'message.checkOrder.success',
						type: MessageTypes.SUCCESS,
					});
					this.$router.push({ name: 'Dashboard' });
					this.state.showModal = false;
					this.state.saveErrorInProgress = false;
				})
				.catch(err => {
					logAndToastError(this.$store.dispatch, 'Failed to check collection order', err, {
						url,
						body,
					});
					setTimeout(() => (this.state.saveErrorInProgress = false), 1000);
				});
		},
		getCheckOrderBody(plannedPickUpDate, pickUpDate, deliveryRemarks) {
			return {
				pickUpDate: plannedPickUpDate.getTime() > pickUpDate.getTime() ? plannedPickUpDate.toISOString() : pickUpDate.toISOString(),
				deliveryRemarks,
			};
		},
	},
};
