import RnDropzone from './dropzone/dropzone.vue';

export default {
	name: 'rn-dropzones',
	components: {
		RnDropzone,
	},

	props: {
		zones: {
			type: Array,
			default: [],
		},
		interactive: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		remove(tempId, el) {
			this.$emit('remove', tempId, el);
		},
	},
};
