export function withMmUnit(mixinReference, translationKey) {
	return `${mixinReference.$t(translationKey)} (${mixinReference.$t('common.mm')})`;
}

export function withKgUnit(mixinReference, translationKey) {
	return `${mixinReference.$t(translationKey)} (${mixinReference.$t('common.kg')})`;
}

export function formatWeight(weight) {
	return `${Math.round(weight * 10) / 10} kg`;
}
